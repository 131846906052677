import Base from './../Base';
import Component from '../../pages/flows/Flows';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";
import _ from 'lodash';

/**
 * Controller for Inventory component
 */
export default class Flows extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Flows.component) {
            const item = new Flows();
            Flows.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Flows.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            config: state.flows,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            selectedFlow: ownProps.selectedFlow || "",
            selectedBlockIndex: state.selectedBlockIndex,
            history: ownProps.history,
            inventory: state.config.inventory && state.config.inventory.lan,
            status: state.status,
        };
        let selectedFlow = null;
        if (ownProps.selectedFlow && state.flows) {
            if (ownProps.selectedFlow === 'new') {
                selectedFlow = state.newFlow;
            } else {
                const selectedFlowIndex = state.flows.findIndex(item => item.id == ownProps.selectedFlow);
                if (selectedFlowIndex !== -1) {
                    selectedFlow = state.flows[selectedFlowIndex];
                }
            }
            if (selectedFlow) {
                props['selectedFlowObject'] = selectedFlow;
                props['id'] = selectedFlow.id || '';
                props['title'] = selectedFlow.title || '';
                if (state.selectedBlockIndex !== null) {
                    props.selectedBlock = selectedFlow.blocks[state.selectedBlockIndex];
                }
            }
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (!this.flows || !this.flows.length) {
            await this.loadConfig();
        }
        if (!this.intervals || !this.intervals.length || typeof(this.intervals)==='undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof(this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.GetStatus();
                    this.isChecking = false;
                }
            }, 1));
        }
    }

    async Save() {
        this.ChangeField('errors',{});
        this.cleanBlocksPassedState();
        if (!this.validate()) {
            this.applyPropsToState(["errors"]);
            return;
        }
        this.ChangeField('isLoading',true);
        let state = Store.getState();
        if (this.selectedFlow === 'new') {
            state = Store.getState();
            state.flows.push(state.newFlow);
            this.selectedFlowObject = state.newFlow;
        }

        let response = await HttpClient.request("/flow/save","post",this.selectedFlowObject);
        if (response && response.data && response.data.status && response.data.status === "ok") {
            if (!this.selectedFlowObject.id) {
                window.location.href = "/flows/"+response.data.flow.id;
            }
            setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
            Store.changeProperties({
                isLoading:false,
                successMessageText:"Changes saved successfully",
                newFlow:{},
            });
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
    }

    validate() {
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async loadConfig() {
        this.ChangeField("isLoading",true);
        let response = await HttpClient.request("/flow/load",'get',{});
        if (response.data.status === 'ok' && response.data.flows && typeof(response.data.flows) === 'object') {
            this.ChangeField("flows",response.data.flows)
        }
        response = await HttpClient.request("/config/load",'get',{});
        if (response.data.status === 'ok' && response.data.config && typeof(response.data.config) === 'object') {
            this.ChangeField('config',response.data.config);
        }
        this.ChangeField("isLoading",false);
    }

    ChangeFlowField(name,value) {
        const state = Store.getState();
        if (this.selectedFlow !== 'new') {
            const flowIndex = state.flows.findIndex((item) => item.id == this.selectedFlow);
            if (flowIndex == -1) { return }
            let flow = state.flows[flowIndex];
            eval("flow"+Store.getPropertyNameExpression(name)+ " = _.cloneDeep(value);");
            state.flows[flowIndex] = flow;
            Store.changeProperty('flows', state.flows);
        } else {
            state.newFlow[name] = value;
            Store.changeProperty('newFlow',state.newFlow);
        }
    }

    async Delete() {
        if (!window.confirm("Are you sure ?")) { return; }
        const state = Store.getState();
        const deviceIndex = state.flows.findIndex(item => item.id === this.selectedFlow);
        state.flows.splice(deviceIndex,1);
        const selectedFlow = state.flows.length ? state.flows[0] : '';
        Store.changeProperties({flows:state.flows,selectedFlow:selectedFlow});
    }

    ConnectBlocks(source_id,target_id) {
        const blocks = _.cloneDeep(this.selectedFlowObject.blocks);
        const sourceBlockIndex = blocks.findIndex(item => item.id == source_id);
        if (sourceBlockIndex === -1) { return }
        const targetBlockIndex = blocks.findIndex(item => item.id == target_id);
        if (targetBlockIndex === -1) { return }
        const sourceBlock = blocks[sourceBlockIndex];
        const targetBlock = blocks[targetBlockIndex];
        if (sourceBlock.nextBlock === target_id) { return }
        if (sourceBlock.type == "action") {
            sourceBlock.nextBlock = target_id;
        } else if (sourceBlock.type == "condition") {
            if (sourceBlock.trueBlock && !sourceBlock.falseBlock) {
                sourceBlock.falseBlock = target_id
            } else if (!sourceBlock.falseBlock) {
                sourceBlock.trueBlock = target_id
            }
        }
        blocks[sourceBlockIndex] = sourceBlock;
        this.selectedFlowObject.blocks = blocks;
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);

        Store.changeProperty('flows',this.config);
    }

    AddBlock(block) {
        block.id = this.getMaxBlockId()+1;
        block.title = block.type == "condition" ? "Condition" : "Action";
        if (block.type == "condition") {
            block.condition = {
                fields:[],
                operator:"",
                value:0
            };
            block.trueBlock = null;
            block.falseBlock = null;
        } else if (block.type == "action") {
            block.action = {
                value:0
            };
            block.nextBlock = null;
        }
        this.selectedFlowObject.blocks.push(block);
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    getMaxBlockId() {
        let max = 0;
        for (let block of this.selectedFlowObject.blocks) {
            if (block.id>max) { max = block.id; }
        }
        return parseInt(max);
    }

    SelectBlock(elements) {
        if (elements && elements.length) {
            const element = elements[0];
            const blockIndex = this.selectedFlowObject.blocks.findIndex(item => item.id == element.id);
            if (blockIndex !== -1) {
                this.ChangeField('selectedBlockIndex',blockIndex);
            } else {
                this.ChangeField('selectedBlockIndex',null);
            }
        } else {
            this.ChangeField('selectedBlockIndex',null);
        }
    }

    ChangeBlockField(name,value) {
        this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)][name] = value;
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    ChangeBlockActionField(name,value) {
        this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)]['action'][name] = value;
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    AddConditionFieldToBlock() {
        this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)]['condition'].fields.push({
            device_id:null,
            field_id:""
        });
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    ChangeBlockConditionField(name,value,index=null) {
        if (index !== null) {
            this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)]['condition'].fields[index][name] = value;
        } else {
            this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)]['condition'][name] = value;
        }
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    RemoveConditionField(index) {
        this.selectedFlowObject.blocks[parseInt(this.selectedBlockIndex)]['condition'].fields.splice(index,1);
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    DeleteBlock(id) {
        for (let block_index in this.selectedFlowObject.blocks) {
            if (this.selectedFlowObject.blocks[block_index].nextBlock == id) {
                this.selectedFlowObject.blocks[block_index].nextBlock = null;
            } else if (this.selectedFlowObject.blocks[block_index].trueBlock == id) {
                this.selectedFlowObject.blocks[block_index].trueBlock = null;
            } else if (this.selectedFlowObject.blocks[block_index].falseBlock == id) {
                this.selectedFlowObject.blocks[block_index].falseBlock = null;
            }
        }
        const block_index = this.selectedFlowObject.blocks.findIndex(item => item.id == id);
        if (block_index !== -1) {
            this.selectedFlowObject.blocks.splice(block_index, 1);
        }
        this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
        Store.changeProperty('flows',this.config);
    }

    DeleteElements(elements) {
        for (let element of elements) {
            if (element.source && element.target) {
                const sourceBlockIndex = this.selectedFlowObject.blocks.findIndex(item => item.id == element.source);
                if (sourceBlockIndex === -1) { continue; }
                if (this.selectedFlowObject.blocks[sourceBlockIndex].type === "condition") {
                    if (this.selectedFlowObject.blocks[sourceBlockIndex].trueBlock == element.target) {
                        this.selectedFlowObject.blocks[sourceBlockIndex].trueBlock = null;
                    } else if (this.selectedFlowObject.blocks[sourceBlockIndex].falseBlock == element.target) {
                        this.selectedFlowObject.blocks[sourceBlockIndex].falseBlock = null;
                    }
                } else if (this.selectedFlowObject.blocks[sourceBlockIndex].type === "action") {
                    this.selectedFlowObject.blocks[sourceBlockIndex].nextBlock = null;
                }
                this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
                Store.changeProperty('flows',this.config);
            } else if (element.id) {
                this.DeleteBlock(element.id)
            }
        }
    }

    async Delete() {
        if (this.selectedFlowObject && !window.confirm("Are you sure ?")) { return }
        this.ChangeField('errors',{});
        this.ChangeField('isLoading',true);
        let response = await HttpClient.request("/flow/delete","post",{id:this.selectedFlowObject.id});
        if (response && response.data && response.data.status && response.data.status === "ok") {
            this.config.splice(this.selectedFlow,1);
            Store.changeProperty('flows',this.config);
            Store.changeProperties({
                isLoading:false,
                selectedFlow:null
            });
            this.history.push("/flows/");
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
    }

    async Run() {
        this.cleanBlocksPassedState();
        this.ChangeField('errors',{});
        let response = await HttpClient.request("/flow/run","post",this.selectedFlowObject);
        if (response && response.data && response.data.status && response.data.status === "ok") {
            for (let blockId of response.data.result) {
                const blockIndex = this.selectedFlowObject.blocks.findIndex(item=>item.id == blockId);
                if (blockIndex !== -1) {
                    this.selectedFlowObject.blocks[blockIndex].passed = true;
                }
            }
            this.config[this.selectedFlow] = _.cloneDeep(this.selectedFlowObject);
            Store.changeProperty('flows',this.config);
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
    }

    cleanBlocksPassedState() {
        for (let blockIndex in this.selectedFlowObject.blocks) {
            delete this.selectedFlowObject.blocks[blockIndex].passed;
        }
    }

    async GetStatus() {
        const response = await HttpClient.request('/inventory/get_status',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            this.ChangeField("status",response.data.result.lan);
        }
    }

}
