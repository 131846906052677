import Base from './../../Base';
import Component from '../../../pages/inventory/settings/GeneralModbusTcpSettingsFields';
import { connect } from 'react-redux';
import Store from "../../../store/Store";
import _ from 'lodash';

/**
 * Controller for Cluster Controller Settings Fields component
 */
export default class GeneralModbusTcpSettingsFields extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!GeneralModbusTcpSettingsFields.component) {
            const item = new GeneralModbusTcpSettingsFields();
            GeneralModbusTcpSettingsFields.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return GeneralModbusTcpSettingsFields.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state, ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null,
            selectedField: state.selectedField || "*",
            errors: state.errors || {}
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex].fields;
                if (config) {
                    props['config'] = config;
                    props['selectedFieldObject'] = config[state.selectedField || "*"];
                    if (!props['selectedFieldObject']) {
                        props['selectedField'] = Object.getOwnPropertyNames(config)[0];
                        props['selectedFieldObject'] = config[props['selectedField']];
                    }
                }
                if (props['selectedFieldObject']) {
                    ["register", "title", "divider", "unit",
                        "description", "formula", "mode", "type"].forEach(field_id => {
                            if (typeof (props['selectedFieldObject'][field_id]) === "undefined") {
                                props['selectedFieldObject'][field_id] = "";
                            }
                        });
                    if (typeof (props['selectedFieldObject']["value_descriptions"]) === "undefined") {
                        props["selectedFieldObject"]['value_descriptions'] = [];
                    }
                }
            }
        }
        return Object.assign(super.setupStateProps(state, ownProps), props);
    }

    ChangeDeviceField(name, value) {

        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField][name] = value;      
        Store.changeProperty("config", state.config);
    }

    ChangeDeviceFields(fields) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        state.config.inventory.lan[deviceIndex].fields = fields;
        Store.changeProperty("config", state.config);
    }

    AddNewField() {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        const fields = _.cloneDeep(state.config.inventory.lan[deviceIndex].fields);
        if (!fields["new"]) {
            fields["new"] = {
                id: "",
                register: "",
                title: "",               
                divider: 1,
                scale:1,
                unit: "",                
                description: "",                
                formula: "",
                mode: "R",
                type: "U32",
            }
            state.config.inventory.lan[deviceIndex].fields = fields;
            Store.changeProperty("config", state.config);
        }
    }

    Validate() {
        this.errors = {};
        if (!this.selectedFieldObject["id"]) { this.errors["id"] = "ID not specified"; }
        if (this.selectedFieldObject["id"] !== "*") {
            if (typeof (this.selectedFieldObject["register"]) !== 'undefined' && this.selectedFieldObject["register"] < 0) {
                this.errors["register"] = "Register not specified";
            }
            if (!this.selectedFieldObject["title"]) {
                this.errors["title"] = "Title not specified";
            }
        }
        this.applyPropsToState(['errors']);
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    Delete() {
        if (!window.confirm("Are you sure ?")) { return; }
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        delete state.config.inventory.lan[deviceIndex].fields[this.selectedField];
        Store.changeProperties({
            "config": _.cloneDeep(state.config),
            "selectedField": "*"
        });
    }

    AddValueDescription() {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        console.log(state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']);
        if (!state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']) {
            state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'] = [];
        }
        state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'].push({});
        console.log(state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']);
        this.ChangeDeviceField("value_descriptions", _.cloneDeep(state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']));
    }

    ChangeValueDescriptionField(index, name, value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        if (!state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']) {
            state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'] = [];
        }
        state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'][index][name] = value;
        this.ChangeDeviceField("value_descriptions", _.cloneDeep(state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']));
    }

    DeleteValueDescription(index) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        if (!state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']) {
            state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'] = [];
        }
        state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions'].splice(index, 1);
        this.ChangeDeviceField("value_descriptions", _.cloneDeep(state.config.inventory.lan[deviceIndex].fields[this.selectedField]['value_descriptions']));
    }

}
