import Base from './../../Base';
import Component from '../../../pages/inventory/settings/ClusterControllerSettings';
import {connect} from 'react-redux';
import Store from "../../../store/Store";
import HttpClient from "../../../services/HttpClient";
import _ from "lodash";

/**
 * Controller for Cluster Controller Settings component
 */
export default class ClusterControllerSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!ClusterControllerSettings.component) {
            const item = new ClusterControllerSettings();
            ClusterControllerSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return ClusterControllerSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null,
            addUnitDisplayed: state.addUnitDisplayed,
            unitId:ownProps.unitId,
            newUnitId:state.unitId,
            unitNumber:state.unitNumber,
            unitTitle:state.unitTitle,
            unitModel:state.unitModel,
            errors:state.errors,
            devicesList: state.devicesList
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                props["deviceConfig"] = config;
                if (config.units && config.units.length) {
                    let selectedUnit = state.selectedUnit;
                    if (!selectedUnit) { selectedUnit = config.units[0].id}
                    let unitIndex = config.units.findIndex(item => item.id == selectedUnit);
                    if (unitIndex === -1) {
                        selectedUnit = config.units[0].id;
                    }
                    props['selectedUnit'] = selectedUnit.toString();
                }
                props['config'] = config;
            }
            let selectedUnit = state.selectedUnit;
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    OnAddUnitClick() {
        Store.changeProperties({
            addUnitDisplayed:true,
            newUnitId:"",
            unitNumber:"",
            unitTitle:"",
            unitModel:""
        })
    }

    OnDeleteUnitClick() {
        if (this.selectedUnit && window.confirm("Are you sure ?")) {
            const state = Store.getState();
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
            if (deviceIndex !== -1) {
                const config = state.config.inventory.lan[deviceIndex];
                if (config.units) {
                    let unitIndex = config.units.findIndex(item => item.id == this.selectedUnit);
                    if (unitIndex !== -1) {
                        config.units.splice(unitIndex,1);
                        state.config.inventory.lan[deviceIndex] = config;
                        this.ChangeField("config",_.cloneDeep(state.config));
                    }
                }
            }
        }
    }

    AddUnit() {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        if (deviceIndex !== -1) {
            if (this.unitNumber === null || this.unitNumber === "" || typeof(this.unitNumber) === "undefined") {
                window.alert("ModBus unit number not specified");
                return
            }
            if (!this.unitTitle) {
                window.alert("Title not specified");
                return
            }
            if (!this.unitModel) {
                window.alert("Model not specified");
                return
            }
            const reg =new RegExp("[0-9A-Za-z_]+$");
            if (!this.newUnitId || !reg.test(this.newUnitId)) {
                window.alert("Incorrect ID. ID must contain only letters, numbers and underscores");
            }
            const config = state.config.inventory.lan[deviceIndex];
            if (!config.units) { config.units = []};
            let unitIndex = config.units.findIndex(item => item.title == this.unitTitle);
            if (unitIndex !== -1) {
                window.alert("Unit with this title already exists");
                return
            }
            unitIndex = config.units.findIndex(item => item.modbus_unit_id == this.unitNumber);
            if (unitIndex !== -1) {
                window.alert("Unit with this Modbus number already exists");
                return
            }
            unitIndex = config.units.findIndex(item => item.id == this.newUnitId);
            if (unitIndex !== -1) {
                window.alert("Unit with this ID already exists");
                return
            }
            config.units.push({
                id: this.newUnitId,
                modbus_unit_id: parseInt(this.unitNumber),
                title: this.unitTitle,
                model_id: parseInt(this.unitModel),
                fields:{}
            })
            state.config.inventory.lan[deviceIndex] = _.cloneDeep(config);
            Store.changeProperties({
                config:_.cloneDeep(state.config),
                selectedUnit: this.newUnitId,
                addUnitDisplayed:false
            });
        }
    }

    async DetectUnits() {
        if (!this.deviceConfig.host) { window.alert("Host not specified"); return }
        if (!this.deviceConfig.port) { window.alert("Port not specified"); return }
        const response = await HttpClient.request("/sma/discoverDeviceUnits","post",{host:this.deviceConfig.host,port:this.deviceConfig.port});
        if (response && response.data && response.data.units && response.data.units.length) {
            const state = Store.getState();
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
            if (deviceIndex !== -1) {
                const config = state.config.inventory.lan[deviceIndex];
                console.log(this.devicesList);
                config.units = response.data.units.map(unit => ({
                    ...unit,
                    title: this.devicesList[this.devicesList.findIndex(item => item.id == unit.model_id)].title+" !!!",
                    fields:{}
                }));
                console.log(config.units);
                state.config.inventory.lan[deviceIndex] = _.cloneDeep(config);
                this.ChangeField("config",_.cloneDeep(state.config));

            }
        } else if (response.data.message) {
            window.alert(response.data.message);
        }
    }

    async Init() {
        const response = await HttpClient.request("/sma/getDeviceTypes","get");
        if (response && response.data && response.data.list) {
            this.ChangeField('devicesList',response.data.list);
        }
    }
}


