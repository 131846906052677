import React,{Component} from "react";
import Helmet from 'react-helmet';
import _ from 'lodash';
import data_settings from '../../config/data';
import timezones from '../../config/timezones';
import moment from 'moment-timezone';
import {ColorPicker} from 'material-ui-color';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Snackbar,
    TextField,
    TabPanel,
    IconButton,
    FormControlLabel,
    Checkbox,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Tooltip
} from "@material-ui/core";

import {
    Save as SaveIcon,
    Clear as ClearIcon,
    Edit as EditIcon,
    Add as AddIcon,
    Refresh as RefreshIcon,
    ArrowDropDown,
    ArrowDropUp,
    GetApp

} from "@material-ui/icons";

import Dygraph from 'dygraphs';
import {CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import '../../lib/smooth-plotter';

function barChartPlotter(e) {
    var serie_index = _.findIndex(this.series,(item)=> {return item.title==e.setName});
    var chart_type = null;
    if (serie_index != -1) {
        chart_type = this.series[serie_index].chart_type;
    }
    var ctx = e.drawingContext;
    var points = e.points;
    if (!points || !points[1]) { return }
    var y_bottom = e.plotArea.h;
    var y_bottom = e.dygraph.toDomYCoord(0);
    // This should really be based on the minimum gap
    var bar_width = 2/3 * (points[1].canvasx - points[0].canvasx);
    ctx.fillStyle = e.color;
    if (chart_type == 'stackedBar') {
        ctx.globalAlpha = 1;
    } else {
        ctx.globalAlpha = 0.5;
    }
    // Do the actual plotting.
    for (var i = 0; i < points.length; i++) {
        var p = points[i];
        var center_x = p.canvasx;  // center of the bar
        var top = p.canvasy,
            bottom = y_bottom-p.canvasy;
        if (p.yval!=0) {
            ctx.fillRect(center_x - bar_width / 2, top,
                bar_width, bottom);
            ctx.strokeRect(center_x - bar_width / 2, top,
                bar_width, bottom);
        }
    }
};

export default class Chart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        if (this.props.chart === null || typeof(this.props.chart) === 'undefined' || this.props.inventory === null || typeof(this.props.inventory) === 'undefined') { return null; }
        return (
            <React.Fragment>
                <Helmet title="Chart"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                {this.renderChartCard()}
                {this.renderSeriesCard()}
            </React.Fragment>
        )
    }

    renderChartCard() {
        const chart = this.props.chart;
        return (
            <Card mb={12} style={{height:'500px'}}>
                <CardHeader
                    action={
                        <div>
                            <Tooltip title="Save chart changes" arrow>
                                <IconButton aria-label="settings" onClick={()=>this.props.Save()}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Refresh chart" arrow>
                                <IconButton variant="contained" color="primary" onClick={()=>this.props.Refresh()}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download CSV" arrow>
                                <IconButton variant="contained" onClick={()=>this.props.Refresh(true)}>
                                    <GetApp />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    title={chart.title+" ("+moment.tz(chart.date_start*1000,this.props.chart.timezone).format("YYYY-MM-DD HH:mm:SS")+" - "+
                    moment.tz(chart.date_end*1000,this.props.chart.timezone).format("YYYY-MM-DD HH:mm:SS")+")"}
                />
                <CardContent style={{height:'500px'}}>
                    {this.props.errors && this.props.errors["general"] ?
                        <Alert style={{marginBottom:10}} severity="error">{this.props.errors["general"]}</Alert> : null
                    }
                    {this.renderHeaderForm()}
                    <div className="row" style={{height:'60%'}}>
                        <div id={'dygraph_'+this.props.chart_id} style={{width:'99%',height:'100%',display:this.props.isChartLoading ? 'none' : ''}}
                             ref={(div) => { this.dygraphDiv = div; }}></div>
                        { this.props.isChartLoading &&
                            <div style={{textAlign: 'center', width: "100%"}}><CircularProgress color="primary" /></div>
                        }
                    </div>
                </CardContent>
            </Card>
        )
    }

    renderHeaderForm() {
        return (
            <form noValidate autoComplete="off" style={{width:'100%'}}>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["title"] && !!this.props.errors["title"].length}
                            fullWidth
                            size="small"
                            label="Title"
                            variant="outlined"
                            value={this.props.chart.title}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField('title',e.target.value)}
                        />
                        {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["period"] && !!this.props.errors["period"].length}
                            fullWidth
                            select
                            label="Period"
                            size="small"
                            value={this.props.chart.period || "day"}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField("period",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            <option value="live">Live Data</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                            <option value="beginning_day">Beginning of day</option>
                            <option value="beginning_week">Beginning of week</option>
                            <option value="beginning_month">Beginning of month</option>
                            <option value="beginning_year">Beginning of year</option>
                            <option value="custom">Custom</option>
                        </TextField>
                        {this.props.errors["period"] && <div className="error">{this.props.errors["period"]}</div>}
                    </Grid>
                    {this.props.chart.period === 'custom' &&
                    <Grid item xs={2}>
                        <TextField
                            variant="outlined"
                            label="Start"
                            fullWidth
                            error={this.props.errors["date_start"] && !!this.props.errors["date_start"].length}
                            type="datetime-local"
                            style={{margin:8}}
                            size="small"
                            value={moment(this.props.chart.date_start*1000).format("YYYY-MM-DDTHH:mm:ss")}
                            onChange={(e)=>this.props.ChangeChartField('date_start',e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.props.errors["date_start"] && <div className="error">{this.props.errors["date_start"]}</div>}
                    </Grid> }
                    {this.props.chart.period === 'custom' &&
                    <Grid item xs={2}>
                        <TextField
                            label="End"
                            variant="outlined"
                            fullWidth
                            style={{margin:8}}
                            error={this.props.errors["date_end"] && !!this.props.errors["date_end"].length}
                            type="datetime-local"
                            size="small"
                            value={moment(this.props.chart.date_end*1000).format("YYYY-MM-DDTHH:mm:ss")}
                            onChange={(e)=>this.props.ChangeChartField('date_end',e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.props.errors["date_end"] && <div className="error">{this.props.errors["date_end"]}</div>}
                    </Grid> }
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["aggregate"] && !!this.props.errors["aggregate"].length}
                            fullWidth
                            select
                            label="Aggregate"
                            size="small"
                            value={this.props.chart.aggregate || "auto"}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField("aggregate",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            {this.getAggregatesList()}
                        </TextField>
                        {this.props.errors["aggregate"] && <div className="error">{this.props.errors["aggregate"]}</div>}
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["timezone"] && !!this.props.errors["timezone"].length}
                            fullWidth
                            select
                            label="Timezone"
                            size="small"
                            value={this.props.chart.timezone || "UTC"}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField("timezone",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            {_.map(timezones,(text,value) => {
                                return (
                                    <option key={"tz_"+value.replace(/\ /g,"_")} value={value}>
                                        {text}
                                    </option>
                                )
                            })}
                        </TextField>
                        {this.props.errors["timezone"] && <div className="error">{this.props.errors["timezone"]}</div>}
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["group"] && !!this.props.errors["group"].length}
                            fullWidth
                            select
                            label="Group"
                            size="small"
                            value={this.props.chart.group || "none"}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField("group",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            <option value="none">None</option>
                            <option value="hourly">Hourly</option>
                            <option value="daily">Daily</option>
                            <option value="monthly">Monthly</option>
                        </TextField>
                        {this.props.errors["group"] && <div className="error">{this.props.errors["group"]}</div>}
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            error={this.props.errors["category_id"] && !!this.props.errors["category_id"].length}
                            fullWidth
                            select
                            label="Category"
                            size="small"
                            value={this.props.chart.category_id || 2}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartField("category_id",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >   <option value={1}>Other</option>
                            {this.props.categories.map(category=>
                                <option key={"category_"+category.id} value={category.id}>
                                    {category.title}
                                </option>
                            )}
                        </TextField>
                        {this.props.errors["category_id"] && <div className="error">{this.props.errors["category_id"]}</div>}
                    </Grid>
                    <Grid item xs={2} style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={this.props.chart.syncAxis} onChange={(e)=>this.props.ChangeChartField("syncAxis",e.target.checked)}/>
                            }
                            label="Sync Y Axis"
                        />

                    </Grid>
                </Grid>
            </form>
        )
    }

    getAggregatesList() {
        var aggregates = data_settings.aggregate_levels.map(function(aggregate_level) {
            return <option key={'aggregate_'+aggregate_level} value={aggregate_level}>{data_settings.getPeriodTitle(aggregate_level)}</option>;
        },this);
        aggregates.unshift(<option key={'aggregate_0'} value={0}>Auto</option>);
        return aggregates
    }

    renderSeriesCard() {
        return (
            <Card mb={12}>
                <CardHeader
                    action={
                        <Tooltip title="Add new serie" arrow>
                            <IconButton aria-label="settings" onClick={()=>this.props.AddSerie()}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    title={"Chart Series"+(this.props.current_time ? " ("+moment.tz(this.props.current_time,this.props.chart.timezone).format("YYYY-MM-DD HH:mm:ss")+")" : "")}
                />
                <CardContent>
                    {this.renderSeriesForm()}
                </CardContent>
            </Card>
        )
    }

    renderSeriesForm() {
        const series = [];
        this.props.chart.series.forEach((serie,index) => series.push(this.renderChartSerie(serie,index)));
        return (
            <Table size="small">
                <TableBody>
                    {series}
                </TableBody>
            </Table>
        )
    }

    renderChartSerie(serie,index) {
        const errors = this.props.errors["series"] && this.props.errors["series"][index] ? this.props.errors["series"][index] : {};
        return [
            <TableRow key={"chart_serie_"+serie.id}>
                <TableCell>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <Checkbox checked={serie.visible} onChange={(e)=>this.props.ChangeChartSerieField(index,"visible",e.target.checked)}/>
                        <ColorPicker  hideTextfield={true} value={serie.color} onChange={(e) => this.props.ChangeChartSerieField(index,"color",e)}/>
                        <TextField
                            error={errors['title'] && !!errors["title"].length}
                            fullWidth
                            size="small"
                            label="Title"
                            variant="outlined"
                            value={serie.title}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartSerieField(index,'title',e.target.value)}
                        />
                    </div>
                    {errors["title"] && <div className="error">{errors["title"]}</div>}
                </TableCell>
                <TableCell size="small">
                    <TextField
                        fullWidth
                        select
                        label="Type"
                        size="small"
                        value={serie.chart_type || "line"}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,"chart_type",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="line">Line</option>
                        <option value="bar">Bar</option>
                        <option value="area">Area</option>
                        <option value="stackedBar">Stacked Bar</option>
                        <option value="stackedLine">Stacked Line</option>
                    </TextField>
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        size="small"
                        label="Unit"
                        variant="outlined"
                        value={serie.unit}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,'unit',e.target.value)}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        select
                        label="Use If"
                        size="small"
                        value={serie.useif || "all"}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,"useif",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="all">All</option>
                        <option value="all_except_0">All except 0</option>
                        <option value="positive">Positive</option>
                        <option value="positive_and_0">Positive and 0</option>
                        <option value="negative">Negative</option>
                        <option value="negative_and_0">Negative and 0</option>
                    </TextField>
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        select
                        label="Function"
                        size="small"
                        value={serie.func || "avg"}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,"func",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="avg">Average</option>
                        <option value="min">Min</option>
                        <option value="max">Max</option>
                        <option value="sum">Sum</option>
                        <option value="hourly_averaged_sum">Hourly averaged Sum</option>
                        <option value="first">First</option>
                        <option value="last">Last</option>
                    </TextField>
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        select
                        label="Axis"
                        size="small"
                        value={serie.axis || "y1"}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,"axis",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="y1" >Left</option>
                        <option value="y2">Right</option>
                    </TextField>
                </TableCell>
                <TableCell size="small">
                    <TextField
                        fullWidth
                        size="small"
                        label="Multiply By"
                        variant="outlined"
                        value={serie.multiplier || 1}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieField(index,'multiplier',e.target.value)}
                    />
                </TableCell>
                <TableCell size="small">
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <Tooltip title="Move line up" arrow><IconButton onClick={()=>this.props.MoveSerie(index,index-1)}><ArrowDropUp/></IconButton></Tooltip>&nbsp;
                        <Tooltip title="Move line down" arrow><IconButton onClick={()=>this.props.MoveSerie(index,index+1)}><ArrowDropDown/></IconButton></Tooltip>&nbsp;
                        <Tooltip title="Delete serie" arrow><IconButton onClick={()=>this.props.DeleteSerie(index)}><ClearIcon style={{color:'red'}}/></IconButton></Tooltip>&nbsp;
                        <Tooltip title="Edit fields" arrow><IconButton onClick={()=>this.props.ChangeChartSerieField(index,'show_fields',!serie.show_fields)}><EditIcon style={{color:'blue'}}/></IconButton></Tooltip>&nbsp;
                        <Tooltip title="Add new fields" arrow><IconButton onClick={()=>this.props.AddSerieField(index)}><AddIcon style={{color:'green'}}/></IconButton></Tooltip>
                    </div>
                </TableCell>
                <TableCell style={{visibility:serie.current_value ? 'visible' : 'hidden'}} size="small">
                    {serie.current_value || "9999999"}
                </TableCell>
            </TableRow>,
            serie.show_fields && this.renderChartSerieFields(serie,index)
        ]
    }

    renderChartSerieFields(serie,serie_index) {
        const fields = [];
        serie.fields.forEach((field,index) => fields.push(this.renderChartSerieField(serie,serie_index,field,index)));
        return (
            <TableRow key={"chart_serie_field_"+serie.id+"_fields"}>
                <TableCell colSpan={9} style={{backgroundColor:"#eeeeee"}}>
                    <Table size="small">
                        <TableBody>
                            {fields}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        )
    }

    renderChartSerieField(serie,serie_index,field,field_index) {
        const errors = this.props.errors["series"] && this.props.errors["series"][serie_index] && this.props.errors["series"][serie_index]['fields'] &&
            this.props.errors["series"][serie_index]['fields'][field_index] ? this.props.errors["series"][serie_index]['fields'][field_index] : {};
        return (
            <TableRow key={"chart_serie_field_"+serie_index+"_"+field.field_id}>
                <TableCell>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <Checkbox checked={field.visible}
                          onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,"visible",e.target.checked)}
                        />
                        <TextField
                            fullWidth
                            select
                            label="Operator"
                            size="small"
                            value={field.operator || "+"}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,"operator",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            <option value="+">+</option>
                            <option value="-">-</option>
                            <option value="*">*</option>
                            <option value="/">/</option>
                        </TextField>
                    </div>
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        size="small"
                        label="Multiply By"
                        variant="outlined"
                        value={field.multiplier || 1}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,'multiplier',e.target.value)}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        select
                        label="Device"
                        error={errors['device_id'] && !!errors["device_id"].length}
                        size="small"
                        value={field.device_id || ""}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,"device_id",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value={0}></option>
                        {this.props.inventory.map(device =>
                            <option value={device.id} key={"chart_serie_field_device_"+serie.id+"_"+field.id+"_"+device.id}>
                                {device.title}
                            </option>
                        )}
                    </TextField>
                    {errors["device_id"] && <div className="error">{errors["device_id"]}</div>}
                </TableCell>
                {field.device_id && this.props.inventory[this.props.inventory.findIndex(item=>item.id==field.device_id)] &&
                    this.props.inventory[this.props.inventory.findIndex(item=>item.id==field.device_id)].units &&
                    <TableCell>
                        <TextField
                            fullWidth
                            select
                            error={errors['unit_id'] && !!errors["unit_id"].length}
                            label="Unit"
                            size="small"
                            value={field.unit_id || ""}
                            style={{margin:8}}
                            onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,"unit_id",e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                            variant="outlined"
                        >
                            <option value={0}></option>
                            {this.props.inventory[this.props.inventory.findIndex(item=>item.id==field.device_id)].units.map(unit =>
                                <option value={unit.id} key={"chart_serie_field_unit_"+serie.id+"_"+field.id+"_"+field.device_id+"_"+unit.id}>
                                    {unit.title}
                                </option>
                            )}
                        </TextField>
                        {errors["unit_id"] && <div className="error">{errors["unit_id"]}</div>}
                    </TableCell>
                }
                {((serie,field) => {
                    if (!field.device_id) { return null }
                    const device = this.props.inventory[this.props.inventory.findIndex(item=>item.id==field.device_id)];
                    if (!device) { return null }
                    if (device.units && !field.unit_id) { return null };
                    let fields = {};
                    if (device.units) {
                        fields = device.units[device.units.findIndex(item => item.id == field.unit_id)].fields;
                    } else {
                        fields = device.fields;
                    }
                    const options = [];
                    for (let field_id in fields) {
                        options.push(<option value={field_id} key={"chart_serie_field_field_"+serie.id+"_"+field.id+"_"+field.device_id+"_"+field.unit_id+"_"+field_id}>
                            {fields[field_id].title}
                        </option>)
                    }
                    return (
                        <TableCell>
                            <TextField
                                fullWidth
                                select
                                error={errors['field_id'] && !!errors["field_id"].length}
                                label="Field"
                                size="small"
                                value={field.field_id || ""}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeChartSerieFieldField(serie_index,field_index,"field_id",e.target.value)}
                                SelectProps={{
                                    native: true
                                }}
                                variant="outlined"
                            >
                                <option value={0}></option>
                                {options}
                            </TextField>
                            {errors["field_id"] && <div className="error">{errors["field_id"]}</div>}
                        </TableCell>
                    )
                })(serie,field)}
                <TableCell>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <Tooltip title="Delete serie" arrow>
                            <IconButton style={{color:'red'}} onClick={()=>this.props.DeleteSerieField(serie_index,field_index)}><ClearIcon/></IconButton>
                        </Tooltip>&nbsp;
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
        if (this.liveUpdater) {
            clearInterval(this.liveUpdater);
            this.liveUpdater = null;
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (!this.props.chart) { return }
        var updated_props = {};

        if (this.props.chart.type != "table") {
            if (!this.props.chart.date_start) {
                updated_props['date_start'] = moment().subtract(24, 'hours').utc().unix();
            }
            if (!this.props.chart.date_end) {
                updated_props['date_end'] = moment().utc().unix();
            }
            if (!this.props.chart.period) {
                updated_props['period'] = 'day';
            }
            if (!this.props.chart.group) {
                updated_props['group'] = 'none';
            }
        }

        if (this.props.chart.period == 'live') {
            if (!this.liveUpdater) {
                this.table_fields = null;
                this.liveUpdater = setInterval(async()=>await this.props.UpdateLiveData(), 1000);
                this.props.data[this.props.chart_id+(this.props.widget_id ? '_'+this.props.widget_id : '')] = [];
            }
        } else {
            if (this.liveUpdater) {
                this.table_fields = null;
                clearInterval(this.liveUpdater);
                this.liveUpdater = null;
            }
        }
        if (prevProps.chart_id != this.props.chart_id) { this.props.Refresh() }
        if ((this.props.mode != 'widget' || this.props.widget_type != 'table') && ((this.props.chart.needs_update || prevProps.chart_id != this.props.chart_id) &&
            typeof(this.props.data[this.props.chart_id+(this.props.widget_id ? '_'+this.props.widget_id : '')]) != 'undefined')) {
            if (this.dygraph && this.dygraph.destroy) {
                try {
                    this.dygraph.destroy();
                } catch (e) {}
            }
            const options = {
                axisLabelFontSize: 11,
                connectSeparatedPoints: true,
                drawPoints: false,
                strokeWidth: 3,
                pointSize: 5,
                stackedGraph: false,//this.props.chart.stacked,
                customBars: false,//this.props.period != 'live',
                //errorBars: this.props.period != 'live',
                highlightCircleSize: 3,
                animatedZooms: true,
                hideOverlayOnMouseOut: true,
                legend: 'onmouseover',
                showLabelsOnHighlight: false,
                labelsSeparateLines: true,
                labels: this.props.chart.labels,
                zoomCallback: this.props.OnChartZoom.bind(this),
                highlightCallback: this.props.OnChartMouseOver.bind(this),
                displayAnnotations: true
            }
            const axisSeries = [];
            for (let i in this.props.chart.series) {
                axisSeries[this.props.chart.series[i].title] = {
                    axis: this.props.chart.series[i].axis,
                    strokeWidth: this.props.chart.series[i].chart_type == 'bar' ? 1 : 3
                };
                if (this.props.chart.series[i].chart_type == 'bar') {
                    axisSeries[this.props.chart.series[i].title].plotter = barChartPlotter.bind(this.props.chart);
                } else if (this.props.chart.series[i].chart_type == 'line') {
                    axisSeries[this.props.chart.series[i].title].plotter = window.smoothPlotter;
                } else if (this.props.chart.series[i].chart_type == 'area') {
                    axisSeries[this.props.chart.series[i].title].fillGraph = true;
                }
            }
            if (this.props.chart.axes) { options.axes = this.props.chart.axes;}
            if (axisSeries) { options.series = axisSeries;} else { options.series = []; }
            if (this.props.chart.colors) { options.colors = this.props.chart.colors; } else {options.colors = []; }
            if (this.props.chart.labels) { options.labels = this.props.chart.labels; } else { options.labels = []; }
            if (this.dygraphDiv) {
                this.dygraph = new Dygraph(this.dygraphDiv, this.props.data[this.props.chart_id + (this.props.widget_id ? '_' + this.props.widget_id : '')], options)
                if (this.props.mode == 'widget') {
                    this.dygraph.updateOptions({interactionModel: {}});
                }
                if (this.props.chart.annotations && this.props.chart.annotations.length < 50) {
                    this.dygraph.setAnnotations(this.props.chart.annotations)
                }
            }
        }
        if (!prevProps.chart) {
            this.props.Refresh();
        }
    }
}
