import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';

import {
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    Snackbar
} from "@material-ui/core";

import {
    Save as SaveIcon,
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";

export default class EmailSettings extends Component {
    constructor(props) {
        super(props);
        this.intervals = [];
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        return (
            <React.Fragment>
                <Helmet title="Email Settings"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Email Settings</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>&nbsp;&nbsp;
                                    </h1>
                                </div>
                            </Grid>
                        </Grid>
                        <form noValidate autoComplete="off" style={{width:'100%'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <strong>SMTP Client</strong>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={this.props.errors["host"] && this.props.errors["host"].length}
                                        fullWidth
                                        size="small"
                                        label="Host"
                                        variant="outlined"
                                        value={this.props.host}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.smtp.host',e.target.value)}
                                    />
                                    {this.props.errors["host"] && <div className="error">{this.props.errors["host"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Port"
                                        variant="outlined"
                                        value={this.props.port}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.smtp.port',e.target.value)}
                                    />
                                    {this.props.errors["port"] && <div className="error">{this.props.errors["port"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Login"
                                        variant="outlined"
                                        value={this.props.login}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.smtp.auth.user',e.target.value)}
                                    />
                                    {this.props.errors["login"] && <div className="error">{this.props.errors["login"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={this.props.password}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.smtp.auth.pass',e.target.value)}
                                    />
                                    {this.props.errors["password"] && <div className="error">{this.props.errors["password"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="From"
                                        variant="outlined"
                                        value={this.props.from}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.from',e.target.value)}
                                    />
                                    {this.props.errors["from"] && <div className="error">{this.props.errors["from"]}</div>}
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Alerts</strong>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Admin email addresses"
                                        variant="outlined"
                                        multiline={true}
                                        value={this.props.admin_addresses}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('email_settings.admin_addresses',e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }


}
