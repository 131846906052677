import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';

import {
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    Snackbar
} from "@material-ui/core";

import {
    Save as SaveIcon,
    Link as ConnectIcon,
    LinkOff as DisconnectIcon,
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";

export default class PortalSettings extends Component {
    constructor(props) {
        super(props);
        this.intervals = [];
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        return (
            <React.Fragment>
                <Helmet title="Portal Settings"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1 style={{color:this.props.connected ? 'green' : 'red'}}>Portal Settings</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>&nbsp;&nbsp;
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                                onClick={()=>this.props.OnConnectBtnClick(!this.props.connected)}>
                                            { this.props.connected ? <DisconnectIcon /> : <ConnectIcon/> }
                                            { this.props.connected ? "Disconnect" : "Connect" }
                                        </Button>
                                    </h1>
                                </div>
                            </Grid>
                        </Grid>
                        <form noValidate autoComplete="off" style={{width:'100%'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        error={this.props.errors["host"] && this.props.errors["host"].length}
                                        fullWidth
                                        size="small"
                                        label="Host"
                                        variant="outlined"
                                        value={this.props.host}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.host',e.target.value)}
                                    />
                                    {this.props.errors["host"] && <div className="error">{this.props.errors["host"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Port"
                                        variant="outlined"
                                        value={this.props.port}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.port',e.target.value)}
                                    />
                                    {this.props.errors["port"] && <div className="error">{this.props.errors["port"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Login"
                                        variant="outlined"
                                        value={this.props.login}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.login',e.target.value)}
                                    />
                                    {this.props.errors["login"] && <div className="error">{this.props.errors["login"]}</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={this.props.password}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.password',e.target.value)}
                                    />
                                    {this.props.errors["password"] && <div className="error">{this.props.errors["password"]}</div>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Send data period (sec)"
                                        variant="outlined"
                                        value={this.props.send_to_portal_period}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.send_to_portal_period',e.target.value)}
                                    />
                                    {this.props.errors["send_to_portal_period"] && <div className="error">{this.props.errors["send_to_portal_period"]}</div>}
                                </Grid>                                
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Keep alive period (sec)"
                                        variant="outlined"
                                        value={this.props.keep_alive_interval}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField('portal_settings.keep_alive_interval',e.target.value)}
                                    />
                                    {this.props.errors["keep_alive_interval"] && <div className="error">{this.props.errors["keep_alive_interval"]}</div>}
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }


}
