import React,{Component} from "react";
import Hm305pSettingsFieldsContainer from "../../../containers/inventory/settings/Hm305pSettingsFields";

export default class Hm305pSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const Hm305pSettingsFields = Hm305pSettingsFieldsContainer.getComponent();
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                <Hm305pSettingsFields deviceId={this.props.deviceId}/>
            </div>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
