import Base from './Base';
import Component from '../pages/auth/SignIn';
import {connect} from 'react-redux';
import HttpClient from "../services/HttpClient";

/**
 * Controller for Routes component
 */
export default class SignIn extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!SignIn.component) {
            const item = new SignIn();
            SignIn.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return SignIn.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
            login:state.login,
            password:state.password,
            errors:state.errors
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        this.errors = {};
        this.successMessageText = "";
        this.applyPropsToState();
    }

    async DoLogin() {
        if (this.validateForm()) {
            const response = await HttpClient.request("/login/post_login","post",{login:this.login,password:this.password});
            this.errors = {};
            if (response.data) {
                if (response.data.token) {
                    window.location.reload();
                    return;
                }
                if (response.data.status === "error") {
                    this.errors["general"] = response.data.message || "Server error";
                }
            } else {
                this.errors["general"] = "Server error";
            }
        }
        this.applyPropsToState();
    }

    validateForm() {
        this.errors = {};
        if (!this.login) { this.errors['login'] = "Login not specified"; }
        if (!this.password) { this.errors['password'] = "Password not specified"; }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }
}
