import Base from './../../Base';
import Component from '../../../pages/inventory/settings/SMASpeedwireSettings';
import GeneralSettingsComponent from "../../../pages/inventory/settings/SMASpeedwireGeneralSettings";
import {connect} from 'react-redux';
import Store from "../../../store/Store";
import _ from 'lodash';
import HttpClient from '../../../services/HttpClient';

/**
 * Controller for SMA Speedwire Settings component
 */
export default class SMASpeedwireSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!SMASpeedwireSettings.component) {
            const item = new SMASpeedwireSettings();
            SMASpeedwireSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return SMASpeedwireSettings.component;
    }

    static generalSettingsComponent = null;
    static getGeneralSettingsComponent() {
        if (!SMASpeedwireSettings.generalSettingsComponent) {
            const item = new SMASpeedwireSettings();
            SMASpeedwireSettings.generalSettingsComponent =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(GeneralSettingsComponent);
        }
        return SMASpeedwireSettings.generalSettingsComponent;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                props['config'] = config;
            } else {
                props['config'] = ownProps.config;
            }
        }
        props.devicesList = state.devicesList;
        props.errors = ownProps.errors;
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    ChangeDeviceField(name,value) {
        const state = Store.getState();
        this.selectedDevice = state.selectedDevice;
        if (this.deviceId !== 'new') {
            const deviceIndex = state.config.inventory.lan.findIndex((item) => item.id === this.deviceId);
            if (deviceIndex == -1) { return }
            let device = state.config.inventory.lan[deviceIndex];
            if (!device['alerts']) { device['alerts'] = {}; }
            eval("device"+Store.getPropertyNameExpression(name)+ " = _.cloneDeep(value);");
            state.config.inventory.lan[deviceIndex] = device;
            Store.changeProperty('config', state.config);
        } else {
            state.newDevice[name] = value;
            Store.changeProperty('newDevice',_.cloneDeep(state.newDevice));
        }
    }

    async Init() {
        const response = await HttpClient.request("/sma/getDeviceTypes","get");
        if (response && response.data && response.data.list) {
            this.ChangeField('devicesList',response.data.list);
        }
    }

    async DiscoverDevice() {
        if (!this.host) { window.alert("Host not specified"); return }
        const response = await HttpClient.request("/sma/discoverDevice","post",{host:this.host,port:this.port});
        if (response && response.data && response.data.id) {
            const models = this.devicesList.filter(item => item.id == response.data.id);
            this.ChangeDeviceField("device_model_id",response.data.id);
            if (models.length) {
                this.ChangeDeviceField("title",models[0].title+" ("+response.data.serial+")")
            }
        } else if (response.data.message) {
            window.alert(response.data.message);
        }
    }

}
