import React,{Component} from "react";
import styled from "styled-components";

import Helmet from 'react-helmet';

import {
    FormControl,
    Input,
    InputLabel,
    Button as MuiButton,
    Paper,
    Typography,
    Snackbar
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {Alert} from "@material-ui/lab";


const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

export default class SignUp extends Component {
  render() {
      return (
          <Wrapper>
              <Helmet title="Sign In"/>
              <Typography component="h1" variant="h4" align="center" gutterBottom>
                  Sign Up
              </Typography>
              {this.props.successMessageText ?
                  <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={true}
                      message={this.props.successMessageText}
                  /> : null
              }
              {this.props.errors && this.props.errors['general'] && <Alert severity="error">{this.props.errors["general"]}</Alert>}
              <Typography component="h2" variant="body1" align="center">
                  Register your site on Offgrid Portal
              </Typography>
              <form>
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input id="email" name="email" autoComplete="email" autoFocus
                             error={this.props.errors['email']}
                             helperText="Email"
                             onChange={(e) => this.props.ChangeField('email', e.target.value)}
                      />
                      {this.props.errors["email"] && <span className="error">{this.props.errors["email"]}</span> }
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <Input id="email" name="name" autoComplete="name"
                             error={this.props.errors['name']}
                             helperText="Name"
                             onChange={(e) => this.props.ChangeField('name', e.target.value)}
                      />
                      {this.props.errors["email"] && <span className="error">{this.props.errors["email"]}</span> }
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                          error={this.props.errors['password']}
                          name="password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          onChange={(e) => this.props.ChangeField('password', e.target.value)}
                      />
                      {this.props.errors["password"] && <span className="error">{this.props.errors["password"]}</span> }
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">Confirm Password</InputLabel>
                      <Input
                          error={this.props.errors['password']}
                          name="confirmPassword"
                          type="password"
                          id="confirmPassword"
                          autoComplete="current-password"
                          onChange={(e) => this.props.ChangeField('confirmPassword', e.target.value)}
                      />
                      {this.props.errors["confirmPassword"] && <span className="error">{this.props.errors["confirmPassword"]}</span> }
                  </FormControl>
                  <Button
                      to="/"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.props.DoSignUp()}
                      mb={2}
                  >
                      Sign Up
                  </Button>
              </form>
          </Wrapper>
      );
  }

    componentDidMount() {
        this.props.Init();
    }

}

