import React,{Component} from "react";
import {
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
} from "@material-ui/core";
import {
   RefreshOutlined
} from "@material-ui/icons";
export default class SMASpeedwireGeneralSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                <TextField
                    error={this.props.errors["device_model_id"] && this.props.errors["device_model_id"].length}
                    select
                    label="Device Model"
                    size="small"
                    disabled={this.props.deviceId != 'new'}
                    value={this.props.config ? this.props.config.device_model_id : ""}
                    onChange={(e) => this.props.ChangeDeviceField("device_model_id", e.target.value)}
                    SelectProps={{
                        native: true
                    }}
                    variant="outlined"
                >
                    <option value=""/>
                    {this.props.devicesList.map(device => <option key={"sma_device_" + device.id}
                                                                  value={device.id}>{device.id} - {device.title}</option>)}
                </TextField>
                <Button mr={2} variant="contained" className="pull-right" color="primary"
                        style={{color: 'white', marginLeft: 10}}
                        onClick={() => this.props.DiscoverDevice()}>
                    <RefreshOutlined style={{color: 'white', fill: 'white'}}/>
                    Discover
                </Button>
                {this.props.errors["device_model_id"] && <div className="error">{this.props.errors["device_model_id"]}</div>}
            </div>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
