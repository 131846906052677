import React,{Component} from "react";
import BQ76952SettingsFieldsContainer from "../../../containers/inventory/settings/BQ76952SettingsFields";

export default class BQ76952Settings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const BQ76952SettingsFields = BQ76952SettingsFieldsContainer.getComponent();
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                <BQ76952SettingsFields deviceId={this.props.deviceId}/>
            </div>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
