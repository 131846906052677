import Base from './../Base';
import Component from '../../pages/settings/EmailSettings';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";

/**
 * Controller for Email Settings component
 */
export default class EmailSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!EmailSettings.component) {
            const item = new EmailSettings();
            EmailSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return EmailSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
        },{
            host: state.email_settings.smtp.host || "",
            port: state.email_settings.smtp.port || "",
            login: state.email_settings.smtp.auth.user || "",
            password:state.email_settings.smtp.auth.pass || '',
            from:state.email_settings.from || "",
            admin_addresses:state.email_settings.admin_addresses || "",
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        if (!this.host) { await this.load(); }
        super.Init();
    }

    async Save() {
        this.ChangeField('errors',{});
        if (this.validate()) {
            this.ChangeField('isLoading',true);
            const state = Store.getState();
            let response = await HttpClient.request("/email/save","post",state.email_settings);
            this.ChangeField("isLoading",false);
            if (response && response.data && response.data.status && response.data.status === "ok") {
                Store.changeProperties({
                    isLoading:false,
                    successMessageText:"Changes saved successfully"
                });
                setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
            } else {
                if (response && response.data && response.data.status && response.data.status === "error") {
                    this.errors['general'] = response.data.message;
                } else {
                    this.errors['general'] = 'Error saving changes';
                }
                this.applyPropsToState("errors");
            }
        } else {
            this.applyPropsToState("errors");
        }
    }

    validate() {
        if (!this.host) { this.errors['host'] = 'Host not specified';}
        if (!this.port) { this.errors['port'] = 'Port not specified';}
        if (!this.login) { this.errors['login'] = 'Login not specified';}
        if (!this.password) { this.errors['password'] = 'Password not specified';}
        if (!this.password) { this.errors['password'] = 'Password not specified';}
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async load() {
        this.ChangeField("isLoading",true);
        const response = await HttpClient.request("/email/load","get",{});
        if (response && response.data) {
            Store.changeProperties({
                isLoading:false,
                email_settings:response.data.config
            });
        }
    }
}
