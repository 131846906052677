import Base from './Base';
import Component from '../pages/phasecontrol/PhaseControl';
import { connect } from 'react-redux';
import HttpClient from '../services/HttpClient';
import Store from "../store/Store";
import _ from 'lodash';

/**
 * Controller for PhaseControl List component
 */
export default class PhaseControl extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!PhaseControl.component) {
            const item = new PhaseControl();
            PhaseControl.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return PhaseControl.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state, ownProps) {
        var isOld = false;
        if (typeof (this.isOld) !== 'undefined') {
            isOld = this.isOld
        }
        const props = {
            phaseControl: state.phaseControl,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            history: ownProps.history,
            status: state.status,
            isTriggering: state.isTriggering,
            isSaving: state.isSaving,
            isChangingSetting: state.isChangingSetting || false,
            isChangeEnable: state.isChangeEnable || false,
            oldEnable: isOld,
            RRLevel0: "loading",
            RRLevel1: "loading",
            RRLevel2: "loading",

        };
        if(typeof state.phaseControl !== 'undefined'&& typeof state.phaseControl.table[2] !== 'undefined')
        {
            props.RRLevel0 = state.phaseControl.table[0].soc
            props.RRLevel1 = state.phaseControl.table[1].soc
            props.RRLevel2 = state.phaseControl.table[2].soc
        }
        return Object.assign(super.setupStateProps(state, ownProps), props);
    }
    Reload() {
        this.load();
    }
    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        // if (this.phaseControl === null) 
        {
            await this.load();
            await this.getStatus();
        }
        if (!this.intervals || !this.intervals.length || typeof (this.intervals) === 'undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof (this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.getStatus();
                    this.isChecking = false;
                }
            }, 1 * 1000));
        }
    }

    async getStatus() {
        /* Get the current SOC value */
        // if ((this.selectedTab !== 0 && this.selectedTab !== 3) || !this.selectedDevice) { return; }
        try {
            const response = await HttpClient.request('/phasecontrol/getStatus', "get", {});
            // if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            if (response)
                this.ChangeField("status", response.data.info);
        }
        catch (err) {
            console.log("Error :" + err);
        }

    }

    async load() {
        this.ChangeField("isLoading", true);
        const response = await HttpClient.request("/phasecontrol/load", 'get', {});
        console.log(JSON.stringify(response))
        try {
            if (response.data.status === 'ok' && response.data.config && typeof (response.data.config) === 'object') {
                this.ChangeField("phaseControl", response.data.config);
                this.ChangeField("isLoading", false);
            }
        } catch (error) {
            console.log("Error :" + error);
        }
    }

    updatePhasecontrolStateFromProps() {
        const state = Store.getState();
        // const phasecontroll = state.phaseControl;
        Store.changeProperty("phaseControl", _.cloneDeep(state.phaseControl))

    }

    /**
     * Method used to check if current device or unit is online (e.g. sending data)     
     * */
    EnablePhaseControl(isEn) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.enable != isEn) {
            state.isChangingSetting = true;
            state.isChangeEnable = true;
        }
        if (typeof (this.isOld) === 'undefined') {
            this.isOld = control.enable
        }
        // this.oldEnable=control.enable
        control.enable = isEn;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            isChangeEnable: state.isChangeEnable,
            phaseControl: control
        });
        this.updatePhasecontrolStateFromProps();
    }

    SelectAdamDevice(name) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.adam.activeId != name)
            state.isChangingSetting = true;
        control.adam.activeId = name;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            phaseControl: control
        });
        Store.changeProperties({ phaseControl: control });
        this.updatePhasecontrolStateFromProps();
    }

    GetRelay(index) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        return control.adam.activeRelays[index]
    }
    SelectAdamRelay(index, relay) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.adam.activeRelays[index] != relay) {
            state.isChangingSetting = true;
        }
        control.adam.activeRelays[index] = relay;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            phaseControl: control
        });
    }

    SelectSunnyDevice(name) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.sunny.activeId != name) {
            state.isChangingSetting = true;
        }
        control.sunny.activeId = name;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            phaseControl: control
        });
    }

    UpdateRelayRRTable(row, index, value) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        control.table[row].relays[index] = value;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            phaseControl: control
        });
    }

    UpdateSocRRTable(row, value) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.table[row].soc != value) {
            state.isChangingSetting = true;
        }
        control.table[row].soc = value;
        var entry = "RRLevel" + row;;
        control[entry] = value;
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            entry: value,
            phaseControl: control
        });
    }

    RREntryMove(from_index, to_index) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (to_index >= 0 && to_index < _.toArray(control.table).length) {
            var swap = _.cloneDeep(control.table[from_index]);
            control.table[from_index] = _.cloneDeep(control.table[to_index]);
            control.table[to_index] = swap;
            Store.changeProperties({ phaseControl: control });
        }
    }
    RREntryRemove(index) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        if (control.table.length == 1)
            return;
        control.table.splice(index, 1);
        Store.changeProperties({ phaseControl: control });
    }
    RREntryAdd(index) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        var newEntry = { soc: 0, relays: ["off", "off", "off"] };
        control.table.splice(index, 0, newEntry);
        Store.changeProperties({ phaseControl: control });

    }

    ScheduleUpdate(date) {
        const state = Store.getState();
        var control = _.cloneDeep(state.phaseControl);
        state.isChangingSetting = true;
        control.scheduleTime = date.getHours() + ":" + date.getMinutes();
        Store.changeProperties({
            isChangingSetting: state.isChangingSetting,
            phaseControl: control
        });
    }

    async TriggerPhasecontrol() {
        console.log("Trigger Event");
        this.ChangeField("isTriggering", true);
        let response = await HttpClient.request("/phasecontrol/post_trigger", "post", {});
        if (response && response.data && response.data.status && response.data.status === "ok") {
            Store.changeProperties({
                isTriggering: false,
                successMessageText: "Changes saved successfully"
            });
            setTimeout(() => { this.ChangeField("successMessageText", "") }, 3000);
        }
        else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error Sending Trigger ';
            }
            Store.changeProperties({
                isTriggering: false,
                successMessageText: "Error Sending Trigger"
            });
            this.applyPropsToState("errors");
        }
    }


    async SaveSetting() {
        console.log("Save new setting");
        this.ChangeField("isSaving", true);
        const state = Store.getState();
        let response = await HttpClient.request("/phasecontrol/post_savephasecontrol", "post", { phaseControl: state.phaseControl });
        if (response && response.data && response.data.status && response.data.status === "ok") {
            Store.changeProperties({
                isSaving: false,
                isChangingSetting: false,
                isChangeEnable: false,
                // oldEnable: state.phaseControl.enable,
                successMessageText: "Changes saved successfully"
            });
            // if (typeof (this.isOld) !== 'undefined') {
            this.isOld = state.phaseControl.enable;
            // }
            setTimeout(() => { this.ChangeField("successMessageText", "") }, 3000);
        }
        else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }

    }
    validate() {
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

}
