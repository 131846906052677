import Base from './../Base';
import Component from '../../pages/settings/NetworkSettings';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";

/**
 * Controller for Network Settings component
 */
export default class NetworkSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!NetworkSettings.component) {
            const item = new NetworkSettings();
            NetworkSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return NetworkSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
        },{
            ip_address: state.lan_settings.ip_address || "",
            ip_netmask: state.lan_settings.ip_netmask || "",
            ip_gateway: state.lan_settings.ip_gateway || "",
            mode:state.lan_settings.mode,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        if (!this.mode) {
            await this.load();
        }
        super.Init();
    }

    async Save() {
        this.ChangeField('errors',{});
        if (this.validate()) {
            this.ChangeField('isLoading',true);
            const state = Store.getState();
            let response = await HttpClient.request("/network/post_ethernet_save","post",{
                lan_ip_address:this.ip_address,
                lan_ip_netmask:this.ip_netmask,
                lan_ip_gateway:this.ip_gateway,
                lan_mode:this.mode
            });
            this.ChangeField("isLoading",false);
            if (response && response.data && response.data.status && response.data.status === "ok") {
                Store.changeProperties({
                    isLoading:false,
                    successMessageText:"Changes saved successfully",
                    password:"",
                    confirmPassword:""
                });
                setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
            } else {
                if (response && response.data && response.data.status && response.data.status === "error") {
                    this.errors['general'] = response.data.message;
                } else {
                    this.errors['general'] = 'Error saving changes';
                }
                this.applyPropsToState("errors");
            }
        } else {
            this.applyPropsToState("errors");
        }
    }

    validate() {
        if (!this.mode) { this.errors['mode'] = 'Mode not specified'; }
        if (this.mode === 'static') {
            if (!this.ip_address) { this.errors['ip_address'] = 'IP address not specified'; }
            if (!this.ip_netmask) { this.errors['ip_netmask'] = 'IP netmask not specified'; }
            if (!this.ip_gateway) { this.errors['ip_gateway'] = 'IP gateway not specified'; }
        }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async load() {
        this.ChangeField("isLoading",true);
        const response = await HttpClient.request("/network/get_settings","get",{});
        if (response && response.data) {
            Store.changeProperties({
                isLoading:false,
                lan_settings:response.data.config.lan
            });
        }
    }

}
