import axios from 'axios';
import getBackendUrl from '../utils/getBackendUrl';
import Store from '../store/Store';
/**
 * Class used to communicate with Backend server via HTTP, based on Axios
 */
class HttpClient {

    /**
     * Sends HTTP request on server and returns raw response
     * @param url - relative URL on server
     * @param method - request method: GET, POST, PUT, DELETE etc.
     * @param data - Form data for POST and PUT requests, in JSON format {key1:value1,key2:value2}
     * @returns Promise with response. response.status contains HTTP status of response, response.data contains response itself
     */
    request(url,method,data) {
        return new Promise(async(resolve) => {
            try {
                const params = {
                    url: getBackendUrl()+url,
                    method: method,
                    data: data,
                    headers: { token: localStorage.getItem("token") }
                };
                let response = await axios(params);
                if (response && response.data && response.data.token) {
                    localStorage.setItem("token",response.data.token);
                }
                resolve(response);
            } catch (err) {
                const response = err.response;
                if (response) {
                    if (response && response.data && response.data.token) {
                        localStorage.setItem("token",response.data.token);
                    }
                    if (response.status === 401 && window.location.pathname !== "/") {
                        window.location.href = "/";
                    }
                    resolve(err.response)
                } else {
                    resolve({status:404})
                }
            }
        })
    }
}

export default new HttpClient();
