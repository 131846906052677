import actions from '../actions/Actions';
import Store from '../store/Store';

/**
 * Root reducer function
 * @param state: Current state before change
 * @param action: Action, which should be applied to state
 * @returns new state after apply action
 */
export default function rootReducer(state,action) {
    if (!state) state = {
        config:{},
        status:{},
        lan_settings:{},
        portal_settings:{},
        email_settings:{
            smtp:{
                auth:{}
            }
        },
        alerts_settings:{},
        charts_settings:{},
        successMessageText:"",
        errors: {},
        isAuthorized:false,
        login:'',
        email:'',
        password:'',
        confirmPassword:'',
        name:'',
        error:'',
        isLoading:false,
        selectedTab:0,
        selectedSubTab:0,
        selectedCondition:0,
        selectedUnit:"",
        selectedField:"",
        newDevice:{},
        newFlow:{blocks:[]},
        data:{},
        isChartLoading:false,
        newChartId:0,
        current_time:0,
        show_all_fields:true,
        terminalCommand:'',
        terminalBuffer:[],
        selectedRelay:'',
        editMode:false,
        editFields:{},
        flows:[],
        selectedBlockIndex:null,
        lastTimestamp:0,
        isUpdating:false,
        isRestarting:false,
        devicesList:[],
        addUnitDisplayed:false,
        unitId:"",
        unitNumber:"",
        unitTitle:"",
        unitModel:""
    };

    let newState = require('lodash').cloneDeep(state);
    switch (action.type) {
        case actions.types.CHANGE_PROPERTY:
            changeProperty(action.name,action.value,newState);
            break;
        case actions.types.CHANGE_PROPERTIES:
            for (let name in action.properties) changeProperty(name,action.properties[name],newState);
            break;
        default:
            break;
    }
    return newState;
}

/**
 * Method used to change property in application state. Supports hierarchical properties
 * @param name - Name of property to change
 * @param value - new property value
 * @param newState - link to application state
 * @returns Modified application state
 */
const changeProperty = function(name,value,newState) {
    eval("newState"+Store.getPropertyNameExpression(name)+ " = _.cloneDeep(value);");
    return newState
};
