import React,{Component} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

export default class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    {this.props.isAuthorized ? childRoutes(DashboardLayout, dashboardLayoutRoutes) : null }
                    {childRoutes(AuthLayout, authLayoutRoutes)}
                    <Route
                        render={() => (<div></div>)}
                    />
                </Switch>
            </Router>
        )
    }
}
