import React, { Component } from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';
import {
    Card,
    Paper,
    Grid,
    Button,
    Snackbar,
    TextField,
    Tabs,
    Tab,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

import {
    Save as SaveIcon,
    DeleteForever as DeleteIcon,
    VerticalAlignBottom,
    VerticalAlignTop
} from "@material-ui/icons";

import { CircularProgress } from "@material-ui/core";
import DeviceStatusContainer from "../../containers/inventory/DeviceStatus";
import IndividualFieldsSettingContainer from "../../containers/inventory/IndividualFieldsSetting"
import Adam6000StatusContainer from '../../containers/inventory/Adam6000DeviceStatus';
import ClusterControllerSettingsContainer from "../../containers/inventory/settings/ClusterControllerSettings";
import Adam6000SettingsContainer from "../../containers/inventory/settings/Adam6000Settings";
import BQ76952SettingsContainer from "../../containers/inventory/settings/BQ76952Settings";
import Adam6000SettingsAlertsContainer from "../../containers/inventory/settings/Adam6000SettingsAlerts";
import Hm305pSettingsContainer from "../../containers/inventory/settings/Hm305pSettings";
import Hm305pStatusContainer from "../../containers/inventory/Hm305pDeviceStatus";
import SMAElgrisEnergyMeterSettingsContainer from "../../containers/inventory/settings/SMAElgrisEnergyMeterSettings";
import SMASpeedwireSettingsContainer from "../../containers/inventory/settings/SMASpeedwireSettings";
import GeneralModbusTcpSettingsContainer from "../../containers/inventory/settings/GeneralModbusTcpSettings"
import data from "../../config/data";

export default class Inventory extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{ textAlign: 'center', width: "100%" }}><CircularProgress color="primary" /></div>
        }
        if (!this.props.config) { return null; }
        return (
            <React.Fragment>
                <Helmet title="Inventory" />
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{ padding: 10 }}>
                        {this.props.errors['general'] && <div mb={4} style={{ backgroundColor: "#ffaaaa", padding: 10, marginBottom: 10 }}
                            dangerouslySetInnerHTML={{ __html: this.props.errors["general"].replace(/\n/g, "<br/>") }} />
                        }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Inventory</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    <Button mr={2} variant="contained" className="pull-right" style={{ backgroundColor: "#007700", color: 'white' }}
                                        onClick={() => this.props.DownloadSettings()}>
                                        <VerticalAlignBottom style={{ color: 'white', fill: 'white' }} />
                                        Download settings
                                    </Button>&nbsp;&nbsp;
                                    <Button mr={2} variant="contained" className="pull-right" style={{ backgroundColor: "#007700", color: 'white' }}
                                        onClick={() => this.props.UploadSettings()}>
                                        <VerticalAlignTop style={{ color: 'white', fill: 'white' }} />
                                        Upload settings
                                    </Button>&nbsp;&nbsp;
                                    <input type="file" id={"uploadImage"} style={{ display: 'none' }}
                                        onInput={() => this.props.OnFileSelected()}
                                    />
                                    <Button mr={2} variant="contained" color="primary" className="pull-right"
                                        onClick={() => this.props.Save()}>
                                        <SaveIcon />
                                        Save Changes
                                    </Button>&nbsp;&nbsp;
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <TextField
                                    value={this.props.selectedDevice}
                                    fullWidth
                                    small
                                    label="Device"
                                    select variant="outlined"
                                    SelectProps={{ native: true }}
                                    onChange={(e) => {
                                        this.props.ChangeField("errors", {});
                                        this.props.history.push("/inventory/" + e.target.value);
                                    }}
                                >
                                    <option value="0">--- Select device ---</option>
                                    {
                                        this.props.config.map(device =>
                                            <option key={"device_" + device.id} value={device.id}>{device.title}</option>
                                        )
                                    }
                                    <option value="new">Add new device &gt;</option>
                                </TextField>
                            </Grid>
                        </Grid>
                        {this.props.selectedDeviceObject && this.renderDevice()}
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    renderDevice() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <table style={{ width: '100%' }}>
                        <tbody style={{ width: '100%' }}>
                            <tr>
                                <td>
                                    <Tabs value={this.props.selectedTab} onChange={(event, value) => this.props.ChangeField('selectedTab', value)}>
                                        <Tab label="Status" style={{ display: this.props.selectedDevice === 'new' ? 'none' : '' }} />
                                        <Tab label="General Settings" />
                                        <Tab label="Alerts" />
                                        <Tab label="Individual Fields Setting" />
                                        {this.props.selectedDeviceObject && this.getDeviceSettingsComponent(this.props.selectedDeviceObject.type) ?
                                            <Tab label="Device Settings" style={{ display: this.props.selectedDevice === 'new' ? 'none' : '' }} /> : null
                                        }

                                    </Tabs>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {this.props.selectedDevice !== 'new' &&
                                        <div style={{ width: '100%', textAlign: 'right' }}>
                                            <Button mr={2} variant="contained" color="primary" onClick={() => this.props.Delete()}>
                                                <DeleteIcon />
                                                Delete device
                                            </Button>
                                        </div>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ display: this.props.selectedTab == 0 ? '' : 'none', width: '100%' }}>
                        {this.renderDeviceStatus()}
                    </div>
                    <div style={{ display: this.props.selectedTab == 1 ? '' : 'none' }}>
                        {this.renderGeneralSettings()}
                    </div>
                    <div style={{ display: this.props.selectedTab == 2 ? '' : 'none' }}>
                        {this.renderAlerts()}
                    </div>
                    <div style={{ display: this.props.selectedTab == 3 ? '' : 'none' }}>
                        {this.renderIndividualSettings()}
                    </div>
                    <div style={{ display: this.props.selectedTab == 4 ? '' : 'none' }}>
                        {this.renderDeviceSettings()}
                    </div>
                </Grid>
            </Grid>
        )

    }

    renderGeneralSettings() {
        const GeneralSettingsComponent = this.renderGeneralSettingsComponent();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="ID"
                        variant="outlined"
                        value={this.props.id}
                        style={{ margin: 8 }}
                        error={this.props.errors["id"] && this.props.errors["id"].length}
                        onChange={(e) => this.props.ChangeDeviceField('id', e.target.value)}
                    />
                    {this.props.errors["id"] && <div className="error">{this.props.errors["id"]}</div>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={this.props.errors["type"] && this.props.errors["id"].length}
                        fullWidth
                        select
                        disabled={this.props.selectedDevice != 'new'}
                        label="Type"
                        size="small"
                        value={this.props.type}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField("type", e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="" />
                        {(() => {
                            const result = [];
                            for (let reader_id in data.reader_types) {
                                result.push(<option value={reader_id} key={"reader_type_" + reader_id}>{data.reader_types[reader_id]}</option>)
                            }
                            return result;
                        })()}
                    </TextField>
                    {this.props.errors["type"] && <div className="error">{this.props.errors["type"]}</div>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={this.props.errors["host"] && this.props.errors["host"].length}
                        fullWidth
                        size="small"
                        label="Host"
                        variant="outlined"
                        value={this.props.host}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField('host', e.target.value)}
                    />
                    {this.props.errors["host"] && <div className="error">{this.props.errors["host"]}</div>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={this.props.errors["title"] && this.props.errors["title"].length}
                        fullWidth
                        size="small"
                        label="Name"
                        variant="outlined"
                        value={this.props.title}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField('title', e.target.value)}
                    />
                    {this.props.errors["name"] && <div className="error">{this.props.errors["name"]}</div>}
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        error={this.props.errors["port"] && this.props.errors["port"].length}
                        fullWidth
                        size="small"
                        label="Port"
                        variant="outlined"
                        value={this.props.port}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField('port', e.target.value)}
                    />
                    {this.props.errors["port"] && <div className="error">{this.props.errors["port"]}</div>}
                </Grid>
                < Grid item xs={3}>
                    <TextField
                        error={this.props.errors["modbus_id"] && this.props.errors["modbus_id"].length}
                        fullWidth
                        size="small"
                        label="MobusId"
                        variant="outlined"
                        value={this.props.modbus_id ? this.props.modbus_id : 1}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField('modbus_id', e.target.value)}
                    />
                    {this.props.errors["modbus_id"] && <div className="error">{this.props.errors["modbus_id"]}</div>}
                </Grid>
                <Grid item xs={12}>
                    <strong>Intervals</strong>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Data read (ms)"
                        variant="outlined"
                        value={this.props.data_read_interval}
                        style={{ margin: 8 }}
                        onChange={(e) => this.props.ChangeDeviceField('data_read_interval', e.target.value)}
                    />
                    {this.props.errors["data_read_interval"] && <div className="error">{this.props.errors["data_read_interval"]}</div>}
                </Grid>
                {/* <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Sync database to portal (s)"
                        variant="outlined"
                        value={this.props.send_to_portal_period}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('send_to_portal_period',e.target.value)}
                    />
                    {this.props.errors["send_to_portal_period"] && <div className="error">{this.props.errors["send_to_portal_period"]}</div>}
                </Grid> */}
                {/* <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Save to DB (s)"
                        variant="outlined"
                        value={this.props.save_to_db_period}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('save_to_db_period',e.target.value)}
                    />
                    {this.props.errors["save_to_db_period"] && <div className="error">{this.props.errors["save_to_db_period"]}</div>}
                </Grid> */}
                <Grid item xs={4}>
                    <div style={{
                        padding: '5px', 'margin-left': '5%'
                    }}>{
                            <FormControlLabel
                                control={
                                    <Checkbox checked={this.props.enabled} onChange={(e) => this.props.ChangeDeviceField("enabled", e.target.checked)} />
                                }
                                label="Enabled"
                            />
                        }</div>
                </Grid>
                {/* <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.simulated} onChange={(e)=>this.props.ChangeDeviceField("simulated",e.target.checked)}/>
                        }
                        label="Simulated"
                    />
                </Grid> */}
                {GeneralSettingsComponent && <GeneralSettingsComponent
                    config={this.props.config}
                    deviceId={this.props.selectedDevice}
                    host={this.props.host}
                    port={this.props.port}
                    modbus_id={this.props.modbus_id}
                    errors={this.props.errors}
                />}
            </Grid>
        )
    }

    renderAlerts() {
        let Component = null;
        switch (this.props.selectedDeviceObject.type) {
            case 'adam6000':
                Component = Adam6000SettingsAlertsContainer.getComponent();
                break;
        }
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={this.props.alerts.alert_if_inactive} onChange={(e) => this.props.ChangeDeviceField("alerts.alert_if_inactive", e.target.checked)} />
                            }
                            label="Alert if inactive more than"
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="ms"
                            variant="outlined"
                            value={this.props.alerts.alert_if_inactive_period}
                            style={{ margin: 8 }}
                            onChange={(e) => this.props.ChangeDeviceField('alerts.alert_if_inactive_period', e.target.value)}
                        />
                    </Grid>
                </Grid>
                {Component && <Component deviceId={this.props.selectedDevice} />}
            </div>
        )
    }

    renderDeviceStatus() {
        let DeviceStatus = DeviceStatusContainer.getComponent();
        switch (this.props.selectedDeviceObject.type) {
            case 'adam6000':
                DeviceStatus = Adam6000StatusContainer.getComponent();
                break;
            case 'hm305p':
                DeviceStatus = Hm305pStatusContainer.getComponent();
                break;
            default:
                DeviceStatus = DeviceStatusContainer.getComponent();
        }
        return (
            <Grid container spacing={3} style={{ paddingTop: '15px', width: '100%' }}>
                {this.props.selectedDeviceObject.units && this.props.selectedDeviceObject.units.length && this.renderUnitsList()}
                <div style={{ padding: '10px', width: '100%' }}>
                    <DeviceStatus device={this.props.selectedDeviceObject} unit={this.props.selectedUnitObject} />
                </div>
            </Grid>

        )
    }

    renderIndividualSettings() {
        let IndividualFieldsSetting = IndividualFieldsSettingContainer.getComponent();
        return (
            <Grid container spacing={3} style={{ paddingTop: '15px', width: '100%' }}>
                {this.props.selectedDeviceObject.units && this.props.selectedDeviceObject.units.length && this.renderUnitsList()}
                <div style={{ padding: '10px', width: '100%' }}>
                    <IndividualFieldsSetting device={this.props.selectedDeviceObject} unit={this.props.selectedUnitObject} />
                </div>
            </Grid>

        )
    }


    renderGeneralSettingsComponent() {
        switch (this.props.selectedDeviceObject.type) {
            case 'sma_speedwire':
                return SMASpeedwireSettingsContainer.getGeneralSettingsComponent();
                break;
            default:
                return null;
        }
    }

    renderUnitsList() {
        return (
            <TextField
                fullWidth
                select
                label="Unit"
                size="small"
                value={this.props.selectedUnit}
                style={{ margin: 8 }}
                onChange={(e) => this.props.ChangeField("selectedUnit", e.target.value)}
                SelectProps={{
                    native: true
                }}
                variant="outlined"
            >
                {this.props.selectedDeviceObject.units.filter(unit => unit.title && unit.title.length).map(unit =>
                    <option key={"deviceunit_" + unit.id} value={unit.id}>{unit.title} ({unit.id})</option>
                )}
            </TextField>
        )
    }





    renderDeviceSettings() {
        let Component = this.getDeviceSettingsComponent(this.props.selectedDeviceObject.type);
        if (Component) { return <Component deviceId={this.props.selectedDevice} /> }
        return null;
    }

    getDeviceSettingsComponent(type) {
        let Component = null;
        switch (type) {
            case 'speedwire':
                Component = ClusterControllerSettingsContainer.getComponent();
                break;
            case 'cluster_controller':
                Component = ClusterControllerSettingsContainer.getComponent();
                break;
            case 'adam6000':
                Component = Adam6000SettingsContainer.getComponent();
                break;
            case 'BQ76952':
                Component = BQ76952SettingsContainer.getComponent();
                break;
            case 'hm305p':
                // Component = Hm305pSettingsContainer.getComponent();
                Component = GeneralModbusTcpSettingsContainer.getComponent();
                break;
            case 'sma_elgris_energy_meter':
                // Component = SMAElgrisEnergyMeterSettingsContainer.getComponent();
                Component = GeneralModbusTcpSettingsContainer.getComponent();
                break;
            case 'sma_speedwire':
                Component = SMASpeedwireSettingsContainer.getComponent();
            case 'Reinhausen':
                Component = GeneralModbusTcpSettingsContainer.getComponent();
                break;
            case 'Umg605Meter':
            case 'rut950v2':
            case 'irradiance_Si':
            case 'naradaRack':
            case 'NaradaRackClient':
            case 'TriPowerX':
            case "HuaweiSun2000":
            case "HuaweiSmartLogger":
            case "Trumpf":
            case "LeochXY25":
                Component = GeneralModbusTcpSettingsContainer.getComponent();
                break;

        }
        return Component;
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }


}
