import Base from './../Base';
import Component from '../../pages/settings/GeneralSettings';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";

/**
 * Controller for General Settings component
 */
export default class GeneralSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!GeneralSettings.component) {
            const item = new GeneralSettings();
            GeneralSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return GeneralSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
        },{
            _id: state.config && state.config._id,
            title: state.config && state.config.title,
            lat:  state.config.location &&  state.config.location.lat,
            lng:  state.config.location &&  state.config.location.lng,
            address:  state.config.location && state.config.location.address,
            company:  state.config.location && state.config.support.company,
            description: state.config.support && state.config.support.description,
            engineer_name: state.config.support && state.config.support.engineer.name,
            engineer_surname: state.config.support && state.config.support.engineer.surname,
            engineer_contact_number: state.config.support && state.config.support.engineer.contact_number,
            engineer_email: state.config.support && state.config.support.engineer.email,
            engineer_description: state.config.support && state.config.support.engineer.description,
            manager_name: state.config.support && state.config.support.manager.name,
            manager_surname: state.config.support && state.config.support.manager.surname,
            manager_contact_number: state.config.support && state.config.support.manager.contact_number,
            manager_email: state.config.support && state.config.support.manager.email,
            manager_description: state.config.support && state.config.support.manager.description,
            password: state.password,
            confirmPassword: state.confirmPassword,
            errors: state.errors,
            successMessageText: state.successMessageText,
            isLoading: state.isLoading
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (!this.title) {
            await this.loadConfig();
        }
    }

    async Save() {
        this.ChangeField('errors',{});
        if (this.validate()) {
            this.ChangeField('isLoading',true);
            const state = Store.getState();
            let response = await HttpClient.request("/config/save","post",{config:state.config});
            if (response && response.data && response.data.status && response.data.status === "ok") {
                if (this.password) {
                    response = await HttpClient.request("/login/post_changePassword",'post',{password:this.password});
                    if (!response || !response.data || !response.data.status) {
                        this.errors['general'] = 'Error saving changes';
                        this.applyPropsToState("errors");
                        this.ChangeField("isLoading",false);
                        return;
                    }
                }
                Store.changeProperties({
                    isLoading:false,
                    successMessageText:"Changes saved successfully",
                    password:"",
                    confirmPassword:""
                });
                setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
            } else {
                if (response && response.data && response.data.status && response.data.status === "error") {
                    this.errors['general'] = response.data.message;
                } else {
                    this.errors['general'] = 'Error saving changes';
                }
                this.applyPropsToState("errors");
            }
        } else {
            this.applyPropsToState(["errors"]);
        }
    }

    validate() {
        if (!this.title) { this.errors['title'] = 'Site name not specified'; }
        if (this.password && this.password !== this.confirmPassword) { this.errors['password'] = 'Passwords must match'; }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async loadConfig() {
        this.ChangeField("isLoading",true);
        const response = await HttpClient.request("/config/load",'get',{});
        if (response.data.status === 'ok' && response.data.config && typeof(response.data.config) === 'object') {
            this.ChangeField('config',response.data.config);
            this.ChangeField("isLoading",false);
        }
    }

}
