import React,{Component} from "react";
import { TextField,Tooltip,Dialog,DialogTitle,DialogContent,DialogActions,Button } from "@material-ui/core";
import ClusterControllerSettingsFieldsContainer from "../../../containers/inventory/settings/ClusterControllerSettingsFields";
import { Clear as ClearIcon, Add as AddIcon, RefreshOutlined } from "@material-ui/icons";

export default class ClusterControllerSettings extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const ClusterControllerSettingsFields = ClusterControllerSettingsFieldsContainer.getComponent();
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                {this.renderUnitsList()}
                {this.props.selectedUnit && <ClusterControllerSettingsFields deviceId={this.props.deviceId} unitId={this.props.selectedUnit.toString()}/>}
                {this.renderDialog()}
            </div>
        )
    }

    renderUnitsList() {
        return (
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start'}}>
                <TextField
                    fullWidth
                    select
                    label="Unit"
                    size="small"
                    value={this.props.selectedUnit}
                    onChange={(e)=>this.props.ChangeField("selectedUnit",e.target.value)}
                    SelectProps={{
                        native: true
                    }}
                    variant="outlined"
                >
                    <option value={""}/>
                    {this.props.config.units && this.props.config.units.filter(unit=>unit.title && unit.title.length).map(unit =>
                        <option value={unit.id}>{unit.title} ({unit.id+","+unit.modbus_unit_id})</option>
                    )}
                </TextField>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start'}}>
                    <Tooltip title="Add unit" arrow>
                        <AddIcon fontSize="large" onClick={()=>this.props.OnAddUnitClick()} style={{cursor:'pointer',color:'green'}}/>
                    </Tooltip>
                    <Tooltip title="Delete unit" arrow>
                        <ClearIcon fontSize="large" onClick={()=>this.props.OnDeleteUnitClick()} style={{cursor:'pointer',color:'red'}}/>
                    </Tooltip>
                    <Tooltip title="Detect units" arrow>
                        <RefreshOutlined fontSize="large" onClick={()=>this.props.DetectUnits()} style={{cursor:'pointer',color:'blue'}}/>
                    </Tooltip>
                </div>
            </div>
        )
    }

    renderDialog() {
        return (
            <Dialog open={this.props.addUnitDisplayed} onClose={()=>this.props.ChangeField("addUnitDisplayed",false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Unit</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        value={this.props.newUnitId}
                        margin="dense"
                        id="name"
                        label="ID"
                        fullWidth
                        style={{marginBottom:10}}
                        onChange={(e)=>this.props.ChangeField("unitId",e.target.value)}
                    />
                    <TextField
                        autoFocus
                        value={this.props.unitNumber}
                        margin="dense"
                        id="name"
                        type="number"
                        label="Modbus Unit Number"
                        fullWidth
                        style={{marginBottom:10}}
                        onChange={(e)=>this.props.ChangeField("unitNumber",e.target.value)}
                    />
                    <TextField
                        autoFocus
                        value={this.props.unitTitle}
                        margin="dense"
                        id="name"
                        label="Title"
                        fullWidth
                        style={{marginBottom:20}}
                        onChange={(e)=>this.props.ChangeField("unitTitle",e.target.value)}
                    />
                    <TextField
                        error={this.props.errors["unitModel"] && this.props.errors["unitModel"].length}
                        select
                        fullWidth
                        label="Device Model"
                        size="small"
                        value={this.props.config ? this.props.config.unitModel : ""}
                        onChange={(e) => this.props.ChangeField("unitModel", e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value=""/>
                        {this.props.devicesList.map(device => <option key={"sma_device_" + device.id}
                                                                      value={device.id}>{device.id} - {device.title}</option>)}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>this.props.ChangeField("addUnitDisplayed",false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={()=>this.props.AddUnit()} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
