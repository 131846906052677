import React, { Component } from "react";
import _ from 'lodash';
import { Edit, Check, Cancel, ThumbDownSharp, RowingTwoTone } from "@material-ui/icons";
import Store from "../../store/Store";

import styled, { withTheme } from "styled-components";
import moment from 'moment-timezone';
import Helmet from 'react-helmet';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from "@material-ui/core/TableContainer";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SettingsIcon from '@material-ui/icons/Settings';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';
import Input from "@material-ui/core/Input";

import {
    KeyboardTimePicker
} from '@material-ui/pickers';

import {
    Grid,
    Card,
    CardHeader,
    IconButton,
    Chip as MuiChip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
    Tooltip,
    Button,
    Typography,
    Switch,
    TextField,
    CardContent as MuiCardContent,
    Tab,
} from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { purple, blue, green, red, orange, white, cyan, grey, lime } from "@material-ui/core/colors";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

// const useStyles = makeStyles((theme) => ({
//     margin: {
//       margin: theme.spacing(1),
//     },
//   }));
// const classes = useStyles();
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        }
        // ,
        // '&$disabled': {
        //     color: red[50],
        // }
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: green[200],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
    if (orderBy == "description") {
        orderBy = a.register ? "register" : a.id ? "id" : "title"
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const headCells = [
    { id: 'description', checkbox: false, disablePadding: true, label: 'Description' },
    { id: 'interval', checkbox: true, disablePadding: false, label: 'Aggregate' },
    { id: 'stream_live', checkbox: true, disablePadding: false, label: 'Stream Live' },
    { id: 'save_to_cotroller', checkbox: true, disablePadding: false, label: 'Save Database' },
    { id: 'save_to_portal', checkbox: true, disablePadding: false, label: 'Portal Sync' },
];

const tableIntervalLookUp = {
    "Disable": 0,
    "1 second": 1000,
    "5 seconds": 5000,
    "15 seconds": 15000,
    "30 seconds": 30000,
    "1 minute": 60000,
    "5 minutes": 300000,
    "10 minutes": 600000,
    "1 hour": 3600000,
    "1 day": 86400000,
    "Save once": -1
}

const tableDatabaseLimitTimeLookUp = {
    "100 days": 100,
    "365 days": 365,
    "1000 days": 1000,
    "unlimited": -1,
}

const filter = createFilterOptions();



export default class IndividualFieldsSetting extends Component {
    constructor(props) {
        super(props);
        this.columns_count = 5;

    }
    state = {
        order: "asc",
        orderBy: "description",
        searchValue: null,
        showAllFields: true,
        filterRows: {
            isCluster: false,
            id: null,
            value: []
        },
        timerId: null,
        checkAllState: {
            interval: true,
            stream_live: false,
            save_to_cotroller: false,
            save_to_portal: false

        },
        isSorted: true,
        page: 0,
        rowsPerPage: 10,
    }

    stableSort(array, comparator) {
        // if (this.state.isSorted == true) {
        //     return array
        // }
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        // this.setState({ isSorted: true })
        return stabilizedThis.map((el) => el[0]);
    }

    EnhancedTableHead(order, orderBy, onRequestSort) {
        // const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            try {
                onRequestSort(event, property);
                this.setState({ isSorted: false })
            } catch (error) {
                console.log(error)
            }

        };

        return (
            <TableHead>
                <TableRow>
                    {/* <TableCell /> */}
                    {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            align="center"
                            // padding={headCell.disablePadding ? 'none' : 'normal'}
                            padding="none"
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ fontSize: 17 }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                size='20px'
                                hideSortIcon={false}
                            >
                                {headCell.label}
                            </TableSortLabel>
                            {/* {headCell.label} */}
                            {headCell.checkbox ?
                                <FormControlLabel
                                    control={< BlueCheckbox checked={true}
                                        checked={this.state.checkAllState[headCell.id]}
                                        onChange={(event) => this.handleSelectAllChange(event.target.checked, headCell.id)}
                                    />}
                                /> : ""}
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: isAsc ? 'desc' : 'asc' })
        this.setState({ orderBy: property })
    };

    updateTable(newValue) {
        let targetField = null
        for (let field of _.toArray(this.props.config.fields)) {
            let label = (field.register ? field.register : field.id ? field.id : "") + " - " + field.title
            // if (label == newValue || newValue == null) {
            if (label == newValue) {
                // newRows.push(
                targetField = field
                // )
            }
        }
        this.setState({
            page: 0,
            rowsPerPage: 10,
            filterRows: {
                isCluster: this.props.config.modbus_unit_id ? true : false,
                id: this.props.config.modbus_unit_id ? this.props.config.modbus_unit_id : this.props.config.id,
                value: targetField
            }
        })
    }
    handleSelectAllChange = (isCheck, name) => {
        let config = _.cloneDeep(this.props.config)
        for (let index in config.fields) {
            let field = config.fields[index]
            if (name == "interval") {
                if (isCheck == false) {
                    field[name] = 0
                }
            }
            else {
                field[name] = isCheck
            }
        }
        if (this.props.unit) {
            this.props.ChangeUnitFields(config.fields)
        }
        else
            this.props.ChangeDeviceFields(config.fields)
        // this.props.ChangeField("config", config)

        let state = _.cloneDeep(this.state)
        state.checkAllState[name] = isCheck;
        this.setState({
            checkAllState: state.checkAllState,
            // rows: _.toArray(config.fields)
        })
    };
    handleSellectChange(field, name, isCheck) {
        let config = _.cloneDeep(this.props.config)
        for (let index in config.fields) {
            let element = config.fields[index]
            if (element.id == field.id) {
                let value = isCheck
                if (this.props.unit) {
                    this.props.ChangeUnitField(field.id, name, value)
                }
                else {
                    this.props.ChangeDeviceField(field.id, name, value)
                }
                return;
            }
        }

    }

    handleValueChanged(eValue, field, name) {
        let config = _.cloneDeep(this.props.config)
        for (let index in config.fields) {
            let element = config.fields[index]
            if (element.id == field.id) {
                let value = null
                if (name == "interval") {
                    value = tableIntervalLookUp[eValue]
                }
                else if (name == "limit_save_to_cotroller" || name == "limit_save_to_portal") {
                    value = tableDatabaseLimitTimeLookUp[eValue]
                }
                if (this.props.unit) {
                    this.props.ChangeUnitField(field.id, name, value)
                }
                else {
                    this.props.ChangeDeviceField(field.id, name, value)
                }
                return;
            }
        }
    }
    getLookUpValue(value, name) {
        if (name == "interval") {
            for (let index in tableIntervalLookUp) {
                if (tableIntervalLookUp[index] == value)
                    return index;
            }
        }
        else if (name == "save_to_cotroller" || name == "save_to_portal") {
            for (let index in tableDatabaseLimitTimeLookUp) {
                if (tableDatabaseLimitTimeLookUp[index] == value)
                    return index;
            }
        }
        return "Unknown"
    }


    handleChangePage = (event, newPage) => {
        this.state.page = newPage;
        this.setState({ page: this.state.page });
    };

    handleChangeRowsPerPage = (event) => {
        this.state.rowsPerPage = +event.target.value;
        this.setState({ rowsPerPage: this.state.rowsPerPage });
        this.state.page = 0;
        this.setState({ page: this.state.page });
    };

    render() {
        var SearchList = []
        for (let field of _.toArray(this.props.config.fields)) {
            if (field.id != "*")
                SearchList.push(
                    (field.register ? field.register : field.id ? field.id : "") + " - " + field.title
                )
        }
        let rows = []
        let isCluster = this.props.config.modbus_unit_id ? true : false
        let deviceId = this.props.config.modbus_unit_id ? this.props.config.modbus_unit_id : this.props.config.id
        if (deviceId == this.state.filterRows.id && isCluster == this.state.filterRows.isCluster && this.state.filterRows.value) {
            for (let index in this.props.config.fields) {
                let field = this.props.config.fields[index]
                // for (let entry of this.state.filterRows.value) {
                if (this.state.filterRows.value.id == field.id) {
                    rows.push(field)
                }
                // }
            }
        }
        else {
            if (this.state.showAllFields)
                rows = _.toArray(this.props.config.fields)
            else {
                for (let index in this.props.config.fields) {
                    {
                        let field = this.props.config.fields[index]
                        // for (let entry of this.state.filterRows.value) {
                        if (field.interval != 0) {
                            rows.push(field)
                        }
                    }
                }
            }

        }
        let sortedTable = rows;
        sortedTable = this.stableSort(rows, getComparator(this.state.order, this.state.orderBy))
        return (
            <Card>
                <CardHeader
                    title={
                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '0px'
                        }}
                        >
                            <div >
                                <Tooltip title="Search registers " arrow>
                                    <Autocomplete
                                        value={this.state.searchValue}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                this.setState({ searchValue: newValue });
                                                this.updateTable(newValue)
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input                                        
                                                this.setState({ searchValue: newValue.inputValue });
                                                this.updateTable(newValue.inputValue)
                                            } else {
                                                this.setState({ searchValue: newValue });
                                                this.updateTable(newValue)
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        autoHighlight
                                        id="free-solo-with-text-demo"
                                        options={SearchList}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option;

                                        }}
                                        renderOption={
                                            (option) => option
                                        }
                                        style={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField label="Search Register" variant="outlined"
                                                {...params}
                                            />
                                        )}
                                    />
                                </Tooltip>
                            </div>
                            <div
                                style={{
                                    padding: '5px', 'margin-left': '5%'
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.showAllFields} onChange={(e) => this.setState({ showAllFields: e.target.checked })} name="gilad" />}
                                    label="Show all fields"
                                />
                            </div>

                        </div>
                    }
                />
                < TableContainer >
                    <Table size="small" stickyHeader aria-label="sticky table" >
                        {this.EnhancedTableHead(this.state.order, this.state.orderBy, this.handleRequestSort)}
                        <TableBody>
                            {sortedTable.slice(this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(
                                    (field, index) => {
                                        // const isItemSelected = isSelected(row.name);
                                        // const labelId = index;
                                        if (field.id == "*")
                                            return
                                        return (
                                            <TableRow
                                                hover
                                            >
                                                <TableCell
                                                    component="th"
                                                // id={labelId}
                                                >
                                                    {(field.register ? field.register : field.id ? field.id : "") + " - " + field.title}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    // id={labelId}
                                                    align="center">

                                                    <FormControl>
                                                        <NativeSelect
                                                            value={this.getLookUpValue(field.interval, "interval")}
                                                            input={<BootstrapInput />}
                                                            onChange={(event) => this.handleValueChanged(event.target.value, field, "interval")}
                                                        >
                                                            {
                                                                Object.keys(tableIntervalLookUp).map((entry) =>
                                                                    <option value={entry}>{entry}</option>
                                                                )
                                                            }

                                                        </NativeSelect>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        disabled={field.interval == 0 ? true : false}
                                                        control={<GreenCheckbox
                                                            checked={typeof (field.stream_live) === 'undefined' ? false : field.stream_live}
                                                            onChange={(event) => this.handleSellectChange(field, "stream_live", event.target.checked)
                                                            }
                                                        />}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    // id={labelId}
                                                    align="center">
                                                    <div
                                                        style={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0px'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            disabled={field.interval == 0 ? true : false}
                                                            control={<BlueCheckbox
                                                                checked={typeof (field.save_to_cotroller) === 'undefined' ? false : field.save_to_cotroller}
                                                                disabled={field.interval == 0 ? true : false}
                                                                onChange={(event) => this.handleSellectChange(field, "save_to_cotroller", event.target.checked)}
                                                            />}
                                                        />
                                                        <FormControl
                                                            disabled={field.interval == 0 ? true : false || !field.save_to_cotroller}
                                                        >
                                                            <NativeSelect

                                                                value={this.getLookUpValue(field.limit_save_to_cotroller, "save_to_cotroller")}
                                                                input={<BootstrapInput />}
                                                                onChange={(event) => this.handleValueChanged(event.target.value, field, "limit_save_to_cotroller")}
                                                            >
                                                                {Object.keys(tableDatabaseLimitTimeLookUp).map((entry) =>
                                                                    <option value={entry}>{entry}</option>
                                                                )
                                                                }

                                                            </NativeSelect>
                                                        </FormControl>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    // id={labelId}
                                                    align="center">
                                                    <div
                                                        style={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0px'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            disabled={field.interval == 0 ? true : false}
                                                            control={<RedCheckbox
                                                                checked={typeof (field.save_to_portal) === 'undefined' ? false : field.save_to_portal}
                                                                disabled={field.interval == 0 ? true : false}
                                                                onChange={(event) => this.handleSellectChange(field, "save_to_portal", event.target.checked)}
                                                            />}
                                                        />
                                                        <FormControl
                                                            disabled={field.interval == 0 ? true : false || !field.save_to_portal}
                                                        >
                                                            <NativeSelect

                                                                value={this.getLookUpValue(field.limit_save_to_portal, "save_to_portal")}
                                                                input={<BootstrapInput />}
                                                                onChange={(event) => this.handleValueChanged(event.target.value, field, "limit_save_to_portal")}
                                                            >
                                                                {Object.keys(tableDatabaseLimitTimeLookUp).map((entry) =>
                                                                    <option value={entry}>{entry}</option>
                                                                )
                                                                }

                                                            </NativeSelect>
                                                        </FormControl>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer >
                <TablePagination
                    rowsPerPageOptions={[10, 15, 50]}
                    component="div"
                    count={sortedTable.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Card >
        )
    }



    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

}

