import React,{Component} from "react";
import { TextField, Tabs, Tab, Grid, FormControlLabel,Checkbox,Tooltip,IconButton } from "@material-ui/core";
import MaskedInput from 'react-text-mask';
import {
    Clear as ClearIcon,
    Add as AddIcon
} from "@material-ui/icons";
import moment from "moment-timezone/index";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[ /\d/,/\d/,':',/\d/, /\d/, ':', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

export default class Adam6000Settings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.config || typeof(this.props.config) === 'undefined' ) { return null; }
        return (
            <div>
                {this.renderFieldsList()}
                {this.props.selectedField && this.props.selectedRelayObject ? this.renderRelaySettings() : null}
            </div>
        )
    }

    renderFieldsList() {
        const fieldsList = [];
        for (let field_id in this.props.config.fields) {
            const field = this.props.config.fields[field_id];
            if (!field.title || !field.title.length) { return; }
            fieldsList.push(<option value={field_id}>{field.title} ({field_id})</option>);
        }
        return (
            <TextField
                fullWidth
                select
                label="Select relay to setup"
                size="small"
                value={this.props.selectedField}
                style={{margin:8}}
                onChange={(e)=>this.props.ChangeField("selectedField",e.target.value)}
                SelectProps={{
                    native: true
                }}
                variant="outlined"
            >
                {fieldsList}
            </TextField>
        )
    }

    renderRelaySettings() {
        return (
            <div>
                <Tabs value={this.props.selectedSubTab} onChange={(event,value) => this.props.ChangeField('selectedSubTab',value)}>
                    <Tab label="General"/>
                    <Tab label="Trigger Conditions"/>
                </Tabs>
                <div style={{display: this.props.selectedSubTab == 0 ? '' : 'none'}}>
                    {this.renderRelayGeneralSettings()}
                </div>
                <div style={{display: this.props.selectedSubTab == 1 ? '' : 'none'}}>
                    {this.renderRelayTriggerConditions()}
                </div>
            </div>
        )
    }

    renderRelayGeneralSettings() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        variant="outlined"
                        value={this.props.selectedRelayObject.title}
                        style={{margin:8}}
                        error={this.props.errors["title"] && this.props.errors["title"].length}
                        onChange={(e)=>this.props.ChangeRelayField('title',e.target.value)}
                    />
                    {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Number"
                        variant="outlined"
                        value={this.props.selectedRelayObject.number}
                        style={{margin:8}}
                        error={this.props.errors["number"] && this.props.errors["number"].length}
                        onChange={(e)=>this.props.ChangeRelayField('number',e.target.value)}
                    />
                    {this.props.errors["number"] && <div className="error">{this.props.errors["number"]}</div>}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Location"
                        variant="outlined"
                        value={this.props.selectedRelayObject.location}
                        style={{margin:8}}
                        error={this.props.errors["location"] && this.props.errors["location"].length}
                        onChange={(e)=>this.props.ChangeRelayField('location',e.target.value)}
                    />
                    {this.props.errors["location"] && <div className="error">{this.props.errors["location"]}</div>}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Description"
                        mutliline={true}
                        variant="outlined"
                        value={this.props.selectedRelayObject.description}
                        style={{margin:8}}
                        error={this.props.errors["description"] && this.props.errors["description"].length}
                        onChange={(e)=>this.props.ChangeRelayField('description',e.target.value)}
                    />
                    {this.props.errors["description"] && <div className="error">{this.props.errors["description"]}</div>}
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedRelayObject.send_live_data} onChange={(e)=>this.props.ChangeRelayField("send_live_data",e.target.checked)}/>
                        }
                        label="Send Live Data"
                    /><br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedRelayObject.save_to_db} onChange={(e)=>this.props.ChangeRelayField("save_to_db",e.target.checked)}/>
                        }
                        label="Save Data to DB"
                    /><br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedRelayObject.send_to_portal} onChange={(e)=>this.props.ChangeRelayField("send_to_portal",e.target.checked)}/>
                        }
                        label="Send to Portal"
                    />
                </Grid> */}
            </Grid>
        )
    }

    renderRelayTriggerConditions() {
        const condition = this.props.selectedRelayObject.conditions[this.props.selectedCondition];
        return (
            <div>
                {this.renderConditionsList()}
                {condition &&
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Title"
                                variant="outlined"
                                value={condition.title}
                                style={{margin: 8}}
                                error={this.props.errors["title"] && this.props.errors["title"].length}
                                onChange={(e) => this.props.ChangeRelayConditionField('title', e.target.value)}
                            />
                            {this.props.errors["conditionTitle"] && <div className="error">{this.props.errors["conditionTitle"]}</div>}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={condition.schedule_enabled} onChange={(e)=>this.props.ChangeRelayConditionField("schedule_enabled",e.target.checked)}/>
                                }
                                label="Enable Schedule"
                            />
                        </Grid>
                        {condition.schedule_enabled && this.renderRelayConditionSchedule()}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={condition.sunSetSunRise_enable} onChange={(e)=>{
                                        this.props.ChangeRelayConditionField("sunSetSunRise_enable",e.target.checked)
                                        this.props.ChangeRelayConditionField("condition_enabled",false)
                                    }
                                }/>
                                }
                                label="Using Sunrise/Sunset condition"
                            />
                        </Grid>
                        {
                            !condition.sunSetSunRise_enable && 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={condition.condition_enabled} onChange={(e)=>this.props.ChangeRelayConditionField("condition_enabled",e.target.checked)}/>
                                    }
                                    label="Enable Condition"
                                />
                            </Grid>
                        }
                        {(!condition.sunSetSunRise_enable && condition.condition_enabled) && this.renderRelayConditionCondition()}
                    </Grid>
                }
            </div>
        )
    }

    renderConditionsList() {
        return (
            <Grid container spacing={3} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        select
                        label="Select condition to setup"
                        size="small"
                        value={this.props.selectedCondition}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeField("selectedCondition",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        {this.props.selectedRelayObject.conditions.map((condition,index) => <option value={index}>{condition.title}</option>)}
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Add relay condition" arrow>
                        <IconButton onClick={()=>this.props.AddCondition()}>
                            <AddIcon fontSize="large"  style={{cursor:'pointer',color:'green'}}/>
                        </IconButton>
                    </Tooltip>&nbsp;
                    <Tooltip title="Delete relay condition" arrow>
                        <IconButton onClick={()=>this.props.DeleteCondition()}>
                            <ClearIcon fontSize="large" style={{cursor:'pointer',color:'red'}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }


    renderRelayConditionSchedule() {
        const condition = this.props.selectedRelayObject.conditions[this.props.selectedCondition];
        return (
            <Grid container spacing={3}>
                <Grid item xs={1} style={{paddingLeft:'10px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <strong>Days</strong>
                </Grid>
                <Grid item xs={11} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    {['Sun','Mon','Tue','Wed','Thu','Fri','Sat'].map((day,index) =>
                        <FormControlLabel key={day+"_"+index}
                            control={
                                <Checkbox checked={condition.days[index]}
                                      onChange={(e)=>this.props.ChangeRelayConditionScheduleDay(index,e.target.checked)}
                                />
                            }
                            label={day}
                        />
                    )}
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        variant="outlined"
                        label="Start time"
                        fullWidth
                        error={this.props.errors["start_time"] && this.props.errors["start_time"].length}
                        style={{margin:8}}
                        size="small"
                        ampm={false}
                        InputProps={{inputComponent:TextMaskCustom}}
                        value={this.getTimePeriodText(condition.start_time)}
                        onChange={(e)=>this.props.ChangeRelayConditionField('start_time',e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        variant="outlined"
                        label="Duration"
                        fullWidth
                        error={this.props.errors["duration"] && this.props.errors["duration"].length}
                        style={{margin:8}}
                        size="small"
                        InputProps={{inputComponent:TextMaskCustom}}
                        value={this.getTimePeriodText(condition.duration)}
                        onChange={(e)=>this.props.ChangeRelayConditionField('duration',e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    getTimePeriodText(value) {
        if (typeof(value) === "number" || value == parseInt(value)) {
            let hours = Math.floor(value / 3600);
            value = value - hours;
            let minutes = Math.floor(value / 60);
            let seconds = value - minutes;
            return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
        } else {
            return value;
        }
    }

    renderRelayConditionCondition() {

        const condition = this.props.selectedRelayObject.conditions[this.props.selectedCondition];
        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        select
                        label="Device"
                        size="small"
                        value={condition.device_id || ""}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeRelayConditionField("device_id",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value={0}></option>
                        {this.props.inventory.map(device =>
                            <option value={device.id} key={"condition_device_"+condition.title+"_"+device.id}>
                                {device.title}
                            </option>
                        )}
                    </TextField>
                </Grid>
                {condition.device_id && this.props.inventory[this.props.inventory.findIndex(item=>item.id===condition.device_id)].units &&
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        select
                        label="Unit"
                        size="small"
                        value={condition.unit_id || ""}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeRelayConditionField("unit_id",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value={0}></option>
                        {this.props.inventory[this.props.inventory.findIndex(item=>item.id===condition.device_id)].units.map(unit =>
                            <option value={unit.id} key={"condition_unit_"+condition.title+"_"+condition.device_id+"_"+unit.id}>
                                {unit.title}
                            </option>
                        )}
                    </TextField>
                </Grid>
                }
                {((condition) => {
                    if (!condition.device_id) { return null }
                    const device = this.props.inventory[this.props.inventory.findIndex(item=>item.id===condition.device_id)];
                    if (device.units && !condition.unit_id) { return null };
                    let fields = {};
                    let status = {};
                    if (device.units) {
                        fields = device.units[device.units.findIndex(item => item.id === condition.unit_id)].fields;
                        status = this.props.status[condition.unit_id];
                    } else {
                        fields = device.fields;
                        status = this.props.status[device.id];
                    }
                    const options = [];
                    for (let field_id in fields) {
                        options.push(<option value={field_id} key={"condition_field_"+"_"+condition.title+"_"+condition.device_id+"_"+condition.unit_id+"_"+field_id}>
                            {fields[field_id].title}
                        </option>)
                    }
                    return (
                        <Grid item xs={3} style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',marginRight:5,alignItems:'center'}}>
                            <TextField
                                fullWidth
                                select
                                label="Field"
                                size="small"
                                value={condition.field_id || ""}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeRelayConditionField("field_id",e.target.value)}
                                SelectProps={{
                                    native: true
                                }}
                                variant="outlined"
                            >
                                <option value={0}></option>
                                {options}
                            </TextField>
                            {status && status[condition.field_id] ? <span style={{whiteSpace:'nowrap',fontWeight:'bold'}}>
                                ({status[condition.field_id]} {fields[condition.field_id].unit})
                            </span> : ""}
                        </Grid>
                    )
                })(condition)}
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="If value less than"
                        variant="outlined"
                        value={condition.min_value}
                        style={{margin:8}}
                        type="number"
                        error={this.props.errors["min_value"] && this.props.errors["min_value"].length}
                        onChange={(e)=>this.props.ChangeRelayConditionField('min_value',e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        select
                        label="Turn relay"
                        size="small"
                        value={condition.min_value_action || ""}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeRelayConditionField("min_value_action",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value={1}>ON</option>
                        <option value={0}>OFF</option>
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="If value greater than"
                        variant="outlined"
                        value={condition.max_value}
                        style={{margin:8}}
                        type="number"
                        error={this.props.errors["max_value"] && this.props.errors["max_value"].length}
                        onChange={(e)=>this.props.ChangeRelayConditionField('max_value',e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        select
                        label="Turn relay"
                        size="small"
                        value={condition.max_value_action || ""}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeRelayConditionField("max_value_action",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value={1}>ON</option>
                        <option value={0}>OFF</option>
                    </TextField>
                </Grid>
                {/* <Grid item xs={4}>
                    <TextField
                        variant="outlined"
                        label="Trigger sense time"
                        fullWidth
                        error={this.props.errors["trigger_sense_time"] && this.props.errors["trigger_sense_time"].length}
                        style={{margin:8}}
                        size="small"
                        InputProps={{inputComponent:TextMaskCustom}}
                        value={this.getTimePeriodText(condition.trigger_sense_time)}
                        onChange={(e)=>this.props.ChangeRelayConditionField('trigger_sense_time',e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid> */}
            </Grid>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
