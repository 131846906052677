import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';

import {
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    Snackbar,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

import {
    Save as SaveIcon,
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";

export default class AlertsSettings extends Component {
    constructor(props) {
        super(props);
        this.intervals = [];
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        return (
            <React.Fragment>
                <Helmet title="Alerts Settings"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Alerts Settings</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>&nbsp;&nbsp;
                                    </h1>
                                </div>
                            </Grid>
                        </Grid>
                        <form noValidate autoComplete="off" style={{width:'100%'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.props.enabled} onChange={(e)=>this.props.ChangeField("alerts_settings.enabled",e.target.checked)}/>
                                        }
                                        label="Enable alerts"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={this.props.alert_on_restart} onChange={(e)=>this.props.ChangeField("alerts_settings.alert_on_restart",e.target.checked)}/>
                                        }
                                        label="Alert when system restarts"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Alert emails repeat frequency (ms)"
                                        variant="outlined"
                                        value={this.props.repeat_frequency}
                                        style={{margin:8}}
                                        type="number"
                                        onChange={(e)=>this.props.ChangeField('alerts_settings.repeat_frequency',e.target.value)}
                                    />
                                    {this.props.errors["repeat_frequency"] && <div className="error">{this.props.errors["repeat_frequency"]}</div>}
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }


}
