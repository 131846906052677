import React from "react";
import moment from "moment-timezone";
import _ from 'lodash';
import { Button,Tooltip } from "@material-ui/core";
import {EditOutlined,Check,Cancel} from "@material-ui/icons"
import DeviceStatus from "./DeviceStatus";

export default class Hm305pDeviceStatus extends DeviceStatus {
    constructor(props) {
        super(props);
        this.columns_count = 1;
    }

    render() {
        return (
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-start',width:'100%'}}>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                    {this.renderHeader()}
                    {this.renderTable()}
                    {this.props.IsOnline() ?
                    !this.props.editMode ?
                        <Button variant="contained" color="primary" style={{marginTop: 10}}
                        onClick={() => this.props.ToggleEditMode()}>
                        <EditOutlined/>
                        Edit
                        </Button>
                        :
                        <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <Button variant="contained" color="primary" style={{marginTop: 10, marginRight: 10}}
                        onClick={() => this.props.Save()}>
                        <Check/>
                        Save
                        </Button>
                        <Button variant="contained" color="secondary" style={{marginTop: 10, backgroundColor: 'red'}}
                        onClick={() => this.props.ToggleEditMode()}>
                        <Cancel/>
                        Cancel
                        </Button>

                        </div>
                    : null }
                </div>
                <div style={{position:"relative"}}>
                    <img src={require("../../img/hm305p.png")} useMap="#image-map"/>
                    {this.props.status && this.props.status["PoweredOn"] ?
                        <Tooltip title="Turn OFF" arrow>
                            <img src={require("../../img/hm305p_on.png")} onClick={()=>this.props.TurnOnOff()}
                                 style={{position: 'absolute', left: 112, top: 290,cursor:'pointer'}}/>
                        </Tooltip>
                        :
                        <Tooltip title="Turn ON" arrow>
                            <img src={require("../../img/hm305p_off.png")} onClick={()=>this.props.TurnOnOff()}
                                 style={{position: 'absolute', left: 112, top: 290,cursor:'pointer'}}/>
                        </Tooltip>
                    }
                </div>
            </div>
        )
    }

    renderStatusField(field) {
        if (!this.props.editFields) { return; }
        if (!this.props.editMode || typeof(this.props.editFields[field.id]) === "undefined") {
            return super.renderStatusField(field);
        }
        return (
            <td style={{padding:'5px',borderWidth:'1px',borderColor:'black',borderStyle:'solid',width:'10%'}} key={'status_column_'+field.id}>
                <input type="number" value={this.props.editFields[field.id]} onChange={(e)=>this.props.ChangeEditField(field.id,e.target.value)}/>
            </td>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
