import DeviceStatus from './DeviceStatus';
import Component from '../../pages/inventory/Hm305pDeviceStatus';
import {connect} from 'react-redux';
import _ from "lodash";
import HttpClient from "../../services/HttpClient";

/**
 * Controller for Device Status component
 */
export default class Hm305pDeviceStatus extends DeviceStatus {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;

    static getComponent() {
        if (!Hm305pDeviceStatus.component) {
            const item = new Hm305pDeviceStatus();
            Hm305pDeviceStatus.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Hm305pDeviceStatus.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = Object.assign(super.setupStateProps(state,ownProps));
        props["editMode"] = state.editMode;
        props["editFields"] = state.editFields;
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    Init() {
        this.ChangeField("editMode",false);
        this.ChangeField("errors",{});
        this.editFields = {};
        for (let field_id in this.config.fields) {
            if (this.config.fields[field_id].mode === "rw") {
                this.editFields[field_id] = 0;
            }
        }
        this.ChangeField("editFields",_.cloneDeep(this.editFields));
    }

    ToggleEditMode() {
        for (let field_id in this.editFields) {
            this.editFields[field_id] = this.status[field_id] || 0;
        }
        this.ChangeField("editFields",_.cloneDeep(this.editFields));
        this.ChangeField("editMode",!this.editMode);
    }

    ChangeEditField(name,value) {
        this.editFields[name] = value;
        this.ChangeField("editFields",_.cloneDeep(this.editFields));
    }

    async Save() {
        const fields = [];
        for (let field_id in this.editFields) {
            fields.push({
                id:field_id,
                value: this.editFields[field_id]
            })
        }
        this.ChangeField("isLoading",true);
        await HttpClient.request("/relayboard/send_command", 'POST', {device_id:this.config.id,fields:fields,type:"set_fields"});
        this.ChangeField("isLoading",false);
        this.ChangeField("editMode",false);
    }

    async TurnOnOff() {
        if (!this.status) { return; }
        await HttpClient.request("/relayboard/send_command", 'POST', {
            device_id:this.config.id,fields:[{id:"PoweredOn",value:this.status["PoweredOn"] ? 0 : 1}],type:"set_fields"
        });
    }
}
