import Base from './../../Base';
import Component from '../../../pages/inventory/settings/Adam6000SettingsAlerts';
import {connect} from 'react-redux';
import Store from '../../../store/Store';
import moment from 'moment-timezone';

/**
 * Controller for Adam6000 Settings component
 */
export default class Adam6000SettingsAlerts extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Adam6000SettingsAlerts.component) {
            const item = new Adam6000SettingsAlerts();
            Adam6000SettingsAlerts.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Adam6000SettingsAlerts.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null,
            inventory: state.config && state.config.inventory.lan,
            errors: state.errors || {}
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);

            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                props['config'] = config;
                props['alerts'] = config.alerts || {};
            }

        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    ChangeDeviceField(name,value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex((item) => item.id === this.deviceId);
        if (deviceIndex == -1) { return }
        let device = state.config.inventory.lan[deviceIndex];
        if (!device['alerts']) { device['alerts'] = {}; }
        eval("device"+Store.getPropertyNameExpression(name)+ " = _.cloneDeep(value);");
        state.config.inventory.lan[deviceIndex] = device;
        Store.changeProperty('config', state.config);
    }

}
