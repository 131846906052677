import React,{Component} from "react";
import {
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { DeleteForever,Add } from "@material-ui/icons";
import _ from 'lodash';

export default class SMASpeedwireSettingsFields extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                {this.renderFieldsList()}
                {this.props.selectedField && this.renderFieldForm()}
            </div>
        )
    }

    renderFieldsList() {
        const fields = [];
        for (let field_id in this.props.config) {
            if (field_id === "*") { continue; }
            const field = this.props.config[field_id];
            if (field_id == 'new') { continue }
            fields.push(field.title+" "+field_id);
        }
        fields.sort((s1,s2) => s1.toString().toLowerCase()<s2.toString().toLowerCase() ? -1 : 1);
        const options = fields.map(field => {
            let parts = field.split(" ");
            const id = parts.pop();
            const title = parts.join(" ");
            return <option value={id}>{title} ({id})</option>
        });
        options.unshift(<option value={"new"}>---Add New Field---</option>);
        return (
            <TextField
                select
                label="Field"
                size="small"
                fullWidth
                value={this.props.selectedField}
                style={{margin:8}}
                onChange={(e)=>{
                    if (!this.props.Validate()) {
                        return;
                    }
                    if (e.target.value == "new") { this.props.AddNewField();}
                    else if (this.props.selectedField != this.props.selectedFieldObject.id) {
                        if (this.props.config[this.props.selectedFieldObject.id]) {
                            window.alert("Field with specified ID already exists. Please change ID and then leave");
                            return;
                        }
                        const fields = _.cloneDeep(this.props.config);
                        fields[this.props.selectedFieldObject.id] = _.cloneDeep(this.props.selectedFieldObject);
                        delete(fields[this.props.selectedField]);
                        this.props.ChangeDeviceFields(fields);
                    }
                    this.props.ChangeField("selectedField", e.target.value)
                }}
                SelectProps={{
                    native: true
                }}
                variant="outlined"
            >
                <option value="*">Default</option>
                {options}
            </TextField>
        )
    }

    renderFieldForm() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["id"] && this.props.errors["id"].length}
                        fullWidth
                        size="small"
                        label="Id"
                        variant="outlined"
                        value={this.props.selectedFieldObject.id}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('id',e.target.value)}
                    />
                    {this.props.errors["id"] && <div className="error">{this.props.errors["id"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        variant="outlined"
                        value={this.props.selectedFieldObject.title}
                        style={{margin:8}}
                        error={this.props.errors["title"] && this.props.errors["title"].length}
                        onChange={(e)=>this.props.ChangeDeviceField('title',e.target.value)}
                    />
                    {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["mode"] && this.props.errors["mode"].length}
                        fullWidth
                        select
                        label="Mode"
                        size="small"
                        value={this.props.selectedFieldObject.mode}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("mode",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="R">Read Only</option>
                        <option value="R/W">Read/Write</option>
                        <option value="W">Write Only</option>
                    </TextField>
                    {this.props.errors["mode"] && <div className="error">{this.props.errors["mode"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : "flex",flexDirection:'row'}}>
                    <TextField
                        error={this.props.errors["register"] && this.props.errors["register"].length}
                        fullWidth
                        size="small"
                        label="Register #"
                        variant="outlined"
                        value={this.props.selectedFieldObject.register}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('register',e.target.value)}
                    />
                    {this.props.errors["register"] && <div className="error">{this.props.errors["register"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["type"] && this.props.errors["type"].length}
                        fullWidth
                        size="small"
                        label="Type"
                        variant="outlined"
                        value={this.props.selectedFieldObject.type}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('type',e.target.value)}
                    />
                    {this.props.errors["type"] && <div className="error">{this.props.errors["type"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["offset"] && this.props.errors["offset"].length}
                        fullWidth
                        type="number"
                        size="small"
                        label="Offset (bytes)"
                        variant="outlined"
                        value={this.props.selectedFieldObject.offset}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('offset',e.target.value)}
                    />
                    {this.props.errors["offset"] && <div className="error">{this.props.errors["offset"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["size"] && this.props.errors["size"].length}
                        fullWidth
                        type="number"
                        size="small"
                        label="Length (bytes)"
                        variant="outlined"
                        value={this.props.selectedFieldObject.size}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('size',e.target.value)}
                    />
                    {this.props.errors["size"] && <div className="error">{this.props.errors["size"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["unit"] && this.props.errors["unit"].length}
                        fullWidth
                        size="small"
                        label="Unit"
                        variant="outlined"
                        value={this.props.selectedFieldObject.unit}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('unit',e.target.value)}
                    />
                    {this.props.errors["unit"] && <div className="error">{this.props.errors["unit"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["divider"] && this.props.errors["divider"].length}
                        fullWidth
                        size="small"
                        label="Divided By"
                        variant="outlined"
                        value={this.props.selectedFieldObject.divider}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('divider',e.target.value)}
                    />
                    {this.props.errors["divider"] && <div className="error">{this.props.errors["divider"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["formula"] && this.props.errors["formula"].length}
                        fullWidth
                        size="small"
                        label="Formula ('v' - input data to convert)"
                        variant="outlined"
                        value={this.props.selectedFieldObject.formula}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('formula',e.target.value)}
                    />
                    {this.props.errors["formula"] && <div className="error">{this.props.errors["formula"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["min"] && this.props.errors["min"].length}
                        fullWidth
                        size="small"
                        label="Min value"
                        variant="outlined"
                        value={this.props.selectedFieldObject.min}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('min',e.target.value)}
                    />
                    {this.props.errors["min"] && <div className="error">{this.props.errors["min"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["max"] && this.props.errors["max"].length}
                        fullWidth
                        size="small"
                        label="Max value"
                        variant="outlined"
                        value={this.props.selectedFieldObject.max}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('max',e.target.value)}
                    />
                    {this.props.errors["max"] && <div className="error">{this.props.errors["max"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["format"] && this.props.errors["format"].length}
                        fullWidth
                        size="small"
                        label="Format"
                        variant="outlined"
                        value={this.props.selectedFieldObject.format}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('format',e.target.value)}
                    />
                    {this.props.errors["format"] && <div className="error">{this.props.errors["format"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["enum"] && this.props.errors["enum"].length}
                        fullWidth
                        size="small"
                        label="Enum"
                        variant="outlined"
                        value={this.props.selectedFieldObject.enum}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('enum',e.target.value)}
                    />
                    {this.props.errors["enum"] && <div className="error">{this.props.errors["enum"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["send_live_data"] && this.props.errors["send_live_data"].length}
                        fullWidth
                        select
                        label="Read data"
                        size="small"
                        value={this.props.selectedFieldObject.send_live_data}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("send_live_data",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["send_live_data"] && <div className="error">{this.props.errors["send_live_data"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["save_to_db"] && this.props.errors["save_to_db"].length}
                        fullWidth
                        select
                        label="Save to database"
                        size="small"
                        value={this.props.selectedFieldObject.save_to_db}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("save_to_db",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["save_to_db"] && <div className="error">{this.props.errors["save_to_db"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["send_to_portal"] && this.props.errors["send_to_portal"].length}
                        fullWidth
                        select
                        label="Send to portal"
                        size="small"
                        value={this.props.selectedFieldObject.send_to_portal}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("send_to_portal",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["send_to_portal"] && <div className="error">{this.props.errors["send_to_portal"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedFieldObject.can_be_signed} onChange={(e)=>this.props.ChangeDeviceField("can_be_signed",e.target.checked)}/>
                        }
                        label="Signed"
                    />
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedFieldObject.is_virtual} onChange={(e)=>this.props.ChangeDeviceField("is_virtual",e.target.checked)}/>
                        }
                        label="Is Virtual"
                    />
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" || !this.props.selectedFieldObject.is_virtual ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["default_value"] && this.props.errors["default_value"].length}
                        fullWidth
                        type="number"
                        size="small"
                        label="Default value"
                        variant="outlined"
                        value={this.props.selectedFieldObject.default_value}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('default_value',e.target.value)}
                    />
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.selectedFieldObject.is_timer} onChange={(e)=>this.props.ChangeDeviceField("is_timer",e.target.checked)}/>
                        }
                        label="Is Timer"
                    />
                </Grid>
                <Grid item xs={12} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["description"] && this.props.errors["description"].length}
                        fullWidth
                        multiline
                        size="small"
                        label="Description"
                        variant="outlined"
                        value={this.props.selectedFieldObject.description}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('description',e.target.value)}
                    />
                    {this.props.errors["description"] && <div className="error">{this.props.errors["description"]}</div>}
                </Grid>
                <Grid item xs={12} style={{display:this.props.selectedField === "*" ? "none" : "flex",flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginBottom:10,width:'100%'}}>
                        <strong style={{marginRight:5,whiteSpace:'nowrap'}}>Descriptions of values</strong>
                        <Button variant="contained" color="primary" onClick={() => this.props.AddValueDescription()} style={{whiteSpace:'nowrap'}}>
                            <Add/>
                            Add description
                        </Button>
                    </div>
                    <Table size="small" style={{width:'100%'}}>
                        <TableBody>
                        <TableRow>
                            <TableCell>Value</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {this.props.selectedFieldObject.value_descriptions.map((value_description,index) => (
                            <tr key={"value_description_"+value_description.value}>
                                <TableCell>
                                    <TextField fullWidth={true} size="small" type="number" value={value_description.value} onChange={(e)=>this.props.ChangeValueDescriptionField(index,"value",e.target.value)}/>
                                </TableCell>
                                <TableCell>
                                    <TextField fullWidth={true} size="small"  value={value_description.description} onChange={(e)=>this.props.ChangeValueDescriptionField(index,"description",e.target.value)}/>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={()=>this.props.DeleteValueDescription(index)}>
                                        <DeleteForever/>
                                    </IconButton>
                                </TableCell>
                            </tr>
                        ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    {this.props.selectedField !== "*" && this.props.selectedField !== "new" ?
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            <Button variant="contained" color="primary" onClick={() => this.props.Delete()}>
                                <DeleteForever/>
                                Delete field
                            </Button>
                        </div>
                        :
                        <span>&nbsp;</span>
                    }
                </Grid>
            </Grid>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
