import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';
import {
    Paper,
    Grid,
    Button,
    Snackbar,
    TextField,
    Tooltip,
    IconButton
} from "@material-ui/core";

import {
    Save as SaveIcon,
    Add,
    PlayArrow,
    DeleteForever
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";
import ReactFlow,{ MiniMap,Controls,Background } from 'react-flow-renderer';

export default class Flows extends Component {
    constructor(props) {
        super(props);
        this.reactFlowWrapper = React.createRef()
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        if (!this.props.config) { return null; }
        return (
            <React.Fragment>
                <Helmet title="Flows"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }

                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Flows</h1>
                            </Grid>
                            <Grid item xs={12} style={{padding:'10px'}}>
                                <TextField
                                    value={this.props.selectedFlow}
                                    fullWidth
                                    small
                                    label="Flow"
                                    select variant="outlined"
                                    SelectProps={{native: true}}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e)=>{
                                        this.props.history.push("/flows/"+e.target.value);
                                    }}
                                >
                                    <option value="0">--- Select flow ---</option>
                                    {
                                        this.props.config.map(flow =>
                                            <option key={"flow_"+flow.id} value={flow.id}>{flow.title}</option>
                                        )
                                    }
                                    <option value="new">Add new flow &gt;</option>
                                </TextField>
                            </Grid>
                        </Grid>
                        {this.renderFlow()}
                    </Paper>
            </React.Fragment>
        )
    }

    renderFlow() {
        if (!this.props.selectedFlowObject) {return null;}
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        error={this.props.errors["title"] && this.props.errors["title"].length}
                        fullWidth
                        size="small"
                        label="Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.props.selectedFlowObject.title}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeFlowField('title',e.target.value)}
                    />
                    {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        error={this.props.errors["run_frequency"] && this.props.errors["run_frequency"].length}
                        fullWidth
                        size="small"
                        type={"number"}
                        label="Run frequency (s)"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.props.selectedFlowObject.run_frequency || 0}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeFlowField('run_frequency',e.target.value)}
                    />
                    {this.props.errors["run_frequency"] && <div className="error">{this.props.errors["run_frequency"]}</div>}
                </Grid>
                <Grid item xs={4} style={{textAlign:'right',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                    <Button mr={2} variant="contained" color="primary" className="pull-right" style={{marginLeft:5,backgroundColor:'green',whiteSpace:'nowrap'}}
                            onClick={()=>this.props.Run()}>
                        <PlayArrow />
                        Run now
                    </Button>
                    <Button mr={2} variant="contained" color="primary" className="pull-right" style={{marginLeft:5}}
                            onClick={()=>this.props.Save(this.reactFlowInstance.getElements())}>
                        <SaveIcon />
                        Save
                    </Button>
                    {this.props.selectedFlowObject && this.props.selectedFlowObject.id &&
                    <Button mr={2} variant="contained" color="primary" className="pull-right" style={{marginLeft:5,backgroundColor:'red'}}
                            onClick={()=>this.props.Delete()}>
                        <DeleteForever />
                        Delete
                    </Button>}
                </Grid>
                <div style={{height:500,width:"100%",display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'flex-start'}}>
                    {this.renderBlocksLibrary()}
                    {this.renderDiagram()}
                </div>
                <Grid item xs={12}>
                    {this.props.selectedBlock && this.renderBlockProperties()}
                </Grid>
            </Grid>
        )
    }

    renderBlocksLibrary() {
        const onDragStart = (event, nodeType) => {
            event.dataTransfer.setData('application/reactflow', nodeType);
            event.dataTransfer.effectAllowed = 'move';
        };
        return (
            <div style={{width:'100',height:'100%',display:'flex',flexDirection:'column',borderRightWidth:1,borderRightColor:'black',borderRightStyle:'solid'}}>
                <div style={{width:90,margin:10,backgroundColor:"#DAE8FC",borderColor:'black',borderWidth:1,borderStyle:'solid',display:'flex',justifyContent:'center',alignItems:'center'}}
                     onDragStart={(event)=>onDragStart(event,'condition')} draggable>
                    Condition
                </div>
                <div style={{width:90,margin:10,backgroundColor:"#D5E8D4",borderColor:'black',borderWidth:1,borderStyle:'solid',display:'flex',justifyContent:'center',alignItems:'center'}}
                     onDragStart={(event)=>onDragStart(event,'action')} draggable>
                    Action
                </div>
            </div>
        )
    }

    renderDiagram() {
        const onDragOver = (event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
        };
        const onDrop = (event) => {
            event.preventDefault();
            const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const position = this.reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            this.props.AddBlock({position:position,type:type})
        };
        return (
            <div style={{height:'100%',width:'100%'}} ref={this.reactFlowWrapper}>
                <ReactFlow elements={this.getDiagramElementsFromBlocks()}
                   onConnect={({source,target}) => this.props.ConnectBlocks(source,target)}
                   onDragOver={onDragOver}
                   onDrop={onDrop}
                   onNodeDragStop={(event,node) => this.props.ChangeBlockField('position',node.position)}
                   onLoad={(instance) => this.reactFlowInstance = instance}
                   onSelectionChange = {(elements) => this.props.SelectBlock(elements)}
                   onElementsRemove = {(elements) => this.props.DeleteElements(elements)}
                   deleteKeyCode={46}
                >
                    <Controls/>
                    <MiniMap/>
                    <Background/>
                </ReactFlow>
            </div>
        )
    }

    renderBlockProperties() {
        return (
            <div style={{display:'flex',flexDirection:'column',height:'100%',alignItems:'flex-start',justifyContent:'flex-start',backgroundColor:"#ffffff",padding:10,width:'100%',marginBottom:10}}>
                <label className="control-label" style={{fontWeight:'bold'}}>Properties</label>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginTop:10,width:'100%'}}>
                    <TextField
                        error={this.props.errors["blockTitle"] && this.props.errors["blockTitle"].length}
                        size="small"
                        label="Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={this.props.selectedBlock.title}
                        onChange={(e)=>{this.props.ChangeBlockField('title',e.target.value)}}
                        style={{width:'90%'}}
                    />
                    <Button mr={2} variant="contained" color="primary" style={{marginLeft:5,backgroundColor:'red',whiteSpace:'nowrap'}}
                            onClick={()=>this.props.DeleteBlock(this.props.selectedBlock.id)}>
                        <DeleteForever />
                        Delete block
                    </Button>
                </div>
                {this.props.selectedBlock.type == 'action' ? this.renderActionBlockProperties() : this.renderConditionBlockProperties()}
            </div>
        )
    }

    renderActionBlockProperties() {
        const action = this.props.selectedBlock.action;
        let field_options = [];
        if (this.props.selectedBlock.action && this.props.selectedBlock.action.device_id) {
            field_options = this.getFieldOptions(this.props.selectedBlock.action.device_id);
        }
        let value_field_options = [];
        if (this.props.selectedBlock.action && this.props.selectedBlock.action.value_device_id) {
            value_field_options = this.getFieldOptions(this.props.selectedBlock.action.value_device_id);
        }
        let current_value_field = null;
        let current_field = null;
        let current_value = null;
        let current_value_value = null;
        if (this.props.status && this.props.status[action.device_id] && this.props.status[action.device_id][action.field_id]) {
            if (action.device_id && action.field_id) {
                current_value = this.props.status[action.device_id][action.field_id];
                let device_index = this.props.inventory.findIndex(item => item.id == action.device_id)
                if (device_index !== -1) {
                    current_field = this.props.inventory[device_index].fields[action.field_id];
                    if (current_field) {
                        current_value = this.getFieldValueDescription(current_field, current_value);
                    }
                }
            }
        }
        if (this.props.status && this.props.status[action.value_device_id] && this.props.status[action.value_device_id][action.value_field_id]) {
            if (action.value_device_id && action.value_field_id) {
                current_value_value = this.props.status[action.value_device_id][action.value_field_id];
                let device_index = this.props.inventory.findIndex(item => item.id == action.value_device_id)
                if (device_index !== -1) {
                    current_value_field = this.props.inventory[device_index].fields[action.value_field_id];
                    if (current_value_field) {
                        current_value_value = this.getFieldValueDescription(current_value_field, current_value_value);
                    }
                }
            }
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginTop:10
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop:10,
                    marginBottom:10
                }}>
                    <label style={{whiteSpae:'nowrap'}}>Set value of:</label>
                    <TextField
                        error={this.props.errors["blockDeviceId"] && this.props.errors["blockDeviceId"].length}
                        value={this.props.selectedBlock.action ? this.props.selectedBlock.action.device_id : "0"}
                        size="small"
                        label="Device"
                        select variant="outlined"
                        SelectProps={{native: true}}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e)=>this.props.ChangeBlockActionField("device_id",e.target.value)}
                        style={{marginLeft:5}}
                    >
                        <option key={"block_"+this.props.selectedBlock.id+"_action_device_0"} value="0"></option>
                        {
                            this.props.inventory.map(device =>
                                <option key={"block_"+this.props.selectedBlock.id+"_action_device_"+device.id} value={device.id}>{device.title}</option>
                            )
                        }
                    </TextField>
                    {field_options &&
                    <TextField
                        error={this.props.errors["blockFieldId"] && this.props.errors["blockFieldId"].length}
                        value={this.props.selectedBlock.action ? this.props.selectedBlock.action.field_id : "0"}
                        size="small"
                        label="Field"
                        select variant="outlined"
                        SelectProps={{native: true}}
                        InputLabelProps={{ shrink: true }}
                        style={{marginLeft:5}}
                        onChange={(e) => this.props.ChangeBlockActionField("field_id", e.target.value)}
                    >
                        <option key={"block_"+this.props.selectedBlock.id+"_action_field_0"} value="0"></option>
                        {field_options}
                    </TextField>
                    }
                    {current_field && <strong style={{whiteSpace:'nowrap',marginLeft:5,marginRight:5}}>{current_value} {current_field.unit}</strong>}
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',marginLeft:5,marginTop:10}}>
                    <label>To:</label>
                    <TextField
                        error={this.props.errors["blockValueDeviceId"] && this.props.errors["blockValueDeviceId"].length}
                        value={this.props.selectedBlock.action ? this.props.selectedBlock.action.value_device_id : ""}
                        size="small"
                        style={{marginLeft:5,marginRight:5}}
                        label="Set value to"
                        select variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                        onChange={(e)=>this.props.ChangeBlockActionField("value_device_id",e.target.value)}
                    >
                        <option key={"block_"+this.props.selectedBlock.id+"_action_value_device_0"} value="">Static value</option>
                        {
                            this.props.inventory.map(device =>
                                <option key={"block_"+this.props.selectedBlock.id+"_action_device_"+device.id} value={device.id}>{device.title}</option>
                            )
                        }
                    </TextField>
                    {value_field_options && this.props.selectedBlock.action.value_device_id &&
                    <TextField
                        error={this.props.errors["blockValueFieldId"] && this.props.errors["blockValueFieldId"].length}
                        value={this.props.selectedBlock.action ? this.props.selectedBlock.action.value_field_id : ""}
                        size="small"
                        label="Specified field"
                        select variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                        onChange={(e) => this.props.ChangeBlockActionField("value_field_id", e.target.value)}
                    >
                        <option key={"block_" + this.props.selectedBlock.id + "_action_value_field_0"}
                                value=""/>
                        {value_field_options}
                    </TextField>
                    }
                    {!this.props.selectedBlock.action.value_device_id  &&
                    <TextField
                        error={this.props.errors["blockValue"] && this.props.errors["blockValue"].length}
                        size="small"
                        label="Specified value"
                        variant="outlined"
                        type={"number"}
                        InputLabelProps={{ shrink: true }}
                        value={this.props.selectedBlock.action && this.props.selectedBlock.action.value ? this.props.selectedBlock.action.value : "0"}
                        onChange={(e) => {
                            this.props.ChangeBlockActionField('value', parseFloat(e.target.value))
                        }}
                    />
                    }
                    {current_value_field && <strong style={{whiteSpace:'nowrap',marginLeft:5,marginRight:5}}>{current_value_value} {current_value_field.unit}</strong>}
                    </div>

            </div>
        )
    }

    renderConditionBlockProperties() {
        const condition = this.props.selectedBlock.condition;
        const value_field_options = this.getFieldOptions(condition.value_device_id);
        let current_value_field = null;
        let current_value = null;
        if (condition.value_device_id && condition.value_field_id && this.props.status && this.props.status[condition.value_device_id] && this.props.status[condition.value_device_id][condition.value_field_id]) {
            current_value = this.props.status[condition.value_device_id][condition.value_field_id];
            let device_index = this.props.inventory.findIndex(item => item.id == condition.value_device_id)
            if (device_index !== -1) {
                current_value_field = this.props.inventory[device_index].fields[condition.value_field_id];
                if (current_value_field) {
                    current_value = this.getFieldValueDescription(current_value_field, current_value);
                }
            }
        }
        return (
            <div style={{flexDirection:'column',display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'flex-start',alignItems:'center',marginTop:10,marginBottom:10}}>
                    <label className="control-label">If any of</label>
                    <Button mr={2} variant="contained" color="primary" className="pull-right" style={{backgroundColor:"green",marginLeft:5,marginRight:5}}
                            onClick={()=>this.props.AddConditionFieldToBlock()}>
                        <Add />
                        Add field
                    </Button>
                    <label className="control-label">fields</label>
                </div>
                <div style={{flexDirection:'column',display:'flex',justifyContent:'flex-start',alignItems:'flex-start',marginTop:10,marginBottom:10}}>
                    {condition.fields && condition.fields.length ?
                        condition.fields.map((field,index) => {
                            const field_options = this.getFieldOptions(field.device_id);
                            let current_field =null;
                            let current_value = null;
                            if (field.field_id) {
                                if (this.props.status && this.props.status[field.device_id] && this.props.status[field.device_id][field.field_id]) {
                                    current_value = this.props.status[field.device_id][field.field_id];
                                    let device_index = this.props.inventory.findIndex(item => item.id == field.device_id)
                                    if (device_index !== -1) {
                                        current_field = this.props.inventory[device_index].fields[field.field_id];
                                        if (current_field) {
                                            current_value = this.getFieldValueDescription(current_field, current_value);
                                        }
                                    }
                                }
                            }
                            return (
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}} key={this.props.selectedBlock.id+"_condition_"+field.device_id+"_"+field.field_id}>
                                    <TextField
                                        error={this.props.errors["blockDeviceId"] && this.props.errors["blockDeviceId"].length}
                                        value={field ? field.device_id : "0"}
                                        size="small"
                                        label="Device"
                                        select variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{native: true}}
                                        onChange={(e)=>this.props.ChangeBlockConditionField("device_id",e.target.value,index)}
                                    >
                                        <option key={"block_"+this.props.selectedBlock.id+"_condition_device_0"} value="0"></option>
                                        {
                                            this.props.inventory.map(device =>
                                                <option key={"block_"+this.props.selectedBlock.id+"_action_device_"+device.id} value={device.id}>{device.title}</option>
                                            )
                                        }
                                    </TextField>
                                    {field_options &&
                                    <TextField
                                        error={this.props.errors["blockFieldId"] && this.props.errors["blockFieldId"].length}
                                        value={field ? field.field_id : "0"}
                                        size="small"
                                        label="Field"
                                        select variant="outlined"
                                        style={{marginLeft:5}}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{native: true}}
                                        onChange={(e) => this.props.ChangeBlockConditionField("field_id", e.target.value,index)}
                                    >
                                        <option key={"block_"+this.props.selectedBlock.id+"_condition_field_0"} value="0"></option>
                                        {field_options}
                                    </TextField>}
                                    {current_field && <strong style={{whiteSpace:'nowrap',marginLeft:5,marginRight:5}}>{current_value} {current_field.unit}</strong>}
                                    <Tooltip title="Delete field" arrow>
                                        <IconButton onClick={()=>this.props.RemoveConditionField(index)}>
                                            <DeleteForever style={{color:'red'}}/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )
                        }) : null
                    }
                </div>
                <div style={{flexDirection:'row',display:'flex',justifyContent:'flex-start',alignItems:'center',marginTop:10,marginBottom:10,width:'100%'}}>
                    <label className="control-label">is</label>
                    <TextField
                        style={{marginLeft:5,marginRight:5}}
                        error={this.props.errors["conditionOperator"] && this.props.errors["conditionOperator"].length}
                        value={condition.operator}
                        size="small"
                        label="Operator"
                        select variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                        onChange={(e)=>this.props.ChangeBlockConditionField("operator",e.target.value)}
                    >
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_0"} value="         "></option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_="} value="==">=</option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_<>"} value="<>">{"<>"}</option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_>"} value=">">{">"}</option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_<"} value="<">{"<"}</option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_>="} value=">=">{">="}</option>
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_operator_<="} value="<=">{"<="}</option>
                    </TextField>
                    <TextField
                        error={this.props.errors["blockValueDeviceId"] && this.props.errors["blockValueDeviceId"].length}
                        value={condition.value_device_id || ""}
                        size="small"
                        style={{marginLeft:5,marginRight:5}}
                        label="Device"
                        select variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                        onChange={(e)=>this.props.ChangeBlockConditionField("value_device_id",e.target.value)}
                    >
                        <option key={"block_"+this.props.selectedBlock.id+"_condition_value_device_0"} value="">Static value</option>
                        {
                            this.props.inventory.map(device =>
                                <option key={"block_"+this.props.selectedBlock.id+"_condition_device_"+device.id} value={device.id}>{device.title}</option>
                            )
                        }
                    </TextField>
                    {value_field_options && condition.value_device_id &&
                    <TextField
                        error={this.props.errors["blockValueFieldId"] && this.props.errors["blockValueFieldId"].length}
                        value={condition.value_field_id || ""}
                        size="small"
                        label="Field"
                        select variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                        onChange={(e) => this.props.ChangeBlockConditionField("value_field_id", e.target.value)}
                    >
                        <option key={"block_" + this.props.selectedBlock.id + "_condition_value_field_0"}
                                value=""/>
                        {value_field_options}
                    </TextField>
                    }
                    {current_value_field && <strong style={{whiteSpace:'nowrap',marginLeft:5,marginRight:5}}>{current_value} {current_value_field.unit}</strong>}
                    {!condition.value_device_id  &&
                    <TextField
                        error={this.props.errors["blockValue"] && this.props.errors["blockValue"].length}
                        size="small"
                        label="Value"
                        variant="outlined"
                        type={"number"}
                        InputLabelProps={{ shrink: true }}
                        value={condition.value ? condition.value : "0"}
                        onChange={(e) => {
                            this.props.ChangeBlockConditionField('value', parseFloat(e.target.value))
                        }}
                    />
                    }
                </div>
            </div>
        )
    }

    getFieldOptions(device_id) {
        let field_options = [];
            const fields = [];
            const deviceIndex = this.props.inventory.findIndex(item=>item.id == device_id);
            if (deviceIndex === -1) { return field_options; }
            for (let field_id in this.props.inventory[deviceIndex].fields) {
                if (field_id === "*") {
                    continue;
                }
                const field = this.props.inventory[deviceIndex].fields[field_id];
                fields.push(field.title + " " + field_id);
            }
            fields.sort((s1, s2) => s1.toString().toLowerCase() < s2.toString().toLowerCase() ? -1 : 1);
            field_options = fields.map(field => {
                let parts = field.split(" ");
                const id = parts.pop();
                const title = parts.join(" ");
                return <option value={id} key={"block_"+this.props.selectedBlock.id+"_action_field_"+id}>{title} ({id})</option>
            });
        return field_options;
    }

    getDiagramElementsFromBlocks() {
        const elements = this.props.selectedFlowObject.blocks.map(block => {
            return {
                id: block.id.toString(),
                data: { label: block.title},
                position: block.position,
                style: block.passed ? {backgroundColor:"#FFFF00"} : block.type == "condition" ? {backgroundColor:"#DAE8FC"} : {backgroundColor:"#D5E8D4"}
            }
        });
        for (let block of this.props.selectedFlowObject.blocks) {
            const sourceElementIndex = elements.findIndex(item => item.id == block.id);
            const sourceElement = elements[sourceElementIndex];
            if (block.type === "action") {
                if (block.nextBlock) {
                    const elementIndex = elements.findIndex(item => item.id == block.nextBlock);
                    if (elementIndex !== -1) {
                        elements.push({
                            type:'smoothstep',
                            source: sourceElement.id.toString(),
                            target: elements[elementIndex].id.toString(),
                            arrowHeadType: 'arrowclosed',
                            id: "e-"+sourceElement.id+"-"+elements[elementIndex].id
                        })
                    }
                }
            } else if (block.type === "condition") {
                if (block.trueBlock) {
                    const elementIndex = elements.findIndex(item => item.id == block.trueBlock);
                    if (elementIndex !== -1) {
                        elements.push({
                            type:'smoothstep',
                            source: sourceElement.id.toString(),
                            target: elements[elementIndex].id.toString(),
                            id: "e-"+sourceElement.id+"-"+elements[elementIndex].id,
                            arrowHeadType: 'arrowclosed',
                            label: "True"
                        })
                    }
                }
                if (block.falseBlock) {
                    const elementIndex = elements.findIndex(item => item.id == block.falseBlock);
                    if (elementIndex !== -1) {
                        elements.push({
                            type:'smoothstep',
                            source: sourceElement.id.toString(),
                            target: elements[elementIndex].id.toString(),
                            id: "e-"+sourceElement.id+"-"+elements[elementIndex].id,
                            arrowHeadType: 'arrowclosed',
                            label: "False"
                        })
                    }
                }

            }
        }
        return elements
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

    getFieldValueDescription(field,value) {
        if (value !== null && value !== "" && field.value_descriptions && field.value_descriptions.length) {
            const index = field.value_descriptions.findIndex(item => item.value == value);
            if (index !== -1) {
                return field.value_descriptions[index]["description"];
            }
        }
        return value;
    }

}
