import Base from './../Base';
import Component from '../../pages/settings/Manage';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";
import moment from "moment-timezone";

/**
 * Controller for Terminal Session component
 */
export default class Manage extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Manage to display
     */
    static component = null;
    static getComponent() {
        if (!Manage.component) {
            const item = new Manage();
            Manage.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Manage.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            command: state.terminalCommand ? state.terminalCommand : '',
            buffer: state.terminalBuffer ? state.terminalBuffer.join('<br/>') : '',
            selectedTab: state.selectedTab || 0,
            lastTimestamp: state.lastTimestamp,
            isUpdating: state.isUpdating,
            isRestarting: state.isRestarting,
            error:state.error
        };
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        const response = await HttpClient.request('/updates/load',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            Store.changeProperty("lastTimestamp",response.data.config.lastTimestamp);
        }
        if (!this.intervals || !this.intervals.length || typeof(this.intervals)==='undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof(this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.GetStatus();
                    this.isChecking = false;
                }
            }, 1000));
        }
    }

    async GetStatus() {
        const response = await HttpClient.request('/terminal/get_status',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            const state = Store.getState();
            for (let lines of response.data.terminalBuffer) {
                for (let line of lines) {
                    state.terminalBuffer.push(line);
                }
            }
            this.ChangeField('terminalBuffer',state.terminalBuffer);
        }
    }

    async OnCommandKeyPress(e) {
        const code = e.which || e.keyCode;
        if (code == 13) {
            const state = Store.getState();
            state.terminalBuffer.push("$ "+this.command);
            await HttpClient.request("/terminal/post_execCommand","post",{command:this.command});
            Store.changeProperties({
                terminalBuffer: state.terminalBuffer,
                terminalCommand: ''
            });
        }
    }

    OnCommandChange(e) {
        this.ChangeField('terminalCommand',e.target.value);
    }

    async Update() {
        const result = await HttpClient.request("/updates/checkUpdate","get",{});
        if (result && result.data && result.data.timestamp) {
            if (window.confirm("New software update from "+moment(result.data.timestamp*1000).format("YYYY-MM-DD HH:mm:ss")+" available. Do you want to update now ?")) {
                Store.changeProperties({"isUpdating":true,"error":""});
                const result = await HttpClient.request("/updates/getUpdate","get",{});
                if (result && result.data && result.data.status === "ok") {
                    Store.changeProperties({"isUpdating":false,"isRestarting":true});
                    setInterval(async() => {
                        try {
                            const response= await HttpClient.request("/auth/check");
                            if (response.data && response.data.status==="ok") {
                                window.location.reload();
                            }
                        } catch (err) {}
                    },5000)
                } else {
                    if (result && result.data && result.data.message) {
                        Store.changeProperties({"isUpdating":false,error:result.data.message})
                    } else {
                        Store.changeProperties({"isUpdating":false,error:"System error"})
                    }
                }
            }
        } else {
            if (result.data && result.data.message) {
                window.alert(result.data.message);
            } else {
                window.alert("No updates available");
            }
        }
    }

    async Restart() {
        if (!window.confirm("Do you want to restart the system ?")) {
            return
        }
        const result = await HttpClient.request("/updates/getRestart","get",{});
        if (!result || !result.data) {
            window.alert("Could not restart system");
            return;
        }
        Store.changeProperties({"error":"","isRestarting":true});
        setInterval(async() => {
            try {
                const response= await HttpClient.request("/auth/check");
                if (response.data && response.data.status==="ok") {
                    window.location.reload();
                }
            } catch (err) {}
        },5000)
    }
}
