import Base from './../Base';
import Component from '../../pages/inventory/Inventory';
import { connect } from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";
import getBackendUrl from "../../utils/getBackendUrl";
import data from "../../config/data";
import _ from 'lodash';

/**
 * Controller for Inventory component
 */
export default class Inventory extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Inventory.component) {
            const item = new Inventory();
            Inventory.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Inventory.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state, ownProps) {
        const props = {
            config: state.config.inventory && state.config.inventory.lan,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            selectedDevice: ownProps.selectedDevice || "",
            selectedUnit: state.selectedUnit || "",
            selectedTab: state.selectedTab || 0,
            history: ownProps.history
        };
        let selectedDevice = null;
        if (ownProps.selectedDevice && state.config.inventory) {
            if (ownProps.selectedDevice === 'new') {
                props.selectedTab = 1;
                selectedDevice = state.newDevice;
                if (typeof (selectedDevice.enabled) === "undefined") {
                    selectedDevice.enabled = true;
                }
                if (typeof (selectedDevice.simulated) === "undefined") {
                    selectedDevice.simulated = false;
                }
            } else {
                const selectedDeviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.selectedDevice);
                if (selectedDeviceIndex !== -1) {
                    selectedDevice = state.config.inventory.lan[selectedDeviceIndex];
                }
            }
            if (selectedDevice) {
                props['selectedDeviceObject'] = selectedDevice;
                props['id'] = selectedDevice.id || '';
                props['title'] = selectedDevice.title || '';
                props['host'] = selectedDevice.host || '';
                props['port'] = selectedDevice.port || '';
                props['modbus_id'] = selectedDevice.modbus_id || '';
                props['type'] = selectedDevice.type || '';
                props['data_read_interval'] = selectedDevice.data_read_interval || 15;
                props['send_to_portal_period'] = selectedDevice.send_to_portal_period || 15;
                props['save_to_db_period'] = selectedDevice.save_to_db_period || 15;
                props['enabled'] = selectedDevice.enabled;
                props['simulated'] = selectedDevice.simulated;
                props['alerts'] = selectedDevice.alerts || {};
                if (!state.selectedUnit && selectedDevice.units && selectedDevice.units.length) {
                    state.selectedUnit = selectedDevice.units[0].id || 0;
                }
                if (state.selectedUnit && selectedDevice.units && selectedDevice.units.length) {
                    let selectedUnitIndex = selectedDevice.units.findIndex(item => item.id === state.selectedUnit);
                    if (selectedUnitIndex === -1) {
                        selectedUnitIndex = 0;
                        props['selectedUnit'] = selectedDevice.units[0].id;
                    }
                    if (selectedUnitIndex !== -1) {
                        const selectedUnit = selectedDevice.units[selectedUnitIndex];
                        props['selectedUnitObject'] = selectedUnit;
                    }
                }
            }
        }
        return Object.assign(super.setupStateProps(state, ownProps), props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (!this.config) {
            await this.loadConfig();
        }
        if (!this.intervals || !this.intervals.length || typeof (this.intervals) === 'undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof (this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.GetStatus();
                    this.isChecking = false;
                }
            }, 1000));
        }
    }

    async Save() {
        this.ChangeField('errors', {});
        if (!this.validate()) {
            this.applyPropsToState(["errors"]);
            return;
        }
        this.ChangeField('isLoading', true);
        let state = Store.getState();
        const device_id = this.id;
        if (this.selectedDevice === 'new' && this.id && this.title) {
            this.ChangeDeviceField("data_read_interval", this.data_read_interval);
            this.ChangeDeviceField("send_to_portal_period", this.send_to_portal_period);
            this.ChangeDeviceField("save_to_db_period", this.save_to_db_period);
            this.ChangeDeviceField("enabled", this.enabled);
            this.ChangeDeviceField("simulated", this.simulated);
            this.ChangeDeviceField("alerts", {});
            state = Store.getState();
            state.config.inventory.lan.push(state.newDevice);
        }
        for (let device_id in state.config.inventory.lan) {
            let device = state.config.inventory.lan[device_id];
            for (let field_id in device.fields) {
                let field = device.fields[field_id];
                if (field_id == 'new') {
                    if (!field.id) { return; }
                    device.fields[field.id] = device.fields[field_id];
                    delete (device.fields[field_id]);
                    state.config.inventory.lan[device_id] = device;
                }
            }
        }
        let response = await HttpClient.request("/config/save", "post", { config: state.config });
        if (response && response.data && response.data.status && response.data.status === "ok") {
            Store.changeProperties({
                isLoading: false,
                successMessageText: "Changes saved successfully",
                password: "",
                newDevice: {},
                confirmPassword: "",
                config: state.config
            });
            if (this.selectedDevice === 'new' || this.type === "cluster_controller") {
                window.location.href = "/inventory/" + device_id;
            } else {
                setTimeout(() => { this.ChangeField("successMessageText", "") }, 3000);
            }
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
        this.loadConfig()
    }

    validate() {
        this.errors = {};
        const reg = new RegExp("[0-9A-Za-z_]+$");
        if (!this.id || !reg.test(this.id)) { this.errors["id"] = "Incorrect ID. ID must contain only letters, numbers and underscores"; }
        if (!this.title || !this.title.toString().trim()) { this.errors["title"] = "Title not specified" }
        if (this.selectedDevice === "new") {
            if (this.config.findIndex(item => item.id == this.id) !== -1) {
                this.errors["id"] = "Device with specified ID already exists";
            }
        } else if (this.selectedDevice !== this.id && this.config.findIndex(item => item.id == this.id) !== -1) {
            this.errors["id"] = "Device with specified ID already exists";
        }
        if (!this.host || !this.host.toString().trim()) { this.errors["host"] = "Host not specified"; }
        if (!this.type || !data.reader_types[this.type] || typeof (data.reader_types[this.type]) === "undefined") {
            this.errors["type"] = "Device type not specified";
        } else {
            if (this.type !== "BQ76952" && this.type !== 'offgrid_controller' && (!this.port || !this.port.toString().trim() || this.port == 0)) {
                this.errors["port"] = "Port not specified";
            }
        }
        if (!this.data_read_interval || this.data_read_interval != parseInt(this.data_read_interval)) {
            this.errors["data_read_interval"] = "Incorrect interval. Must be integer";
        }
        if (!this.send_to_portal_period || this.send_to_portal_period != parseInt(this.send_to_portal_period)) {
            this.errors["send_to_portal_period"] = "Incorrect interval. Must be integer";
        }
        if (!this.save_to_db_period || this.save_to_db_period != parseInt(this.save_to_db_period)) {
            this.errors["save_to_db_period"] = "Incorrect interval. Must be integer";
        }
        if (this.type === "sma_speedwire" && !this.selectedDeviceObject.device_model_id) {
            this.errors["device_model_id"] = "Device model must be specified";
        }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async loadConfig() {
        this.ChangeField("isLoading", true);
        const response = await HttpClient.request("/config/load", 'get', {});
        if (response.data.status === 'ok' && response.data.config && typeof (response.data.config) === 'object') {
            this.ChangeField('config', response.data.config);
            this.ChangeField("isLoading", false);
        }
    }

    ChangeDeviceField(name, value) {
        const state = Store.getState();

        if (this.selectedDevice !== 'new') {
            const deviceIndex = state.config.inventory.lan.findIndex((item) => item.id === this.selectedDevice);
            if (deviceIndex == -1) { return }
            let device = state.config.inventory.lan[deviceIndex];
            if (!device['alerts']) { device['alerts'] = {}; }
            eval("device" + Store.getPropertyNameExpression(name) + " = _.cloneDeep(value);");
            state.config.inventory.lan[deviceIndex] = device;
            Store.changeProperty('config', state.config);
        } else {
            state.newDevice[name] = value;
            Store.changeProperty('newDevice', _.cloneDeep(state.newDevice));
        }
    }

    convertDotsToArray(name) {
        let result = "";
        const parts = name.split(".");
    }

    async GetStatus() {
        if ((this.selectedTab !== 0 && this.selectedTab !== 3) || !this.selectedDevice) { return; }
        let now = Date.now();
        const response = await HttpClient.request('/inventory/get_status', "get", {});
        if (response && typeof (response.data) !== 'undefined' && response.data.status === 'ok') {
            const state = Store.getState();
            this.ChangeField("status", response.data.result.lan);
            this.ChangeField("diffTime", Date.now() - now);
            // let device = Object.keys(response.data.result.lan)[0]
            // this.ChangeField("old_status_" + device, response.data.result.lan);

            // let status = _.merge(state["old_status_" + device], response.data.result.lan)
            // if (response.data.result.lan) {
            //     console.log("resp " + device + " " + JSON.stringify(response.data.result.lan))
            //     console.log("old status " + device + " " + JSON.stringify(state["old_status_" + device]))
            //     console.log("merge status " + device + " " + JSON.stringify(status))
            // }
            // if (typeof (device) !== 'undefined')
            //     this.ChangeField("status", status);
            // this.ChangeField("status", status);

        }
    }

    async Delete() {
        if (!window.confirm("Are you sure ?")) { return; }
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.selectedDevice);
        state.config.inventory.lan.splice(deviceIndex, 1);
        const selectedDevice = state.config.inventory.lan.length ? state.config.inventory.lan[0] : '';
        Store.changeProperties({ config: state.config, selectedDevice: selectedDevice });
    }

    DownloadSettings() {
        window.open(getBackendUrl() + "/config/downloadSettings/?token=" + window.localStorage.getItem("token"));
    }

    UploadSettings() {
        this.errors = {};
        window.$("#uploadImage").toArray()[0].value = '';
        window.$("#uploadImage").trigger('click');
    };

    OnFileSelected() {
        const files = window.$("#uploadImage")[0].files;
        if (!files || !files.length) { return }
        const file = files[0];
        const reader = new FileReader();
        this.errors = {};
        reader.onload = async (event) => {
            const response = await HttpClient.request("/config/uploadSettings", 'post', { config: event.target.result });
            if (!response.data) {
                this.errors.general = "Server error";
            } else if (response.data && response.data.status !== "ok") {
                this.errors.general = response.data.message || "Server error";
            } else {
                window.location.reload();
            }
            this.applyPropsToState(["errors"]);
        };
        reader.readAsText(file);
    };
}
