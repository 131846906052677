import Base from './../Base';
import Component from '../../pages/settings/PortalSettings';
import {connect} from 'react-redux';
import HttpClient from '../../services/HttpClient';
import Store from "../../store/Store";

/**
 * Controller for Portal Settings component
 */
export default class PortalSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!PortalSettings.component) {
            const item = new PortalSettings();
            PortalSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return PortalSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
        },{
            host: state.portal_settings.host || "",
            port: state.portal_settings.port || "",
            login: state.portal_settings.login || "",
            password:state.portal_settings.password || '',
            send_to_portal_period:state.portal_settings.send_to_portal_period || '',
            keep_alive_interval : state.portal_settings.keep_alive_interval || '',
            connected:state.portal_settings.connected,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        if (!this.send_to_portal_period) { await this.load(); }
        if (!this.intervals || !this.intervals.length || typeof(this.intervals)==='undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof(this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.GetStatus();
                    this.isChecking = false;
                }
            }, 1000));
        }
        super.Init();
    }


    async Save() {
        this.ChangeField('errors',{});
        if (this.validate()) {
            this.ChangeField('isLoading',true);
            const state = Store.getState();
            let response = await HttpClient.request("/portal/post_save","post",{
                host:this.host,
                port:this.port,
                login:this.login,
                password:this.password,
                send_to_portal_period:this.send_to_portal_period,
                keep_alive_interval: this.keep_alive_interval
            });
            this.ChangeField("isLoading",false);
            if (response && response.data && response.data.status && response.data.status === "ok") {
                Store.changeProperties({
                    isLoading:false,
                    successMessageText:"Changes saved successfully"
                });
                setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
            } else {
                if (response && response.data && response.data.status && response.data.status === "error") {
                    this.errors['general'] = response.data.message;
                } else {
                    this.errors['general'] = 'Error saving changes';
                }
                this.applyPropsToState("errors");
            }
        } else {
            this.applyPropsToState("errors");
        }
    }

    validate() {
        if (!this.host) { this.errors['host'] = 'Host not specified';}
        if (!this.port) { this.errors['port'] = 'Port not specified';}
        if (!this.login) { this.errors['login'] = 'Login not specified';}
        if (!this.password) { this.errors['password'] = 'Password not specified';}
        if (!this.send_to_portal_period) {
            this.errors['send_to_portal_period'] = 'Send data period not specified'
        } else if (isNaN(parseInt(this.send_to_portal_period)) || parseInt(this.send_to_portal_period)<=0) {
            this.errors['send_to_portal_period'] = 'Incorrect send data period'
        }
         if (!this.keep_alive_interval) {
            this.errors['keep_alive_interval'] = 'Keep alive period not specified'
        } else if (isNaN(parseInt(this.keep_alive_interval)) || parseInt(this.keep_alive_interval)<=0) {
            this.errors['keep_alive_interval'] = 'Incorrect keep alive period'
        }
        

        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async load() {
        this.ChangeField("isLoading",true);
        const response = await HttpClient.request("/portal/get_settings","get",{});
        if (response && response.data) {
            response.data.config.connected = response.data.connected;
            Store.changeProperties({
                isLoading:false,
                portal_settings:response.data.config
            });
        }
    }

    async OnConnectBtnClick(connect) {
        try {
            const url = connect ? '/portal/post_connect' : '/portal/post_disconnect';
            this.ChangeField("errors",{});
            const response = await HttpClient.request(url,"post",{});
            if (!response || typeof(response.data) === 'undefined' || !response.data.status) {
                this.errors['general'] = "System error";
            }
            if (response.data.status === 'error') {
                this.errors['general'] = response.data.message;
            }
        } catch (err) {
            this.isLoading = false;
            this.errors['general'] = err.toString();
        }
        this.applyPropsToState(['errors','isLoading']);
    }

    async GetStatus() {
        const response = await HttpClient.request('/portal/get_status',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            this.ChangeField('portal_settings.connected',response.data.connected);
        }
    }

}
