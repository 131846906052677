import React,{Component} from "react";
import {
    Grid,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

export default class Adam6000SettingsAlerts extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.config) { return null; }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.alerts.alert_if_relay_triggered} onChange={(e)=>this.props.ChangeDeviceField("alerts.alert_if_relay_triggered",e.target.checked)}/>
                        }
                        label="Alert if relay triggered"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.alerts.alert_if_relay_status_changed} onChange={(e)=>this.props.ChangeDeviceField("alerts.alert_if_relay_status_changed",e.target.checked)}/>
                        }
                        label="Alert if relay status changed"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.props.alerts.alert_if_trigger_condition_match} onChange={(e)=>this.props.ChangeDeviceField("alerts.alert_if_trigger_condition_match",e.target.checked)}/>
                        }
                        label="Alert if relay trigger condition match"
                    />
                </Grid>
            </Grid>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }


}
