/**
 * Method used to construct full root URL of backend
 * @returns {string}
 */
export default () => {
    let result = "";
    if (window.backend_host) {
        result += "http://" + window.backend_host;
        if (window.backend_port) {
            result += ":" + window.backend_port;
        }
    }
    if (window.backend_url) {
        result += window.backend_url;
    }
    return result;
}
