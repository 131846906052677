import DeviceStatus from './DeviceStatus';
import Component from '../../pages/inventory/Adam6000DeviceStatus';
import {connect} from 'react-redux';
import HttpClient from "../../services/HttpClient";

/**
 * Controller for Device Status component
 */
export default class Adam6000DeviceStatus extends DeviceStatus {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;

    static getComponent() {
        if (!Adam6000DeviceStatus.component) {
            const item = new Adam6000DeviceStatus();
            Adam6000DeviceStatus.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Adam6000DeviceStatus.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state, ownProps) {
        const props = super.setupStateProps(state, ownProps);
        props["show_all_fields"] = true;
        return Object.assign(super.setupStateProps(state, ownProps), props);
    }

    /**
     * Function used to check if current device or unit is online (e.g. sending data)
     * @return {boolean}
     */
    IsOnline() {
        if (!this.status) {
            return false;
        }
        return Date.now() - this.status.timestamp < 60000;
    }

    async OnRelayClick(device_id, relay_id, status) {
        if (status === null) {
            return
        }
        await HttpClient.request("/relayboard/post_switch_relay", 'POST', {device_id, relay_id, status: status})
    }
}
