import React, { Component } from "react";
import moment from "moment-timezone";
import _ from 'lodash';
import { FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import { Edit, Check, Cancel } from "@material-ui/icons";
import Store from "../../store/Store";

export default class DeviceStatus extends Component {
    constructor(props) {
        super(props);
        this.columns_count = 1;
    }

    render() {
        // if (!this.props.status || typeof(this.props.status) === 'undefined') { return null; }
        return (
            <div>
                {this.renderHeader()}
                {this.renderTable()}
            </div>
        )
    }

    getStatusInfo() {
        let fields = {};
        for (let field_id in this.props.config.fields) {
            fields[field_id] = this.props.config.fields[field_id];
            if (!fields[field_id].id) { fields[field_id].id = field_id; }
            if (!fields[field_id].id) {
                if (fields[field_id].modbus_register) { fields[field_id].id = fields[field_id].modbus_register; }
            }
        }
        fields = _.sortBy(fields, [function (o) {
            if (isNaN(o.id))
                return o.id;
            else
                parseInt(o.id)
        }]);
        // fields.sort((s1, s2) => {
        //     let s1p = s1.split(" ");
        //     const id1 = s1p.pop();
        //     let s2p = s2.split(" ");
        //     const id2 = s2p.pop();
        //     if (isNaN(id1) && isNaN(id2))
        //         return (parseInt(id1) - parseInt(id2))
        //     else
        //         return (id1 - id2)
        // });
        const status = this.props.status;
        const status_rows = [];
        let status_columns = [];
        let counter = 0;
        let c = 0;
        for (let field_id in fields) {
            const field = fields[field_id];
            if (field.id == '*' || !status || status[field.id] === null || typeof (status[field.id]) === "undefined" && !this.props.show_all_fields) { continue; }
            if (!status[field.id] && !field.bits && !this.props.show_all_fields) { continue; }
            status_columns.push(<td key={'status_' + field.id} style={{ padding: '5px', borderWidth: '1px', borderColor: 'black', borderStyle: 'solid' }}><span
                style={{ color: field.send_live_data || (this.props.config.fields['*'] && this.props.config.fields['*'].send_live_data) ? 'black' : 'gray' }}>
                {field.title} {field.register ? <span>({field.register})</span> : ""}
            </span>
            </td>);
            status_columns.push(this.renderStatusField(field))
            counter++;
            if (counter >= this.columns_count) {
                status_rows.push(<tr key={'status_row_' + status_rows.length + "_" + field.id + "_" + c}>{status_columns}</tr>);
                counter = 0;
                status_columns = [];
            }
            c++;
        }
        if (status_columns) {
            status_rows.push(<tr key={'status_row_' + (status_rows.length + 1) + "_end"}>{status_columns}</tr>);
        }
        return status_rows;
    }

    renderStatusField(field) {
        const status = this.props.status;
        const field_id = field.id ? field.id : field.register;
        return (
            <td style={{ padding: '5px', borderWidth: '1px', borderColor: 'black', borderStyle: 'solid' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: "100%"
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        {this.props.selectedFieldId === field_id && <input onChange={(e) => { this.props.ChangeField("selectedFieldValue", e.target.value) }} />}
                        <span style={{ color: field.send_live_data ? 'black' : 'gray' }}>
                            {status && typeof (status[field.id]) != 'undefined' && status[field.id] !== null ?
                                <span style={{
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}>{this.getFieldValueDescription(field, status[field.id])}</span> : ''}
                            {field.unit ? <span>&nbsp;{field.unit}</span> : ""}
                        </span>
                    </div>

                    <div style={{
                        display: field.mode == "W" || field.mode == "R/W" ? 'flex' : 'none',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: "100%"
                    }}>
                        {this.props.selectedFieldId !== field_id ?
                            <Tooltip title="Change value">
                                <Edit style={{ color: 'blue', cursor: 'pointer', marginLeft: 5 }}
                                    onClick={() => Store.changeProperties({ "selectedFieldId": field_id, selectedFieldValue: '' })}
                                />
                            </Tooltip>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                <Tooltip title="Write">
                                    <Check style={{ color: 'green', cursor: 'pointer', marginLeft: 5, marginRight: 5 }}
                                        onClick={() => this.props.WriteToSelectedField()}
                                    />
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <Cancel style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => this.props.ChangeField("selectedFieldId", null)}
                                    />
                                </Tooltip>
                            </div>
                        }
                    </div>
                </div>
            </td>
        )
    }

    renderHeader() {
        return <h2 style={{ color: this.props.IsOnline() ? 'green' : 'red' }}>
            {this.props.config.title} ({this.props.config.id + (this.props.IsOnline() ? "" : this.props.config.enabled ? " - Offline" : " - Disabled")})
        </h2>
    }
    renderLastReceiveTime() {
        if (this.props.status && this.props.status.timestamp)
            return (
                <div>
                    <strong>Latest receive:</strong>&nbsp;{moment(this.props.status.timestamp).format("YYYY-MM-DD HH:mm:ss SSSS")}<br /><br />
                </div>
            )
    }
    renderTable() {
        return (
            <div>
                {/* <strong>Current Timestamp:</strong>&nbsp;{moment(new Date()).format("YYYY-MM-DD HH:mm:ss SSSS")}<br /><br /> */}
                {this.renderLastReceiveTime()}
                <FormControlLabel
                    control={
                        <Checkbox checked={this.props.show_all_fields} onChange={(e) => this.props.ChangeField("show_all_fields", e.target.checked)} />
                    }
                    label="Show all fields"
                />
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        {this.getStatusInfo()}
                    </tbody>
                </table>
            </div>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

    getFieldValueDescription(field, value) {
        if (value !== null && value !== "" && field.value_descriptions && field.value_descriptions.length) {
            const index = field.value_descriptions.findIndex(item => item.value == value);
            if (index !== -1) {
                return field.value_descriptions[index]["description"];
            }
        }
        return value;
    }
}
