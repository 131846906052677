import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import moment from 'moment-timezone';
import Helmet from 'react-helmet';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from "@material-ui/core/TableContainer";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import {
    KeyboardTimePicker
} from '@material-ui/pickers';

import {
    Grid,
    Card,
    CardHeader,
    IconButton,
    Chip as MuiChip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
    Tooltip,
    Button,
    Typography,
    Switch,
    TextField,
    CardContent as MuiCardContent,
    Tab,
} from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { purple, blue, green, red, orange, white, cyan, grey, lime } from "@material-ui/core/colors";

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${props => props.theme.spacing(4)}px;
  }
`;

//old is 168 px 
const ChartWrapper = styled.div`
  height: 168px; 
  position: relative;
  display: flex
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -18px;
  text-align: center;
  z-index: 0;
`;

const TableRow2 = styled(TableRow)`
  height: 50px;
  background-color:  theme.palette.secondary.main
  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;


const StyledRRTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: purple[100],
        },
    },
}))(TableRow);

const StyledRRTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: orange[300],
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 18,
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: lime[100],
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: green[200],
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const HighlightTableRow = withStyles((theme) => ({
    head: {
        backgroundColor: green[100],
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 22,
    },
}))(TableCell);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default class PhaseControl extends Component {

    state = {
        page: 0,
        rowsPerPage: 5,
        value: 100,
        date: moment(Date.now()).format('YYYY-MM-DD HH:mm')
    }

    handleDateChange = (date) => {
        // this.state.date = moment(date).format('YYYY-MM-DD HH:mm ') +  this.props.phaseControl.scheduleTime + " " + this.props.phaseControl.timeZone;
        // this.props.phaseControl.scheduleTime =  date.getHours() + ":" + date.getMinutes();
        this.state.date = moment(date).format('YYYY-MM-DD HH:mm ')
        this.props.ScheduleUpdate(date);
        this.setState({ date: this.state.date })
    };

    handleChangePage = (event, newPage) => {
        this.state.page = newPage;
        this.setState({ page: this.state.page });
    };

    handleChangeRowsPerPage = (event) => {
        this.state.rowsPerPage = +event.target.value;
        this.setState({ rowsPerPage: this.state.rowsPerPage });
        this.state.page = 0;
        this.setState({ page: this.state.page });
    };

    handleSwitchRRTable = (event) => {
        let index = event.target.name.split("-")
        this.state.roundRobinTable[parseInt(index[0])].relays[index[1]] = event.target.checked ? "on" : "off"
        this.setState({ roundRobinTable: this.state.roundRobinTable });
    }
    handleSocTableChange = (event) => {
        this.state.roundRobinTable[parseInt(event.target.name)].soc = parseInt(event.target.value)
        this.setState({ roundRobinTable: this.state.roundRobinTable });
    }
    renderEventIcon = (event) => {
        if (event == "User Trigger") {
            return (
                <IconButton size="small" ><FlashOnIcon style={{ color: red[500] }} /></IconButton>
            )
        }
        if (event == "Phase Controller Trigger") {
            return (
                <IconButton size="small" ><FlashOnIcon style={{ color: green[500] }} /></IconButton>
            )
        }
        if (event == "Phase Rotate") {
            return (
                <IconButton size="small" ><AutorenewIcon style={{ color: purple[500] }} /></IconButton>
            )
        }
        if (event == "User Edit") {
            return (
                <IconButton size="small" ><SettingsIcon style={{ color: green[500] }} /></IconButton>
            )
        }
    }

    renderTriggerButton = (isEn) => {

    }

    render() {
        // const classes = useStyles();
        var data = {
            labels: ["SOC", ""],
            datasets: [
                {
                    data: [this.props.status.soc ? this.props.status.soc : 0, this.props.status.soc ? (100 - this.props.status.soc) : 100],
                    backgroundColor: [
                        green[500],
                        grey[50],
                    ],
                    borderWidth: 5
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            cutoutPercentage: 80
        };

        if (this.props.isLoading) {
            return <div style={{ textAlign: 'center', width: "100%" }}><CircularProgress color="primary" /></div>
        }
        if (typeof (this.props.phaseControl) === 'undefined') {
            return <div style={{ textAlign: 'center', width: "100%" }}><CircularProgress color="primary" /></div>
        }

        const AdamDevice = this.props.phaseControl.adam.list;
        const SunnyDevice = this.props.phaseControl.sunny.list;
        var rows = this.props.phaseControl.table;
        const { page } = this.state;
        this.state.date = moment(Date.now()).format('YYYY-MM-DD ') + this.props.phaseControl.scheduleTime + " " + this.props.phaseControl.timeZone
        var now = new Date();
        var scheduleDay = new Date(this.state.date);
        var remanin = () => {
            let r = "";
            if (now > scheduleDay) {
                scheduleDay.setDate(scheduleDay.getDate() + 1);
            }
            r = (moment(scheduleDay - now).tz(this.props.phaseControl.timeZone).format("HH:mm:ss"));
            // console.log(r);
            // console.log(moment(scheduleDay - now).tz(this.props.phaseControl.timeZone).format("HH:mm:ss"))
            return r;
        }

        return (
            < React.Fragment >
                <Helmet title="Phase Controller" />
                <Grid container spacing={6} zeroMinWidth>
                    <Grid item xs={12} lg={6}>
                        <Card mb={6}>
                            <CardHeader
                                title={<Typography variant="h2" >{"Phase Controller Status"}</Typography>}
                            />
                            <Paper>
                                <Card mb={6}>
                                    <CardHeader
                                        title={
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', color: 'red', padding: '2px'
                                            }} >
                                                <Typography variant="h4" color="primary">{"Status of Sunny ID : "}</Typography>
                                                <Typography variant="h4" color="inherit" >{" " + this.props.phaseControl.sunny.activeId}</Typography>
                                                {
                                                    (this.props.phaseControl.sunny.activeId != "") ?
                                                        <Typography variant="h4" color="primary" >{this.props.status ? ("(" + this.props.status.sunnyOnline + ")") : ""}</Typography>
                                                        : <Typography variant="h4" color="inherit" >No Device</Typography>
                                                }
                                            </div>
                                        }
                                    />
                                    <Paper textAlign="center" padding="10px">
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'red', padding: 0
                                        }}>
                                            <ChartWrapper>
                                                <DoughnutInner variant="h2">
                                                    <Typography variant="h2" >{this.props.status.soc ? this.props.status.soc + "%" : "Loading"}</Typography>
                                                    <Typography variant="caption" color="primary">SOC</Typography>
                                                </DoughnutInner>
                                                <Doughnut data={data} options={options} />
                                            </ChartWrapper>
                                            <div style={{
                                                position: 'relative'
                                            }}>
                                                <Tooltip title="Trigger Phase Control " arrow>
                                                    <Button
                                                        disabled={this.props.isChangeEnable ? this.props.oldEnable : this.props.phaseControl.enable}
                                                        variant="contained" color="secondary" endIcon={<FlashOnIcon style={{ color: red[500] }} />}
                                                        onClick={() => { this.props.TriggerPhasecontrol() }}
                                                    >
                                                        <Typography variant="h4" >Trigger</Typography>
                                                    </Button>
                                                </Tooltip>
                                                {this.props.isTriggering && <CircularProgress size={24}
                                                    style={{
                                                        color: green[500],
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: -12,
                                                        marginLeft: -12,
                                                        position: 'absolute',
                                                    }} />}

                                            </div>
                                        </div>
                                    </Paper>
                                </Card>
                                <Card mb={4}>
                                    <CardHeader
                                        title={
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', color: 'red', padding: 2
                                            }} >
                                                <Typography variant="h4" color="primary">{"Status of Adam ID : "}</Typography>
                                                <Typography variant="h4" color="inherit" >{" " + this.props.phaseControl.adam.activeId}</Typography>
                                                {
                                                    (this.props.phaseControl.adam.activeId != "") ?
                                                        <Typography variant="h4" color="primary" >{this.props.status ? ("(" + this.props.status.adamOnline + ")") : ""}</Typography>
                                                        : <Typography variant="h4" color="inherit" >No Device</Typography>
                                                }
                                            </div>
                                        }
                                    />
                                    <Paper>
                                        <Table>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="center" style={{ fontSize: 18 }} > <strong>Phase A</strong></TableCell>
                                                    <TableCell align="center" style={{ fontSize: 18 }} > <strong>Phase B</strong></TableCell>
                                                    <TableCell align="center" style={{ fontSize: 18 }} > <strong>Phase C</strong></TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell align="center">
                                                        <HomeWorkIcon style={{ fontSize: 55, color: this.props.status.phase1 ? green[500] : grey[300] }}></HomeWorkIcon>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <HomeWorkIcon style={{ fontSize: 55, color: this.props.status.phase2 ? green[500] : grey[300] }}></HomeWorkIcon>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <HomeWorkIcon style={{ fontSize: 55, color: this.props.status.phase3 ? green[500] : grey[300] }}></HomeWorkIcon>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </Paper>
                                </Card>
                            </Paper>
                            <Card mb={4}>
                                <CardHeader
                                    title={<Typography variant="h3" >{"Recent Events"}</Typography>}
                                />
                                <Paper>
                                    <TableContainer style={{ height: 300 }}>
                                        <Table size="small" stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell></StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: 20 }}>Event</StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: 20 }}>Time</StyledTableCell>
                                                    <StyledTableCell style={{ fontSize: 20 }}>Details</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (typeof (this.props.status.recentEvents) !== 'undefined')
                                                    && this.props.status.recentEvents.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                        .map((element, index) =>
                                                        (
                                                            <StyledTableRow>
                                                                < TableCell>{
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', color: 'red', padding: 0 }}>
                                                                        <Tooltip title="Phase Cotrol event" arrow>
                                                                            {this.renderEventIcon(element.event)}
                                                                            {/* {<IconButton size="small" ><FlashOnIcon color="inherit" /></IconButton>} */}
                                                                        </Tooltip>
                                                                    </div>
                                                                }</TableCell>
                                                                <TableCell>{element.event}</TableCell>
                                                                <TableCell>{moment(element.time).tz('UTC').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                                <TableCell align="left">{element.detail}</TableCell>
                                                            </StyledTableRow>
                                                        )
                                                        )
                                                }
                                                {(typeof (this.props.status.recentEvents) !== 'undefined') && (this.props.status.recentEvents.length < 5) &&
                                                    <TableRow style={{ height: 53 * (5 - this.props.status.recentEvents.length) }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 15, 50]}
                                        component="div"
                                        count={(typeof (this.props.status.recentEvents) !== 'undefined') && this.props.status.recentEvents.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Card>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card mb={12}>
                            <CardHeader
                                title={<Typography variant="h2" >{"Phase Control Setting"}</Typography>}
                            />
                            <Card>
                                <CardHeader

                                    action={
                                        <Tooltip title="Saving your changes " arrow>
                                            {
                                                <div style={{
                                                    position: 'relative'
                                                }}>

                                                    {
                                                        <Button
                                                            disabled={!this.props.isChangingSetting || this.props.isSaving}
                                                            variant="contained" color="secondary" startIcon={<SaveIcon />}
                                                            onClick={() => this.props.SaveSetting()}
                                                        >
                                                            Save Setting
                                                        </Button>

                                                    }
                                                    {this.props.isSaving && <CircularProgress size={24}
                                                        style={{
                                                            color: green[500],
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: -12,
                                                            marginLeft: -12,
                                                            position: 'absolute',
                                                        }} />}

                                                </div>
                                            }
                                        </Tooltip>
                                    }
                                    title={<Typography variant="h3" >{"Inventories Setting"}</Typography>}

                                />
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: 16 }} align="center">{
                                                    <TextField
                                                        id="standard-select-currency"
                                                        select
                                                        value={this.props.phaseControl.adam.activeId}
                                                        helperText="Select Adam device"
                                                        onChange={(e) => this.props.SelectAdamDevice(e.target.value)}
                                                    >
                                                        {AdamDevice.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.id}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>}
                                                </TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="center">{
                                                    <TextField

                                                        id="standard-select-sunny"
                                                        select
                                                        value={this.props.phaseControl.sunny.activeId}
                                                        // onChange={handleChange}
                                                        onChange={(e) => this.props.SelectSunnyDevice(e.target.value)}
                                                        helperText="Select Sunny device"
                                                    >
                                                        {SunnyDevice.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <FormControlLabel
                                                        control={<Switch size="medium" checked={this.props.phaseControl.enable ? true : false}
                                                            onChange={(e) => this.props.EnablePhaseControl(e.target.checked)}
                                                        />}
                                                        label={this.props.phaseControl.enable ? "ENABLE" : "DISABLE"}
                                                        labelPlacement="start"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {["A", "B", "C"].map((n, index) =>
                                                (
                                                    <TableCell align="center">{
                                                        <TextField
                                                            id={"standard-select-phase" + n}
                                                            name={"relay" + n}
                                                            select
                                                            value={this.props.GetRelay(index)}
                                                            helperText={"Select Phase " + n}
                                                            onChange={(e) => this.props.SelectAdamRelay(index, e.target.value)}
                                                        >
                                                            {this.props.phaseControl.adam.list.map((option) => (
                                                                option.id == this.props.phaseControl.adam.activeId ?
                                                                    option.relays.map((element) => (
                                                                        <MenuItem key={element} value={element}>
                                                                            {element}
                                                                        </MenuItem>
                                                                    ))
                                                                    : ("No Relays available ")
                                                            )
                                                            )
                                                            }
                                                        </TextField>}
                                                    </TableCell>
                                                ))
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Card>
                            <Card>
                                <CardHeader
                                    title={<Typography variant="h3" >{"Phases rotate schedule"}</Typography>}

                                />
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="right">
                                                    <div
                                                        style={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '2px'
                                                        }}>
                                                        <Typography variant="h4" >{"Phases rotate at (" + this.props.phaseControl.timeZone + "): "}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="h4" color="primary" >{
                                                        moment(this.state.date).tz(this.props.phaseControl.timeZone).format('hh:mm A') + "  (" + remanin() + "s)"
                                                    }</Typography>
                                                </TableCell>
                                                {/* <TableCell></TableCell> */}
                                                <TableCell align="right">
                                                    {
                                                        <KeyboardTimePicker
                                                            margin="dense"
                                                            label="Edit"
                                                            id="time-picker"
                                                            // value={this.state.date}      
                                                            value={moment(Date.now()).format('YYYY-MM-DD ') + this.props.phaseControl.scheduleTime}
                                                            onChange={this.handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                        />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    </Table>
                                </TableContainer>



                            </Card>
                            <Card>
                                <CardHeader
                                    title={<Typography variant="h3" >{"Round Robin Table"}</Typography>}
                                />
                                <TableContainer>
                                    <Table size="small" stickyHeader aria-label="sticky table" >
                                        <TableHead>
                                            <TableRow>
                                                <StyledRRTableCell ></StyledRRTableCell>
                                                <StyledRRTableCell style={{ fontSize: 20 }} align="center">SOC value</StyledRRTableCell>
                                                <StyledRRTableCell align="center" style={{ fontSize: 20 }}>Phase A</StyledRRTableCell>
                                                <StyledRRTableCell align="center" style={{ fontSize: 20 }}>Phase B</StyledRRTableCell>
                                                <StyledRRTableCell align="center" style={{ fontSize: 20 }}>Phase C</StyledRRTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                [0, 1, 2].map((row, index) => (
                                                    index == this.props.currentIndex ?
                                                        <TableRow style={{ backgroundColor: '#f3e5f5' }}>
                                                            <TableCell align="left" >
                                                                {
                                                                    <Typography variant="h6" color="primary">{"Level " + (index + 1)}</Typography>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align="left">{
                                                                <form noValidate autoComplete="off" >
                                                                    <TextField id="standard-basic" label="SOC" align="right" style={{ margin: 8 }} size="small"
                                                                        variant="outlined"
                                                                        value={this.props["RRLevel" + row]}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        }}
                                                                        onChange={(event) => {
                                                                            this.state.value = event.target.value;
                                                                            this.setState({ value: event.target.value });
                                                                            this.props.UpdateSocRRTable(index, event.target.value);
                                                                        }}
                                                                    />
                                                                </form>
                                                            }
                                                            </TableCell>
                                                            {
                                                                [0, 1, 2].map((n) => (
                                                                    <TableCell >
                                                                        {/* <HomeWorkIcon style={{ fontSize: 30, color: this.props.phaseControl.table[row].relays[n] == "on" ? green[500] : grey[300] }}></HomeWorkIcon> */}
                                                                        <FormControlLabel
                                                                            control={
                                                                                // <Switch name={index + "-" + n} size="medium" checked={this.props.phaseControl.table[row].relays[n] == "on" ? true : false}
                                                                                <HomeWorkIcon style={{ fontSize: 35, color: this.props.phaseControl.table[row].relays[n] == "on" ? green[500] : grey[300] }}></HomeWorkIcon>
                                                                            }
                                                                            label={this.props.phaseControl.table[row].relays[n] == "on" ? "ON" : "OFF"}
                                                                        />
                                                                    </TableCell>
                                                                ))
                                                            }
                                                        </TableRow> :
                                                        <TableRow >
                                                            <TableCell align="left" >
                                                                {
                                                                    <Typography variant="h6" color="primary">{"Level " + (index + 1)}</Typography>
                                                                }
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align="left">{
                                                                <form noValidate autoComplete="off" >
                                                                    <TextField id="standard-basic" label="SOC" align="right" style={{ margin: 8 }} size="small"
                                                                        variant="outlined"
                                                                        value={this.props["RRLevel" + row]}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        }}
                                                                        onChange={(event) => {
                                                                            this.state.value = event.target.value;
                                                                            this.setState({ value: event.target.value });
                                                                            this.props.UpdateSocRRTable(index, event.target.value);
                                                                        }}
                                                                    />
                                                                </form>
                                                            }
                                                            </TableCell>
                                                            {
                                                                [0, 1, 2].map((n) => (
                                                                    <TableCell >
                                                                        {/* <HomeWorkIcon style={{ fontSize: 30, color: this.props.phaseControl.table[row].relays[n] == "on" ? green[500] : grey[300] }}></HomeWorkIcon> */}
                                                                        <FormControlLabel
                                                                            control={
                                                                                // <Switch name={index + "-" + n} size="medium" checked={this.props.phaseControl.table[row].relays[n] == "on" ? true : false}
                                                                                <HomeWorkIcon style={{ fontSize: 35, color: this.props.phaseControl.table[row].relays[n] == "on" ? green[500] : grey[300] }}></HomeWorkIcon>
                                                                            }
                                                                            label={this.props.phaseControl.table[row].relays[n] == "on" ? "ON" : "OFF"}
                                                                        />
                                                                    </TableCell>
                                                                ))
                                                            }
                                                        </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>

                        </Card>
                    </Grid>

                </Grid >
            </React.Fragment >
        )
    }
    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}

