import Base from './Base';
import Component from '../pages/charts/Chart';
import {connect} from 'react-redux';
import HttpClient from '../services/HttpClient';
import Store from "../store/Store";
import '../lib/smooth-plotter';
import moment from 'moment-timezone';
import _ from 'lodash';
import getBackendUrl from '../utils/getBackendUrl';

/**
 * Controller for Chart component
 */
export default class Chart extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Chart.component) {
            const item = new Chart();
            Chart.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Chart.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            charts: state.charts_settings && state.charts_settings.charts ? state.charts_settings.charts : null,
            categories: state.charts_settings && state.charts_settings.categories ? state.charts_settings.categories : [],
            chart_id: ownProps.chart_id,
            errors: state.errors || {},
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            history: ownProps.history,
            data: state.data || {},
            isChartLoading: state.isChartLoading || false,
            inventory: state.config && state.config.inventory && state.config.inventory.lan ? state.config.inventory.lan : null,
            current_time : state.current_time || "",
            status: this.status || {}
        };
        if (ownProps.chart_id) {
            props.chart = {
                id:ownProps.chart_id,
                chart_id:ownProps.chart_id,
                series:[]
            }
        }
        if (state.charts_settings.charts && state.charts_settings.charts.length) {
            const chartIndex = state.charts_settings.charts.findIndex(item => item.id == ownProps.chart_id);
            if (chartIndex !== -1) {
                props.chart = state.charts_settings.charts[chartIndex];
            }
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (this.charts === null || this.inventory == null) {
            await this.loadConfig();
        }
        await this.Refresh(false,false);
    }

    validate() {
        const errors = {};
        this.applyPropsToState("errors");
        if (!this.chart.title) {
            errors["title"] = "Required field"
        }
        let series_errors = {};
        for (let serie_index in this.chart.series) {
            const serie = this.chart.series[serie_index];
            const serie_errors = {};
            if (!serie.title) {
                serie_errors["title"] = "Required";
            }
            const fields_errors = {};
            for (let field_index in serie.fields) {
                const field_errors = {};
                const field = serie.fields[field_index];
                if (!field.device_id) {
                    field_errors["device_id"] = "Required";
                }
                const device = this.inventory[this.inventory.findIndex(item=>item.id===field.device_id)];
                if (!device) {
                    field_errors["device_id"] = "Incorrect device selected";
                } else {
                    if (device.units && !field.unit_id) {
                        field_errors["unit_id"] = "Required";
                    }
                }
                if (!field.field_id) {
                    field_errors["field_id"] = "Required";
                }
                if (Object.getOwnPropertyNames(field_errors).length>0) {
                    this.chart.series[serie_index].show_fields = true;
                    fields_errors[field_index] = _.cloneDeep(field_errors)
                }
            }
            if (Object.getOwnPropertyNames(fields_errors).length>0) {
                serie_errors["fields"] = _.cloneDeep(fields_errors);
            }
            if (Object.getOwnPropertyNames(serie_errors).length>0) {
                series_errors[serie_index] = _.cloneDeep(serie_errors);
            }
        }
        if (Object.getOwnPropertyNames(series_errors).length>0) {
            errors["series"] = _.cloneDeep(series_errors);
        }
        this.errors = errors;
        this.applyPropsToState("errors");
        return Object.getOwnPropertyNames(errors).length === 0
    }

    async Save() {
        if (!this.validate()) {
            this.updateChartStateFromProps();
            return;
        }
        this.ChangeField('isLoading',true);
        const state = Store.getState();
        let response = await HttpClient.request("/charts/post_savecharts","post",{charts:state.charts_settings});
        if (response && response.data && response.data.status && response.data.status === "ok") {
            Store.changeProperties({
                isLoading:false,
                successMessageText:"Changes saved successfully",
                charts:state.charts,
                newChartId:0
            });
            setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
    }

    async loadConfig() {
        this.ChangeField("isLoading",true);
        let response = await HttpClient.request("/charts/get_config",'get',{});
        if (response.data.status === 'ok' && response.data.charts_settings && typeof(response.data.charts_settings) === 'object') {
            const props = {charts_settings: response.data.charts_settings, isLoading:false};
            response = await HttpClient.request("/config/load",'get',{});
            if (response.data.status === 'ok' && response.data.config && typeof(response.data.config) === 'object') {
                props["config"] = response.data.config;
            }
            Store.changeProperties(props);
        }
    }

    ChangeChartField(name,value) {
        if (name === 'date_start' || name === 'date_end') {
            value = moment(value).unix();
        }
        this.chart[name] = value;
        this.updateChartStateFromProps();
    }

    async Refresh(csv=false,validate=true) {
        this.errors = {};
        this.applyPropsToState(["errors"]);
        if (!this.chart) { return };
        if (validate && !this.validate()) { return; }
        switch(this.chart.period) {
            case 'day':
                this.chart.date_start = moment().subtract(24,'hours').utc().unix();
                break;
            case 'week':
                this.chart.date_start = moment().subtract(7,'days').utc().unix();
                break;
            case 'month':
                this.chart.date_start = moment().subtract(31,'days').utc().unix();
                break;
            case 'year':
                this.chart.date_start = moment().subtract(365,'days').utc().unix();
                break;
            case 'beginning_day':
                this.chart.date_start = moment().startOf('day').utc().unix();
                break;
            case 'beginning_week':
                this.chart.date_start = moment().startOf('week').utc().unix();
                break;
            case 'beginning_month':
                this.chart.date_start = moment().startOf('month').utc().unix();
                break;
            case 'beginning_year':
                this.chart.date_start = moment().startOf('year').utc().unix();
                break;
            case 'custom':
                break;
            default:
                this.chart.date_start = moment().subtract(24,'hours').utc().unix();
                break;
        }
        if (this.chart.period!=='custom') {
            this.chart.date_end = moment().utc().unix();
        }
        this.chart.chart_id = this.chart.id;
        await this.loadChartData(this.chart,csv);
    }

    async loadChartData(state,csv) {
        if (!state || !state.chart_id) { return}

        this.data[state.chart_id] = [];
        Store.changeProperties({
            'isChartLoading': true,
            data: [],
            needs_update: true
        });
        var params = {
            title: state.title,
            id:state.chart_id,
            date_start: state.date_start,
            date_end: state.date_end,
            group: state.group,
            series: state.series,
            aggregate: parseInt(state.aggregate),
            widget_type: state.widget_type,
            widget_settings: state.widget_settings,
            timezone: state.timezone,
            csv: csv
        };
        let response = await HttpClient.request("/chart/getSensorData", 'post', params);
        if (!response || !response.data || !response.data.status == 'ok') {
            Store.changeProperties({isChartLoading: false});
            return
        }
        if (response.data.status !== "ok") {
            this.errors["general"] = response.data.message || "Server error";
            this.isChartLoading = false;
            this.applyPropsToState(["errors","isChartLoading"]);
            return;
        }
        let result = response.data;
        if (result.fileName) {
            window.open(getBackendUrl()+"/chart/getCsv/?fileName="+result.fileName+"&token="+window.localStorage.getItem("token"));
        }
        this.displayChartData(state, result);
    }

    displayChartData(state,result) {
        const colors = [];
        const  axisSeries = [];
        var labels = ['Time'];
        if (state.widget_type == 'chart' || !state.widget_type) {
            if (result.data) {
                var annotations = [];
                var filteredSeries = _.filter(state.series,(item) => {return item.visible});
                for (var index in result.data) {
                    var stackSeries = null;
                    for (var index1 in result.data[index]) {
                        if (index1==0) {
                            result.data[index][0] = moment(result.data[index][0] * 1000).tz(state.timezone ? state.timezone : 'UTC').toDate();
                        } else {
                            if (filteredSeries[index1-1] && filteredSeries[index1-1].stack_number > 0 && (filteredSeries[index1-1].chart_type == 'stackedBar' ||
                                filteredSeries[index1-1].chart_type == 'stackedLine')) {
                                stackSeries = _.filter(filteredSeries, (item) => {
                                    return item.stack_number == filteredSeries[index1-1].stack_number && (item.chart_type == 'stackedBar' ||
                                        item.chart_type == 'stackedLine')
                                });
                                if (stackSeries.length > 1) {
                                    var lastIndex = _.findIndex(stackSeries, (item) => {
                                        return item.title == filteredSeries[index1-1].title;
                                    });
                                    if (result.data[index][filteredSeries.indexOf(stackSeries[lastIndex])+1] != 0) {
                                        var annotation = {
                                            x: moment(result.data[index][0]).unix() * 1000,
                                            text: _.cloneDeep(result.data[index][filteredSeries.indexOf(stackSeries[lastIndex]) + 1]).toString()+ ' ' +
                                            filteredSeries[filteredSeries.indexOf(stackSeries[lastIndex])].unit,
                                            shortText: _.cloneDeep(result.data[index][filteredSeries.indexOf(stackSeries[lastIndex]) + 1].toString())+ ' ' +
                                            filteredSeries[filteredSeries.indexOf(stackSeries[lastIndex])].unit,
                                            width: 50,
                                            height: 20,
                                            tickHeight: 10,
                                            value: _.cloneDeep(result.data[index][filteredSeries.indexOf(stackSeries[lastIndex]) + 1]),
                                            series: filteredSeries[filteredSeries.indexOf(stackSeries[lastIndex])].title
                                        }
                                        annotations.push(annotation);
                                    }
                                    for (var i1=0;i1<lastIndex;i1++) {
                                        result.data[index][filteredSeries.indexOf(stackSeries[lastIndex])+1] +=
                                            result.data[index][filteredSeries.indexOf(stackSeries[i1])+1];
                                    }
                                }
                            }
                        }
                    }
                    if (stackSeries && stackSeries.length) {
                        var row = result.data[index];
                        var cloned_row = [];
                        var indexes = [];
                        for (var i1 in stackSeries) {
                            i1 = parseInt(i1);
                            indexes.push(filteredSeries.indexOf(stackSeries[i1])+1);
                        }
                        var reversed_indexes = _.cloneDeep(indexes);
                        reversed_indexes = reversed_indexes.reverse();
                        for (var i1 in row) {
                            if (i1==0) {
                                cloned_row[i1] = row[i1];
                                continue;
                            }
                            i1 = parseInt(i1);
                            if (indexes.indexOf(i1) == -1) {
                                cloned_row[i1] = row[i1];
                                colors[i1-1] = filteredSeries[i1-1].color;
                            } else {
                                var annotation_index = _.findIndex(annotations,(item) => {return item.x == moment(row[0]).unix() * 1000 &&
                                    item.series == filteredSeries[reversed_indexes[indexes.indexOf(i1)]-1].title});
                                if (annotation_index!=-1) {
                                    var annotation_index2 = _.findIndex(annotations,(item) => {return item.x == moment(row[0]).unix() * 1000 &&
                                        item.series == filteredSeries[i1-1].title});
                                    annotations[annotation_index].series = filteredSeries[i1-1].title;

                                    if (annotation_index2!=-1 && annotations[annotation_index].value != annotations[annotation_index2].value) {
                                        var swap = annotations[annotation_index].value;
                                        annotations[annotation_index].value = annotations[annotation_index2].value;
                                        annotations[annotation_index2].value = swap;
                                    }
                                }
                                cloned_row[reversed_indexes[indexes.indexOf(i1)]] = row[i1];
                                colors[i1-1] = filteredSeries[reversed_indexes[indexes.indexOf(i1)]-1].color;

                            }
                        }
                        result.data[index] = _.cloneDeep(cloned_row);
                    }
                }
            } else {
                result.data = [];
            }

            for (var i in state.series) {
                if (state.series[i].visible) {
                    labels.push(state.series[i].title);
                    if (!stackSeries || !stackSeries.length) {
                        colors.push(state.series[i].color);
                    }
                    axisSeries[state.series[i].title] = {
                        axis: state.series[i].axis,
                        strokeWidth: state.series[i].chart_type == 'bar' ? 1 : 3
                    };
                }
            }
        }
        this.data[state.chart_id + (state.widget_id ? '_' + state.widget_id : '')] = result.data;
        if (state.widget_type == 'chart' || !state.widget_type) {
            var properties_to_update = {
                'needs_update': true,
                'state': '',
                'labels': labels,
                'axisSeries': axisSeries,
                'axes': {
                    'y': {
                        axisLabelFormatter: function (d) {
                            return d.toFixed(3) + ' ' + (result.axis_units['y1'] && typeof(result.axis_units['y1']) != 'undefined' ? result.axis_units['y1'] : '')
                        },
                        valueRange: state.syncAxis ? [result.axes_mins['y1'].min,result.axes_mins['y1'].max] : []
                    },
                    'y2': {
                        axisLabelFormatter: function (d) {
                            return d.toFixed(3) + ' ' + (result.axis_units['y2'] && typeof(result.axis_units['y2']) != 'undefined' ? result.axis_units['y2'] : '')
                        },
                        valueRange: state.syncAxis ? [result.axes_mins['y2'].min,result.axes_mins['y2'].max] : []
                    },
                    'x': {
                        axisLabelFormatter: function (value, gran) {
                            /*
                            if (gran >= 16) {
                                return moment(value).tz(state.timezone ? state.timezone : 'UTC').format('MMM YYYY');
                            } else if (gran > 12) {
                                return moment(value).tz(state.timezone ? state.timezone : 'UTC').format('D MMM YYYY');
                            } else {
                            */
                            return moment(value).tz(state.timezone ? state.timezone : 'UTC').format('YYYY-MM-DD HH:mm:ss');
                            //}
                        }
                    }
                },
                'colors': colors,
                'range_min': parseInt(result.range.min),
                'range_max': parseInt(result.range.max),
                'annotations': annotations
            };
            for (let property_id in properties_to_update) {
                this.chart[property_id] = properties_to_update[property_id];
            }
            this.updateChartStateFromProps();
        }
        Store.changeProperties({isChartLoading:false});
        setTimeout(()=>{
           this.ChangeChartField("needs_update",false);
        },2000);
    }

    AddSerie() {
        this.chart.series.push({
            visible:true,
            color:'#ff0000',
            title:"",
            unit:"",
            useif:"all",
            axis:"y1",
            chart_type:"line",
            func:"avg",
            show_fields:false,
            fields:[]
        });
        this.updateChartStateFromProps();
    }

    AddSerieField(index) {
        const state = Store.getState();
        this.chart.series[index].fields.push({
            visible:true,
            relayboard_id: state.config._id,
            device_id: null,
            unit_id: null,
            field_id: null,
            operator:'+',
            multiplier:1,
        });
        this.chart.series[index].show_fields = true;
        this.updateChartStateFromProps();
    }

    DeleteSerie(index) {
        this.chart.series.splice(index,1);
        this.updateChartStateFromProps();
    }

    MoveSerie(from_index,to_index) {
        if (to_index>=0 && to_index<_.toArray(this.chart.series).length) {
            var swap = _.cloneDeep(this.chart.series[from_index]);
            this.chart.series[from_index] = _.cloneDeep(this.chart.series[to_index]);
            this.chart.series[to_index] = swap;
            this.updateChartStateFromProps();
        }
    }

    ChangeChartSerieField(index,name,value) {
        if (name === 'color') { value = '#'+value.hex; }
        this.chart.series[index][name] = value;
        this.updateChartStateFromProps();
    }

    updateChartStateFromProps() {
        const state = Store.getState();
        const chartIndex = state.charts_settings.charts.findIndex(item => item.id == this.chart_id);
        if (chartIndex !== -1) {
            state.charts_settings.charts[chartIndex] = this.chart;
            Store.changeProperty("charts_settings",_.cloneDeep(state.charts_settings))
            //this.ChangeField("charts_settings",_.cloneDeep(state.charts_settings));
        }

    }

    ChangeChartSerieFieldField(serie_index,field_index,name,value) {
        this.chart.series[serie_index].fields[field_index][name] = value;
        if (name === 'device_id') {
            this.chart.series[serie_index].fields[field_index]['unit_id'] = null;
            this.chart.series[serie_index].fields[field_index]['field_id'] = null;
        }
        if (name === 'unit_id') {
            this.chart.series[serie_index].fields[field_index]['field_id'] = null;
        }
        this.updateChartStateFromProps();
    }

    DeleteSerieField(serie_index,field_index) {
        this.chart.series[serie_index].fields.splice(field_index,1);
        this.updateChartStateFromProps();
    }

    OnChartMouseOver(event, x, points, row, seriesName) {
        const state = Store.store.getState();
        var chart = this.chart;
        var series = chart.series;
        var current_time = 0;
        if (event.buttons > 0) { return };
        points.forEach((point) => {
            var serie_index = _.findIndex(chart.series,(item) => { return item.title.replace(/\./g,'') == point.name});
            if (serie_index != -1) {
                var annotation_index = _.findIndex(chart.annotations,(item) => {return item.x == point.xval && item.series == point.name});
                if (annotation_index!=-1) {
                    series[serie_index].current_value = parseFloat(chart.annotations[annotation_index].value);
                    if (typeof(point.yval)=='array') {
                    } else if (point.yval !== null && typeof(point.yval) != 'undefined') {
                    }
                } else if (typeof(point.yval)=='array') {
                    series[serie_index].current_value = parseFloat(point.yval[1].toFixed(3));
                } else if (point.yval !== null && typeof(point.yval) != 'undefined') {
                    series[serie_index].current_value = parseFloat(point.yval.toFixed(3));
                }
                series[serie_index].current_time = point.xval/1000;
                current_time = point.xval/1000;
                if (point.yval) {
                    series[serie_index].current_value = parseFloat(point.yval.toFixed(3));
                }
                if (point.xval) { current_time = point.xval; }
            }
        });
        Store.changeProperties({series:series,current_time:current_time})
    }

    OnChartZoom(start_date,end_date) {
        if (start_date) {
            this.chart.date_start = start_date / 1000;
        }
        if (end_date) {
            this.chart.date_end = end_date/1000;
        }
        this.chart.period = 'custom';
        this.updateChartStateFromProps();
        this.Refresh();
    }

    async UpdateLiveData() {
        const response = await HttpClient.request('/inventory/get_status',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            this.status = response.data.result;
        }
        let chart = this.chart;
        let data = this.data;
        if ((this.widget_type=='chart' || !this.widget_type) && this.widget_type != 'map') {
            var row = [moment(Date.now()).toDate()];

            var labels = ['Time'],
                axisSeries = [],
                colors = [];

            for (var i in chart.series) {
                if (chart.series[i].visible) {
                    labels.push(chart.series[i].title);
                    colors.push(chart.series[i].color);
                    axisSeries[chart.series[i].title] = {
                        axis: chart.series[i].axis,
                        strokeWidth: chart.series[i].chart_type == 'bar' ? 1 : 3
                    };
                }
            }

            if (data[0] && labels.length != data[0].length) {
                data[this.chart.id] = [];
                return;
            }

            chart.series.forEach((serie,serie_index) => {
                if (!serie.visible) {
                    return;
                }
                var result_value = 0;
                serie.fields.forEach((field) => {
                    if (!this.status.lan || typeof(this.status.lan) === 'undefined') { return }
                    if (!field.visible) {
                        return;
                    }
                    var field_status = this.status.lan[field.device_id];
                    if (!field_status) {
                        return;
                    }
                    if (field.unit_id) {
                        field_status = field_status[field.unit_id];
                    }
                    var operator = '+';
                    if (field.operator) {
                        operator = field.operator;
                    }
                    var field_value = parseFloat(field_status[field.field_id]);
                    if (isNaN(field_value)) {
                        field_value = 0;
                    }
                    if (field.multiplier) {
                        field_value = field_value*field.multiplier;
                    }
                    result_value = parseFloat(parseFloat(eval(result_value + operator + field_value)).toFixed(3));
                },this)
                if (!serie.multiplier) {
                    serie.multiplier = 1;
                };
                chart.series[serie_index].current_value = result_value;
                chart.current_time = moment(row[0]).unix();
                row.push(result_value*serie.multiplier);
            },this)
            if (data && data[this.chart.id]) {
                if (labels.length == row.length) {
                    data[this.chart.id].push(row);
                    if (data[this.chart.id].length > 100) {
                        data[this.chart.id].shift();
                    }
                }
            }
            data = labels.length == row.length ? data : [];
            chart.needs_update = true;
            chart.lables = labels;
            chart.axisSeries = axisSeries;
            chart.colors = colors;
            Store.changeProperties({chart:chart,data:data})
        }/* else {
            if (!data || !data.push) {
                data = [];
            }
            var row = {};
            if (state.Dashboards.selected_panel && state.Dashboards.panels[state.Dashboards.selected_panel]) {
                chart = state.Dashboards.panels[state.Dashboards.selected_panel].widgets[ownProps.widget_id];
                for (var index in chart.settings.fields) {
                    var result_value = 0;
                    if (!chart.settings.fields[index].enabled) {
                        continue;
                    }
                    for (var field_index in chart.settings.fields[index].fields) {
                        var field = chart.settings.fields[index].fields[field_index];
                        if (!field.enabled) {
                            continue;
                        }
                        var field_status = state.Dashboard.status.lan[field.device_id];
                        if (!field_status) {
                            return;
                        }
                        if (field.unit_id) {
                            field_status = field_status[field.unit_id];
                        }
                        var operator = '+';
                        if (field.operator) {
                            operator = field.operator;
                        }
                        var field_value = parseFloat(field_status[field.field_id]);
                        if (isNaN(field_value)) {
                            field_value = 0;
                        }
                        if (field.multiplier) {
                            field_value = field_value * field.multiplier;
                        }
                        result_value = parseFloat(parseFloat(eval(result_value + operator + field_value)).toFixed(3));
                    }
                    row['f_' + index] = result_value;
                }
                if (!data || !data.push) {
                    data = [];
                };
                data.push(row);
                actions.data[chart_id+(chart.widget_id ? '_'+chart.widget_id : '')] = _.cloneDeep(data);
                Store.store.dispatch(actions.updateChartProperties(chart_id,{
                    needs_update:true
                }));
            }
        }*/
    }
}
