import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import HttpClient from '../../services/HttpClient';
import { css } from 'styled-components';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';


import {
    Grid,
    Card,
    CardHeader,
    IconButton,
    Chip as MuiChip,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress,
    Tooltip,
    Button,
    Typography
} from "@material-ui/core";

import { MoreVertical } from "react-feather";

import { green, red } from "@material-ui/core/colors";
// Define the blinking animation
const blinkingAnimation = css`
    @keyframes blink {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    animation: blink 1.5s infinite;
`;

// Styled component for the blinking text
const BlinkingText = styled(Typography)`
    ${props => props.shouldBlink && blinkingAnimation}
    font-size: 2rem;
    font-weight: bold;
    color: red;
`;

const CenterAlignedCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;

export default class Home extends Component {
    render() {
        if (this.props.isLoading) {
            return <div style={{ textAlign: 'center', width: "100%" }}><CircularProgress color="primary" /></div>
        }
        if (!this.props.lan_settings) { return null; }
        return (
            <React.Fragment>
                <Helmet title="Home" />
                <Grid container spacing={6} justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Card mb={6}>
                            <CardHeader
                                action={
                                    <Tooltip title="Change Site Settings" arrow>
                                        <IconButton aria-label="settings" onClick={() => this.props.history.push("/settings/general")}>
                                            <MoreVertical />
                                        </IconButton>
                                    </Tooltip>
                                }
                                title="Site"
                            />
                            <Paper>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>Name</strong></TableCell>
                                            <TableCell>{this.props.config.title}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>ID</strong></TableCell>
                                            <TableCell>{this.props.config._id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>Location</strong></TableCell>
                                            <TableCell>{this.props.config.location && this.props.config.location.address}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>Local Time</strong></TableCell>
                                            <TableCell>{this.props.config.location && moment().tz(this.props.config.location.timezone ? this.props.config.location.timezone : 'UTC').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>Up time</strong></TableCell>
                                            <TableCell>{this.props.status.uptime ? moment.duration(this.props.status.uptime * 1000).humanize() : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>IP Address</strong></TableCell>
                                            <TableCell>{this.props.lan_settings.ip_address}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>CPU Temperature</strong></TableCell>
                                            <TableCell>{this.props.status.cpu_temperature}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row"><strong>Online</strong></TableCell>
                                            <TableCell><Chip label={this.props.status.connected ? "Yes" : "No"} rgbcolor={this.props.status.connected ? green[500] : red[500]} /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Card mb={6}>
                            <CardHeader
                                action={
                                    <Tooltip title="Show inventory">
                                        <IconButton aria-label="settings" onClick={() => this.props.history.push("/inventory")}>
                                            <MoreVertical />
                                        </IconButton>
                                    </Tooltip>
                                }
                                title="Inventory"
                            />
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Device</TableCell>
                                            <TableCell>Addresses</TableCell>
                                            <TableCell>Online</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.config.inventory && this.props.config.inventory.lan.map(row => (
                                            <TableRow key={row.device_id}>
                                                <TableCell component="th" scope="row">
                                                    <Link to={"/inventory/" + row.id}><strong>{row.title}</strong></Link>
                                                    {row.units && row.units.length && row.units.map(unit =>
                                                        <div style={{ paddingLeft: '20px' }}>{unit.title}</div>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.id}<br />{row.host}</TableCell>
                                                <TableCell>
                                                    {this.props.IsOnline(row) ? <Chip label="Yes" rgbcolor={green[500]} /> : <Chip label="No" rgbcolor={red[500]} />}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {this.renderLoadProtection()}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {this.renderPortalConnectionWidget()}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    handleResetProtectLoad() {
        HttpClient.request("config/clearProtectLoad", "post", {});
    }

    // Add a state for managing the confirmation dialog
    state = {
        isConfirmationDialogOpen: false,
    };

    // Method to open the confirmation dialog
    openConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: true });
    };

    // Method to close the confirmation dialog
    closeConfirmationDialog = () => {
        this.setState({ isConfirmationDialogOpen: false });
    };

    renderLoadProtection() {
        if (!this.props.status?.protectLoad?.isProtectLoadTriggered) {
            return null; // Don't render anything if protection is not triggered
        }
        let protectLoadTriggeredTime = this.props.status?.protectLoad?.protectLoadTriggeredTime || "Unknown";
        console.log("protectLoadTriggeredTime", protectLoadTriggeredTime);
        protectLoadTriggeredTime = moment(protectLoadTriggeredTime*1000).format('YYYY-MM-DD HH:mm:ss');
        return (
            <Card mb={6} elevation={3} style={{ backgroundColor: "white", color: "black" }}>
                <CardHeader title="Protect Load Status" />
                <CenterAlignedCardContent>
                    <BlinkingText variant="h6" color="inherit" gutterBottom shouldBlink>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ color: 'red' }}>Protect Load is Detected</h1>
                        </div>
                    </BlinkingText>
                    <Typography variant="h7" color="textSecondary">
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ color: 'green' }}>Triggered Time at: {protectLoadTriggeredTime}</h1>
                        </div>
                    </Typography>
                    <Typography variant="body1" color="inherit">
                        The load protection has been triggered. Please take appropriate action.
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.openConfirmationDialog}
                        style={{ marginTop: 16 }}
                    >
                        Reset Protection
                    </Button>
                    <Dialog open={this.state.isConfirmationDialogOpen} onClose={this.closeConfirmationDialog}>
                        <DialogTitle>Confirmation</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please make sure the overload issue has been resolved before resetting the protection.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeConfirmationDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleResetProtectLoad} color="primary">
                                Confirm Reset
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CenterAlignedCardContent>
            </Card>
        );
    }


    renderPortalConnectionWidget() {
        return (
            <Card mb={6}>
                <CardHeader
                    title="Portal Connection"
                />
                <Paper>
                    <Grid container spacing={6} style={{ padding: '20px' }}>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center' }}>
                                Register and connect your site to Offgrid Portal to monitor and manage it from any part of the world.
                            </Typography>
                            {this.props.status.connected ?
                                <div style={{ textAlign: 'center' }}>
                                    <h1 style={{ color: 'green' }}>Connected</h1>
                                    {/* <Button mr={2} variant="contained" color="primary"
                                            onClick={()=>this.props.OnDisconnectClick()}>
                                        Disconnect
                                    </Button>&nbsp;&nbsp; */}

                                </div>
                                :
                                <div style={{ paddingTop: '20px' }}>
                                    {/* <Button mr={2} variant="contained" color="primary"
                                            style={{textDecoration:'none'}}
                                            onClick={()=>this.props.history.push("/loginPortal")}>Sign In</Button>&nbsp;&nbsp;
                                    <Button mr={2} variant="contained" color="primary"
                                            style={{textDecoration:'none'}}
                                            onClick={()=>this.props.history.push("/signup")}>Sign Up</Button> */}
                                </div>
                            }
                            {this.props.status && this.props.status.token && this.props.status.connected ? this.generatePortalLink() : null}
                        </Grid>
                    </Grid>
                </Paper>
            </Card>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

    generatePortalLink() {
        let link = "http";
        if (this.props.portal_settings.port == 443) {
            link = "https://"
        }
        link += this.props.portal_settings.host;
        if (this.props.portal_settings.port != 443 && this.props.portal_settings.port != 80) {
            link += ":" + this.props.portal_settings.port;
        }
        link += "/sites?token=" + this.props.status.token;
        return (
            <h2 style={{ paddingTop: '10px' }}>
                <a target="_blank" href={link}>Open portal</a>
            </h2>
        )
    }
}

