import Base from './../Base';
import Component from '../../pages/inventory/DeviceStatus';
import {connect} from 'react-redux';
import moment from "moment-timezone";
import Store from "../../store/Store";
import HttpClient from "../../services/HttpClient";

/**
 * Controller for Device Status component
 */
export default class DeviceStatus extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!DeviceStatus.component) {
            const item = new DeviceStatus();
            DeviceStatus.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return DeviceStatus.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            device: ownProps.device || null,
            unit: ownProps.unit || null,
            show_all_fields: state.show_all_fields,
            selectedFieldId: state.selectedFieldId || null,
            selectedFieldValue: state.selectedFieldValue || "",
        };
        let status = null;
        let config = null;
        if (ownProps.device) {
            status = state.status[ownProps.device.id];
            config = ownProps.device;
        }
        if (ownProps.unit) {
            status = status && status[ownProps.unit.id];
            config = ownProps.unit;
        }
        props['config'] = config;
        props['status'] = status;
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Function used to check if current device or unit is online (e.g. sending data)
     * @return {boolean}
     */
    IsOnline() {
        if (!this.status) { return false; }
        // return this.status.isOnline
        const state = Store.getState();        
        return moment().utc().toDate().getTime() - state.diffTime  - this.status.timestamp < 5 * this.config.data_read_interval;
    }

    ChangeDeviceField(field_name,property_name,value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.device.id);
        state.config.inventory.lan[deviceIndex].fields[field_name][property_name] = value;
        Store.changeProperty("config",state.config);
    }

    async WriteToSelectedField() {
        console.log(this.selectedFieldId+"-"+this.selectedFieldValue);
        console.log({
            device_id:this.device.id, field_id: this.selectedFieldId, value: this.selectedFieldValue
        });
        await HttpClient.request("/relayboard/send_command", 'POST', {
            type:'set_field',device_id:this.device.id, unit_id: this.unit ? this.unit.id : null, field_id: this.selectedFieldId, value: this.selectedFieldValue
        });
    }
}
