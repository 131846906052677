import Base from './../../Base';
import Component from '../../../pages/inventory/settings/Adam6000Settings';
import {connect} from 'react-redux';
import Store from '../../../store/Store';
import moment from 'moment-timezone';

/**
 * Controller for Adam6000 Settings component
 */
export default class Adam6000Settings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Adam6000Settings.component) {
            const item = new Adam6000Settings();
            Adam6000Settings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Adam6000Settings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == ownProps.deviceId);
        let selectedField = state.selectedField;
        if (!selectedField && state.config.inventory.lan[deviceIndex] && state.config.inventory.lan[deviceIndex].fields) {
            selectedField = Object.getOwnPropertyNames(state.config.inventory.lan[deviceIndex].fields)[0]
        }
        const props = {
            deviceId: ownProps.deviceId || null,
            selectedSubTab: state.selectedSubTab || 0,
            selectedRelay: state.selectedRelay || null,
            selectedField: selectedField || null,
            selectedCondition: state.selectedCondition || 0,
            inventory: state.config && state.config.inventory.lan,
            errors: state.errors || {},
            status: state.status,
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                props['config'] = config;
                if (selectedField && config.fields[selectedField]) {
                    props['selectedRelayObject'] = config.fields[selectedField];
                }
            }
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    ChangeRelayField(name,value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField][name] = value;
        this.ChangeField('config',state.config);
    }

    AddCondition() {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField].conditions.push({
            schedule_enabled:true,
            condition_enabled:false,
            sunSetSunRise_enable:true,
            days:[false,false,false,false,false,false,false],
            trigger_sense_time:0,
            duration:0,
            start_time:0,
            min_value:0,
            max_value:0,
            min_value_action:1,
            max_value_action:1,
            device_id:0,
            unit_id:0,
            field_id:0,
            title:''
        });
        const selectedCondition = state.config.inventory.lan[deviceIndex].fields[this.selectedField].conditions.length-1;
        Store.changeProperties({config:state.config,selectedCondition:selectedCondition})
    }

    ChangeRelayConditionField(name,value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField].conditions[this.selectedCondition][name] = value;
        this.ChangeField('config',state.config);
    }

    DeleteCondition() {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField].conditions.splice(this.selectedCondition,1);
        Store.changeProperties({config:state.config,selectedCondition:0})
    }

    ChangeRelayConditionScheduleDay(number,value) {
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id == this.deviceId);
        state.config.inventory.lan[deviceIndex].fields[this.selectedField].conditions[this.selectedCondition].days[number] = value;
        this.ChangeField('config',state.config);
    }
}
