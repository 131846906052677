import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';

import {
    Card,
    Paper,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    TextField,
    Grid,
    Button,
    Snackbar,
    CircularProgress
} from "@material-ui/core";

import {
    Save as SaveIcon
} from "@material-ui/icons";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

export default class GeneralSettings extends Component {
    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        return (
            <React.Fragment>
                <Helmet title="General Settings"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>General Settings</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>
                                    </h1>
                                </div>
                            </Grid>
                        </Grid>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>General</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                    {this.renderGeneral()}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Support</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.renderSupport()}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Site Admin</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.renderSiteAdmin()}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    renderGeneral() {
        return (
            <Paper style={{width:'100%',paddingRight:'20px'}}>
                <form noValidate autoComplete="off" style={{width:'100%'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={this.props.errors["title"] && this.props.errors["title"].length}
                                fullWidth
                                size="small"
                                label="Site Name"
                                variant="outlined"
                                value={this.props.title}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeField('config.title',e.target.value)}
                            />
                            {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Address"
                                variant="outlined"
                                value={this.props.address}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeField('config.location.address',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Latitude"
                                variant="outlined"
                                value={this.props.lat}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeField('config.location.lat',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Longitude"
                                variant="outlined"
                                value={this.props.lng}
                                style={{margin:8}}
                                onChange={(e)=>this.props.ChangeField('config.location.lng',e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }

    renderSupport() {
        return (
            <Paper style={{width:'100%',paddingRight:'20px'}}>
                <form noValidate autoComplete="off" style={{width:'100%'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Company"
                                variant="outlined"
                                style={{margin:8}}
                                value={this.props.company}
                                onChange={(e)=>this.props.ChangeField('config.support.company',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Description"
                                multiline={true}
                                variant="outlined"
                                style={{margin:8}}
                                value={this.props.description}
                                onChange={(e)=>this.props.ChangeField('config.support.description',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Manager</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Engineer</strong>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Name"
                                variant="outlined"
                                value={this.props.manager_name}
                                onChange={(e)=>this.props.ChangeField('config.support.manager.name',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Surname"
                                variant="outlined"
                                value={this.props.manager_surname}
                                onChange={(e)=>this.props.ChangeField('config.support.manager.surname',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Name"
                                variant="outlined"
                                value={this.props.engineer_name}
                                onChange={(e)=>this.props.ChangeField('config.support.engineer.name',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Surname"
                                variant="outlined"
                                value={this.props.engineer_surname}
                                onChange={(e)=>this.props.ChangeField('config.support.engineer.surname',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Contact Number"
                                variant="outlined"
                                value={this.props.manager_contact_number}
                                onChange={(e)=>this.props.ChangeField('config.support.manager.contact_number',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                variant="outlined"
                                value={this.props.manager_email}
                                onChange={(e)=>this.props.ChangeField('config.support.manager.email',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Contact Number"
                                variant="outlined"
                                value={this.props.engineer_contact_number}
                                onChange={(e)=>this.props.ChangeField('config.support.engineer.contact_number',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                variant="outlined"
                                value={this.props.engineer_email}
                                onChange={(e)=>this.props.ChangeField('config.support.engineer.email',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                multiline={true}
                                label="Description"
                                variant="outlined"
                                value={this.props.manager_description}
                                onChange={(e)=>this.props.ChangeField('config.support.manager.description',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                multiline={true}
                                label="Description"
                                variant="outlined"
                                value={this.props.engineer_description}
                                onChange={(e)=>this.props.ChangeField('config.support.engineer.description',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }

    renderSiteAdmin() {
        return (
            <Paper style={{width:'100%',paddingRight:'20px'}}>
                <form noValidate autoComplete="off" style={{width:'100%'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                error={this.props.errors["password"] && this.props.errors["password"].length}
                                size="small"
                                label="Password"
                                type="password"
                                variant="outlined"
                                value={this.props.password}
                                onChange={(e)=>this.props.ChangeField('password',e.target.value)}
                                style={{margin:8}}
                            />
                            {this.props.errors["password"] && <div className="error">{this.props.errors["password"]}</div>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Confirm Password"
                                type="password"
                                variant="outlined"
                                value={this.props.confirmPassword}
                                onChange={(e)=>this.props.ChangeField('confirmPassword',e.target.value)}
                                style={{margin:8}}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>

        )
    }

    async componentDidMount() {
        await this.props.Init();
    }
}
