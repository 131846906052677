import Base from './../../Base';
import Component from '../../../pages/inventory/settings/ClusterControllerSettingsFields';
import {connect} from 'react-redux';
import Store from "../../../store/Store";

/**
 * Controller for Cluster Controller Settings Fields component
 */
export default class ClusterControllerSettingsFields extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!ClusterControllerSettingsFields.component) {
            const item = new ClusterControllerSettingsFields();
            ClusterControllerSettingsFields.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return ClusterControllerSettingsFields.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null,
            unitId: ownProps.unitId || null,
            selectedField: state.selectedField || "*",
            errors: state.errors || {}
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                if (config.units && config.units.length) {
                    const unitIndex = config.units.findIndex(item => item.id === ownProps.unitId);
                    if (unitIndex !== -1) {
                        config = config.units[unitIndex].fields;
                    } else {
                        config = config.units[0].fields;
                    }
                }
                props['config'] = config;
                props['selectedFieldObject'] = config[state.selectedField || "*"];
                if (!props['selectedFieldObject']) {
                    props['selectedField'] = Object.getOwnPropertyNames(config)[0];
                    props['selectedFieldObject'] = config[props['selectedField']];
                }
            }
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    ChangeDeviceField(name,value) {
        if (this.selectedField === '*' && (name !== 'send_live_data' && name !== "save_to_db" && name !== "send_to_portal")) {
            return;
        }
        const state = Store.getState();
        const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === this.deviceId);
        const unitIndex = state.config.inventory.lan[deviceIndex].units.findIndex(item => item.id === this.unitId);
        state.config.inventory.lan[deviceIndex].units[unitIndex].fields[this.selectedField][name] = value;
        Store.changeProperty("config",state.config);
    }

}
