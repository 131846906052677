import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import {
    Card,
    Paper,
    Grid,
    Button,
    Snackbar,
    TextField,
    TabPanel,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tooltip
} from "@material-ui/core";

import {
    Save as SaveIcon,
    Clear as ClearIcon,
    Edit as EditIcon,
    Add as AddIcon
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

export default class ChartList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        if (this.props.charts === null || typeof(this.props.charts) === 'undefined') { return null; }
        return (
            <React.Fragment>
                <Helmet title="Charts"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Charts</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>&nbsp;&nbsp;
                                    </h1>
                                </div>
                            </Grid>
                            <Grid item xs={11} style={{padding:'10px',paddingRight:0}}>
                                <TextField
                                    value={this.props.selectedCategory}
                                    fullWidth
                                    small="true"
                                    label="Select category"
                                    select variant="outlined"
                                    SelectProps={{native: true}}
                                    onChange={(e)=>{
                                        this.props.ChangeField("selectedCategory",e.target.value)
                                    }}
                                >
                                    <option value={0}>All</option>
                                    <option value={1}>Other</option>
                                    {
                                        this.props.categories.map(category =>
                                            <option key={"chart_category_"+category.id} value={category.id}>{category.title}</option>
                                        )
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={1} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <Tooltip title="Add new category" arrow>
                                    <AddIcon fontSize="large" onClick={()=>this.props.AddCategory()} style={{cursor:'pointer',color:'green'}}/>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <br/>
                        {this.renderChartsList()}
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    renderChartsList() {
        const categories = _.cloneDeep(this.props.categories);
        categories.push({id:1,title:'Other'});
        return categories.map(category=>this.renderChartCategory(category,this.props.selectedCategory==0 || category.id == this.props.selectedCategory));
    }

    renderChartCategory(category,visible=true) {
        return (
            <Accordion style={{display: visible ? '' : 'none'}} key={"chart_category_"+category.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{display:'flex',flexDirectior:'row',alignItems:'center'}}>
                        <Tooltip title="Add new chart" arrow>
                            <IconButton onClick={()=>this.props.AddChart(category.id)}><AddIcon size="small" style={{cursor:'pointer',color:"green"}}/></IconButton>
                        </Tooltip>
                        <Tooltip title="Change category name" arrow>
                            <IconButton style={{visibility: category.id > 1 ? 'visible' : 'hidden'}} onClick={(e)=>this.props.EditCategory(category.id,category.title,e)}><EditIcon style={{cursor:'pointer',color:"blue"}}/></IconButton>
                        </Tooltip>
                        <Tooltip title="Delete category" arrow>
                            <IconButton style={{visibility: category.id > 1 ? 'visible' : 'hidden'}} onClick={(e)=>this.props.DeleteCategory(category.id,e)}><ClearIcon style={{cursor:'pointer',color:'red'}}/></IconButton>
                        </Tooltip>
                        <Typography style={{paddingLeft:'15px'}}><strong>{category.title.toUpperCase()}</strong></Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{backgroundColor:"#eeeeee"}}>
                    <table>
                        <tbody>
                            {this.props.charts
                                .filter(chart => chart.category_id == category.id || (category.id == 1 && !chart.category_id))
                                .map(chart => this.renderChartRow(chart))
                            }
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        )
    }

    renderChartRow(chart) {
        return (
            <tr key={"chart_"+chart.id}>
                <td>
                    <Tooltip title="Edit chart" arrow>
                        <IconButton onClick={()=>this.props.history.push("/chart/"+chart.id)}><EditIcon style={{color:'blue',cursor:'pointer'}}/></IconButton>
                    </Tooltip>
                </td>
                <td>
                    <Tooltip title="Delete chart" arrow>
                        <IconButton onClick={()=>this.props.DeleteChart(chart.id)}><ClearIcon style={{color:'red',cursor:'pointer'}}/></IconButton>
                    </Tooltip>
                </td>
                <td style={{visibility:'hidden'}}>
                    <IconButton onClick={()=>this.props.DeleteChart(chart.id)}><ClearIcon style={{color:'red',cursor:'pointer'}}/></IconButton>
                </td>
                <td style={{verticalAlign:'middle'}} onClick={()=>this.props.history.push("/chart/"+chart.id)} style={{cursor:'pointer'}}>
                    {chart.title}
                </td>
            </tr>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

}
