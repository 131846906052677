import React,{Component} from "react";
import Helmet from 'react-helmet';
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import maTheme from "../theme";
import Routes from "../containers/Routes";
import "../css/main.css";
window.$ = window.jQuery = require('jquery');

export default class App extends Component {
    render() {
        const RoutesComponent = Routes.getComponent();
        return (
            <React.Fragment>
                <Helmet
                    titleTemplate="Offgrid Controller"
                    defaultTitle="Offgrid Controller"
                />
                <StylesProvider injectFirst>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiThemeProvider theme={maTheme[0]}>
                            <ThemeProvider theme={maTheme[0]}>
                                <RoutesComponent />
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </StylesProvider>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }
}

