import React,{Component} from "react";
import { TextField,Grid } from "@material-ui/core";

export default class ClusterControllerSettingsFields extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                {this.renderFieldsList()}
                {this.props.selectedField && this.renderFieldForm()}
            </div>
        )
    }

    renderFieldsList() {
        const fields = [];
        for (let field_id in this.props.config) {
            if (field_id === "*") { continue; }
            const field = this.props.config[field_id];
            fields.push(field.title+" "+field_id);
        }
        fields.sort((s1,s2) => s1.toString().toLowerCase()<s2.toString().toLowerCase() ? -1 : 1);
        const options = fields.map(field => {
            let parts = field.split(" ");
            const id = parts.pop();
            const title = parts.join(" ");
            return <option value={id}>{title} ({id})</option>
        });

        return (
            <TextField
                fullWidth
                select
                label="Field"
                size="small"
                value={this.props.selectedField}
                style={{margin:8}}
                onChange={(e)=>this.props.ChangeField("selectedField",e.target.value)}
                SelectProps={{
                    native: true
                }}
                variant="outlined"
            >
                <option value="*">Default</option>
                {options}
            </TextField>
        )
    }

    renderFieldForm() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        variant="outlined"
                        value={this.props.selectedFieldObject.title}
                        style={{margin:8}}
                        error={this.props.errors["title"] && this.props.errors["title"].length}
                        onChange={(e)=>this.props.ChangeDeviceField('title',e.target.value)}
                    />
                    {this.props.errors["title"] && <div className="error">{this.props.errors["title"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["modbus_register"] && this.props.errors["modbus_register"].length}
                        fullWidth
                        size="small"
                        label="Register #"
                        variant="outlined"
                        value={this.props.selectedFieldObject.modbus_register}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('modbus_register',e.target.value)}
                    />
                    {this.props.errors["modbus_register"] && <div className="error">{this.props.errors["modbus_register"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["unit"] && this.props.errors["unit"].length}
                        fullWidth
                        size="small"
                        label="Unit"
                        variant="outlined"
                        value={this.props.selectedFieldObject.unit}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('unit',e.target.value)}
                    />
                    {this.props.errors["unit"] && <div className="error">{this.props.errors["unit"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["multiplier"] && this.props.errors["multiplier"].length}
                        fullWidth
                        size="small"
                        label="Multiply By"
                        variant="outlined"
                        value={this.props.selectedFieldObject.multiplier}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('multiplier',e.target.value)}
                    />
                    {this.props.errors["multiplier"] && <div className="error">{this.props.errors["multiplier"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["type"] && this.props.errors["type"].length}
                        fullWidth
                        select
                        label="Type"
                        size="small"
                        value={this.props.selectedFieldObject.type}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("type",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value=""></option>
                        <option value="U16">U16</option>
                        <option value="U32">U32</option>
                        <option value="U48">U48</option>
                        <option value="U64">U64</option>
                        <option value="S16">S16</option>
                        <option value="S32">S32</option>
                        <option value="S48">S48</option>
                        <option value="S64">S64</option>
                        <option value="STR32">STR32</option>
                    </TextField>
                    {this.props.errors["type"] && <div className="error">{this.props.errors["type"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["format"] && this.props.errors["format"].length}
                        fullWidth
                        size="small"
                        label="Format"
                        variant="outlined"
                        value={this.props.selectedFieldObject.format}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField('format',e.target.value)}
                    />
                    {this.props.errors["format"] && <div className="error">{this.props.errors["format"]}</div>}
                </Grid>
                <Grid item xs={4} style={{display:this.props.selectedField === "*" ? "none" : ""}}>
                    <TextField
                        error={this.props.errors["access"] && this.props.errors["access"].length}
                        fullWidth
                        select
                        label="Access level"
                        size="small"
                        value={this.props.selectedFieldObject.access}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("access",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value=""></option>
                        <option value="RO">Read</option>
                        <option value="RW">Read and Write</option>
                    </TextField>
                    {this.props.errors["access"] && <div className="error">{this.props.errors["access"]}</div>}
                </Grid>
                {/* <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["send_live_data"] && this.props.errors["send_live_data"].length}
                        fullWidth
                        select
                        label="Read data"
                        size="small"
                        value={this.props.selectedFieldObject.send_live_data}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("send_live_data",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["send_live_data"] && <div className="error">{this.props.errors["send_live_data"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["save_to_db"] && this.props.errors["save_to_db"].length}
                        fullWidth
                        select
                        label="Save to database"
                        size="small"
                        value={this.props.selectedFieldObject.save_to_db}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("save_to_db",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["save_to_db"] && <div className="error">{this.props.errors["save_to_db"]}</div>}
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        error={this.props.errors["send_to_portal"] && this.props.errors["send_to_portal"].length}
                        fullWidth
                        select
                        label="Send to portal"
                        size="small"
                        value={this.props.selectedFieldObject.send_to_portal}
                        style={{margin:8}}
                        onChange={(e)=>this.props.ChangeDeviceField("send_to_portal",e.target.value)}
                        SelectProps={{
                            native: true
                        }}
                        variant="outlined"
                    >
                        <option value="">Default</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </TextField>
                    {this.props.errors["send_to_portal"] && <div className="error">{this.props.errors["send_to_portal"]}</div>}
                </Grid> */}
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
            </Grid>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
