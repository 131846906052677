import Base from './Base';
import Component from '../pages/home/index';
import {connect} from 'react-redux';
import HttpClient from '../services/HttpClient';
import Store from "../store/Store";
import '../lib/smooth-plotter';
import moment from 'moment-timezone';
import _ from 'lodash';

/**
 * Controller for Home component
 */
export default class Home extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Home.component) {
            const item = new Home();
            Home.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Home.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            config: state.config || null,
            status: state.status || {},
            lan_settings: state.lan_settings || {},
            portal_settings: state.portal_settings || {},
            history: ownProps.history,
            isLoading: state.isLoading
        };
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (!this.config.inventory) {
            await this.loadConfig();
        }
        if (!this.intervals || !this.intervals.length || typeof(this.intervals)==='undefined') {
            this.intervals = [];
            this.intervals.push(setInterval(async () => {
                if (!this.isChecking || typeof(this.isChecking) === 'undefined') {
                    this.isChecking = true;
                    await this.GetStatus();
                    this.isChecking = false;
                }
            }, 1000));
        }
    }

    async loadConfig() {
        this.ChangeField("isLoading",true);
        let response = await HttpClient.request("/config/load",'get',{});
        if (response.data.status === 'ok' && response.data.config && typeof(response.data.config) === 'object') {
            const config = response.data.config;
            response = await HttpClient.request("/network/get_settings","get",{});
            if (response && response.data) {
                const lan = response.data.config.lan;
                response = await HttpClient.request("/portal/get_settings","get",{});
                if (response && response.data) {
                    Store.changeProperties({
                        isLoading: false,
                        config: config,
                        lan_settings: lan,
                        portal_settings: response.data.config
                    });
                }
                console.log({
                    isLoading: false,
                    config: config,
                    lan_settings: lan,
                    portal_settings: response.data.config
                });
            }
        } else {
            Store.changeProperty("isLoading",false);
        }
    }

    async GetStatus() {
        const response = await HttpClient.request('/config/status',"get",{});
        if (response && typeof(response.data) !== 'undefined' && response.data.status === 'ok') {
            this.ChangeField("status",response.data.result);
        }
    }

    /**
     * Function used to check if current device or unit is online (e.g. sending data)
     * @return {boolean}
     */
    IsOnline(device) {
        try {
            let timestamp = 0;
            if (!this.status.result || !this.status.result.lan || !Object.getOwnPropertyNames(this.status.result.lan).length) {
                return false;
            }
            if (!device.units || !device.units.length) {
                timestamp = this.status.result.lan[device.id] ? this.status.result.lan[device.id].timestamp : 0
            } else {
                for (let unit of device.units) {
                    if (this.status.result.lan[device.id] && this.status.result.lan[device.id][unit.id] && this.status.result.lan[device.id][unit.id].timestamp > timestamp) {
                        timestamp = this.status.result.lan[device.id][unit.id] ? this.status.result.lan[device.id][unit.id].timestamp : 0;
                    }
                }
            }
            return Date.now() - timestamp < 60000;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    async OnDisconnectClick() {
        await HttpClient.request('/portal/post_disconnect',"post",{});
    }
}
