import React,{Component} from "react";
import {PowerSettingsNew} from '@material-ui/icons'
import {IconButton} from '@material-ui/core';
import { TabPanel } from "@material-ui/core";

export default class Adam6000DeviceStatus extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.status || typeof(this.props.status) === 'undefined') { return null; }
        const relay_lines = [];
        const relay_images = [];
        for (let field_id in this.props.config.fields) {
            const n = field_id.split("_").pop();
            relay_lines.push(<div key={"relay_lines_"+field_id}>
                <div className="adam6000line" id={"relay_"+n+"_line_0"}/>
                <div className="adam6000line" id={"relay_"+n+"_line_1"}/>
                <div className="adam6000line" id={"relay_"+n+"_line_2"}/>
                <div className="adam6000line" id={"relay_"+n+"_line_3"}/>
                <div className="adam6000line" id={"relay_"+n+"_line_4"}/>
            </div>)
            relay_images.push(
                <div id={"relay_"+n+"_image"} key={"relay_"+n+"_image"} style={{width:30,height:30,left:60+30*parseInt(n),top:450,position:'absolute'}}/>
            )
        }
        return (
            <div style={{width:"100%"}}>
                {relay_lines}
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-start'}}>
                    <div>
                        {this.renderHeader()}
                        {this.renderTable()}
                    </div>
                    <div style={{position:'relative'}}>
                        {relay_images}
                        <img id="img" style={{height:'500px'}} src={require("../../img/adam6060.jpg")}/>
                    </div>
                </div>
            </div>
        )
    }

    getStatusInfo() {
        var fields = this.props.config.fields,
            status = this.props.status,
            status_rows = [];

        for (var field_id in fields) {
            if (field_id == '*' || (!status[field_id] && !this.props.show_all_fields)) {
                continue;
            }
            const field = fields[field_id];
            status_rows.push(
                <tr key={'status_' + field_id} id={"relay_row_"+field_id.split("_").pop()}>
                    <td><IconButton onClick={this.props.OnRelayClick.bind(this,this.props.device.id,field.number,status ? status[field_id] == 1 ? 0 : 1 : null)}>
                        <PowerSettingsNew id={"relay_switch_image_"+field_id.split("_").pop()} style={{color: status[field_id] ? 'green' : 'red',fontSize:64}}/>
                    </IconButton></td>
                    <td><h1>{fields[field_id].title}</h1></td>
                </tr>
            );
        }
        return status_rows;
    }

    renderHeader() {
        return <h2 id="header" style={{color:this.props.IsOnline() ? 'green' : 'red'}}>
            {this.props.config.title} ({this.props.config.id})
        </h2>
    }

    renderTable() {
        return (
            <table style={{borderCollapse:'collapse'}} id="relays_table">
                <tbody>
                {this.getStatusInfo()}
                </tbody>
            </table>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }

    async componentDidUpdate() {
        if (!this.props.status || typeof(this.props.status) === 'undefined') { return null; }
        let $ = window.$;
        for (let field_id in this.props.config.fields) {
            let n = parseInt(field_id.split("_").pop());
            if (n>4) {continue;}
            $('#relay_'+n+'_line_4')
                .css("left", $('#relay_switch_image_'+n).offset().left + $('#relay_switch_image_'+n).width() / 2 + "px")
                .css("top", $('#relay_switch_image_'+n).offset().top + $('#relay_switch_image_'+n).height() + "px")
                .css("width", "1px")
                .css("height", parseInt($('#relay_row_'+n).offset().top + parseInt($('#relay_row_'+n).height())) - ($('#relay_switch_image_'+n).offset().top + $('#relay_switch_image_'+n).height()) + 'px')
            $('#relay_'+n+'_line_0')
                .css('left', $('#relay_switch_image_'+n).offset().left + $('#relay_switch_image_'+n).width() / 2)
                .css('width', ($('#img').offset().left - ($('#relay_switch_image_'+n).offset().left + $('#relay_switch_image_'+n).width() / 2)-n*10 + "px"))
                .css('top', parseInt($('#relay_row_'+n).offset().top + parseInt($('#relay_row_'+n).height())) + "px")
                .css('height', "1px")
            $('#relay_'+n+'_line_1')
                .css('left', $('#relay_row_'+n).offset().left + ($('#img').offset().left - $('#relay_row_'+n).offset().left)-n*10 + "px")
                .css('width', '1px')
                .css('top', parseInt($('#relay_row_'+n).offset().top + parseInt($('#relay_row_'+n).height())) + "px")
                .css('height', $('#img').height() - $('#relay_row_'+n).height() - n*$('#relay_row_'+n).height() - window.$("#header").height() - 40 + 10*n + "px")
            $('#relay_'+n+'_line_2')
                .css('left', $('#relay_row_'+n).offset().left + ($('#img').offset().left - $('#relay_row_'+n).offset().left)-n*10 + "px")
                .css('width', $('#relay_'+n+'_image').offset().left - ($('#relay_row_'+n).offset().left + ($('#img').offset().left - $('#relay_row_'+n).offset().left)) + 16 + n*10+ "px")
                .css('top', parseInt($('#relay_row_'+n).offset().top + parseInt($('#relay_row_'+n).height())) + $('#img').height() - $('#relay_row_'+n).height() - n*$('#relay_row_'+n).height() - window.$("#header").height() - 40 + 10*n + "px")
                .css('height', '1px')
            $('#relay_'+n+'_line_3')
                .css('left', $('#relay_'+n+'_image').offset().left + 15 + "px")
                .css('width', "1px")
                .css('top', $('#relay_'+n+'_image').offset().top + $('#relay_'+n+'_image').height() + 2 + "px")
                .css('height', (parseInt($('#relay_row_'+n).offset().top + parseInt($('#relay_row_'+n).height())) + $('#img').height() - $('#relay_row_'+n).height() - n*$('#relay_row_'+n).height()- window.$("#header").height() - 42 + 10*n) - ($('#relay_'+n+'_image').offset().top + $('#relay_'+n+'_image').height()) + 'px')
        }



    }
}
