import React from "react";

import SignInContainer from "../containers/SignIn";
import SignUpContainer from "../containers/SignUp";
import LoginPortalContainer from "../containers/LoginPortal";
import GeneralSettingsContainer from "../containers/settings/GeneralSettings";
import NetworkSettingsContainer from "../containers/settings/NetworkSettings";
import PortalSettingsContainer from '../containers/settings/PortalSettings';
import EmailSettingsContainer from "../containers/settings/EmailSettings";
import AlertsSettingsContainer from "../containers/settings/AlertsSettings";
import InventoryContainer from '../containers/inventory/Inventory';
import ChartListContainer from "../containers/ChartList";
import ChartContainer from "../containers/Chart";
import HomeContainer from "../containers/Home";
import ManageContainer from "../containers/settings/Manage";
import FlowsContainer from "../containers/flows/Flows";
import PhaseControlContainer  from "../containers/PhaseControl";
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';

import {
    PieChart,
    Users,
    Settings,
    Home,
    Layers,
    Sliders
} from "react-feather";

import { WidgetsOutlined } from "@material-ui/icons"
import {SettingsInputComponentIcon} from '@material-ui/icons/SettingsInputComponent';

const SignIn = SignInContainer.getComponent();
const SignUp = SignUpContainer.getComponent();
const LoginPortal = LoginPortalContainer.getComponent();
const GeneralSettings = GeneralSettingsContainer.getComponent();
const NetworkSettings = NetworkSettingsContainer.getComponent();
const PortalSettings = PortalSettingsContainer.getComponent();
const EmailSettings = EmailSettingsContainer.getComponent();
const AlertsSettings = AlertsSettingsContainer.getComponent();
const Inventory = InventoryContainer.getComponent();
const ChartList = ChartListContainer.getComponent();
const Chart = ChartContainer.getComponent();
const HomePage = HomeContainer.getComponent();
const Manage = ManageContainer.getComponent();
const Flows = FlowsContainer.getComponent();
const PhaseControl = PhaseControlContainer.getComponent();

const homeRoutes = {
    id: "Home",
    path: "/",
    component: (state) => { return <HomePage history={state.history} /> },
    icon: <Home />,
    children: null
};

const inventoryRoutes = {
    id: "Inventory",
    path: "/inventory/:device?",
    component: (state) => { return <Inventory history={state.history} selectedDevice={state.match.params.device} /> },
    icon: <Layers />
};

const inventorySidebarRoute = {
    id: "Inventory",
    path: "/inventory",
    icon: <Layers />
};

const phaseControlSidebarRoute = {
    id: "PhaseControl",
    path: "/phasecontrol",
    icon: <SettingsInputCompositeIcon />,
    component: (state) => { return <PhaseControl history={state.history} /> },
    children: null
};


const flowsSidebarRoute = {
    id: "Flows",
    path: "/flows",
    icon: <WidgetsOutlined />
};

const flowsRoute = {
    id: "Flows",
    path: "/flows/:flow?",
    component: (state) => { return <Flows history={state.history} selectedFlow={state.match.params.flow} /> },
    icon: <WidgetsOutlined />
};

const settingsRoutes = {
    id: "Settings",
    path: "/settings",
    icon: <Settings />,
    children: [
        {
            path: "/settings/general",
            name: "General",
            component: GeneralSettings
        },

        {
            path: "/settings/network",
            name: "Network",
            component: NetworkSettings
        },
        {
            path: "/settings/portal",
            name: "Portal",
            component: PortalSettings
        },
        {
            path: "/settings/email",
            name: "Email",
            component: EmailSettings
        },
        {
            path: "/settings/alerts",
            name: "Alerts",
            component: AlertsSettings
        },
        {
            path: "/settings/manage",
            name: "Manage",
            component: Manage
        }
    ],
    component: null
};



const authRoutes = {
    id: "Login",
    icon: <Users />,
    path: "/",
    component: SignIn
};

const SignUpRoute = { id: "SignUp", icon: null, path: "/signup", component: SignUp }

const LoginPortalRoute = { id: "LoginPortal", icon: null, path: "/loginPortal", component: LoginPortal }


const chartListRoutes = {
    id: "Charts",
    path: "/charts",
    icon: <PieChart />,
    component: (state) => { return <ChartList history={state.history} /> },
    children: null
};

const chartRoutes = {
    id: "Charts",
    path: "/chart/:chart_id",
    component: (state) => { return <Chart history={state.history} chart_id={state.match.params.chart_id} /> }
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
    homeRoutes,
    chartRoutes,
    chartListRoutes,
    settingsRoutes,
    inventoryRoutes,
    phaseControlSidebarRoute,
    flowsRoute,
    SignUpRoute,
    LoginPortalRoute,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
    homeRoutes,
    chartListRoutes,
    inventorySidebarRoute,
    phaseControlSidebarRoute,
    flowsSidebarRoute,
    settingsRoutes,
];
