import moment from 'moment-timezone';
import _ from 'lodash';
const cache_granularity = {
    1: 'Years',
    2: 'Monthes',
    3: 'Days',
    4: 'Hours',
    5: 'Minutes'
};
export default {
    aggregate_levels: [1, 5,10,15,30,60,300,600,1800,3600,21600,43200,86400],
    periods: [1, 5,10,15,30,60,300,600,1800,3600,21600,43200,86400],
    getPeriodTitle: (period) => {
        if (period<60) {
            return period+' seconds';
        } else {
            return moment.duration(period * 1000).humanize();
        }
    },
    getCacheGranularityArray: () => {
        return _.toArray(cache_granularity)
    },
    cachePath: '/opt/relayboard_server/cache/sensor_data',
    reader_types: {
        // algodue: 'Algodue Meter',
//      irtrans: 'IRTrans Remote Control',
        // cluster_controller: 'SMA Cluster Controller',
//      speedwire: 'SMA SpeedWire Interface',
        sma_speedwire: 'SMA SpeedWire Interface',
        adam6000: "Adam6000 Series Relay Board",
//      sma_energy_meter: "SMA Energy Meter",
        sma_elgris_energy_meter: "SMA Elgris Energy Meter",
        offgrid_controller: 'Offgrid Controller',
        rut950v2: 'Teltonika RUT-950',
        // BQ76952: "BMS I2C",
        // hm305p: "HM305P Programmable DC Power Supply",
        Reinhausen: "Reinhausen device ",
        Umg605Meter: "UMG 605 Smart meter",
        irradiance_Si: 'Irradiance Sensors Si-RS485',
        naradaRack: "Narada EMS Cluster ",
        NaradaRackClient: "Narada BMS Rack ",
        TriPowerX: "TriPower X",
        HuaweiSun2000 : "HuaweiSun 2000",
        HuaweiSmartLogger : "HuaweiSun Smart Logger",
        Trumpf : "Trumpf",
        LeochXY25 : "Leoch XY25",
    }
}
