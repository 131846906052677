import Base from './Base';
import Component from '../pages/auth/SignUp';
import {connect} from 'react-redux';
import HttpClient from "../services/HttpClient";

/**
 * Controller for SignUp component
 */
export default class SignUp extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!SignUp.component) {
            const item = new SignUp();
            SignUp.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return SignUp.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
            email:state.email,
            name:state.name,
            password:state.password,
            confirmPassword:state.confirmPassword,
            successMessageText:state.successMessageText,
            errors:state.errors
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        this.email = '';
        this.name = '';
        this.password = '';
        this.errors = {};
        this.confirmPassword = '';
        this.applyPropsToState();
    }

    async DoSignUp() {
        try {
            if (this.validateForm()) {
                const response = await HttpClient.request("/login/post_signup", "post", {
                    email: this.email,
                    password: this.password,
                    name: this.name
                });
                if (response.data && !response.data.error) {
                    this.successMessageText = "Portal Sign Up successful";
                    this.applyPropsToState();
                    setTimeout(()=> {
                        window.location.href = '/';
                    },3000)
                    return;
                } else {
                    this.errors['general'] = response.data.error;
                }
            }
        } catch (err) {
            this.errors['general'] = err.toString();
        }
        this.applyPropsToState();
    }

    validateForm() {
        this.errors = {};
        if (!this.email) { this.errors['email'] = "Email not specified"; }
        if (!this.name) { this.errors['name'] = "Name not specified"; }
        if (!this.password) { this.errors['password'] = "Password not specified"; }
        if (this.password !== this.confirmPassword) { this.errors['password'] = "Passwords must match"; }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }
}
