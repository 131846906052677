import React,{Component} from "react";
import GeneralModbusTcpSettingsFieldsContainer from "../../../containers/inventory/settings/GeneralModbusTcpSettingsFields";

export default class GeneralModbusTcpSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const GeneralModbusTcpSettingsFields = GeneralModbusTcpSettingsFieldsContainer.getComponent();
        if (!this.props.config || typeof(this.props.config) === 'undefined') { return null; }
        return (
            <div>
                <GeneralModbusTcpSettingsFields deviceId={this.props.deviceId}/>
            </div>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
