import Base from './../../Base';
import Component from '../../../pages/inventory/settings/SMAElgrisEnergyMeterSettings';
import {connect} from 'react-redux';

/**
 * Controller for SMA Speedwire Settings component
 */
export default class SMAElgrisEnergyMeterSettings extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!SMAElgrisEnergyMeterSettings.component) {
            const item = new SMAElgrisEnergyMeterSettings();
            SMAElgrisEnergyMeterSettings.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return SMAElgrisEnergyMeterSettings.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            deviceId: ownProps.deviceId || null
        };
        let config = null;
        if (ownProps.deviceId) {
            const deviceIndex = state.config.inventory.lan.findIndex(item => item.id === ownProps.deviceId);
            if (deviceIndex !== -1) {
                config = state.config.inventory.lan[deviceIndex];
                props['config'] = config;
            }
        }
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

}
