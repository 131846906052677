import Base from './Base';
import Component from '../routes/Routes';
import {connect} from 'react-redux';

/**
 * Controller for Routes component
 */
export default class Routes extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!Routes.component) {
            const item = new Routes();
            Routes.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return Routes.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
            isAuthorized: state.isAuthorized
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
    }
}
