import React,{Component} from "react";
import Helmet from 'react-helmet';
import moment from "moment-timezone";
import { Alert } from '@material-ui/lab';
import {CircularProgress} from "@material-ui/core";

import {
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    Snackbar,
    Tabs,
    Tab
} from "@material-ui/core";

import {
    CloudDownload,
    Refresh
} from "@material-ui/icons";


export default class Manage extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet title="Manage"/>
                {!this.props.isUpdating && !this.props.isRestarting ? this.renderContent() : null}
                {this.props.isUpdating ? this.renderUpdate() : null }
                {this.props.isRestarting ? this.renderRestart() : null }
            </React.Fragment>
        )
    }

    renderContent() {
        return (
            <Card mb={6}>
                <Paper style={{padding:10}}>
                    {this.props.error ? <Alert severity="error">{this.props.error}</Alert> : null }
                    <Tabs value={this.props.selectedTab} onChange={(event,value) => this.props.ChangeField('selectedTab',value)}>
                        <Tab label="Manage"/>
                        <Tab label="Console"/>
                    </Tabs>
                    <div style={{display: this.props.selectedTab == 0 ? 'flex' : 'none',width:'100%'}}>
                        {this.renderManage()}
                    </div>
                    <div style={{display: this.props.selectedTab == 1 ? '' : 'none',width:'100%'}}>
                        {this.renderConsole()}
                    </div>
                </Paper>
            </Card>
        )
    }

    renderUpdate() {
        return (
            <Card mb={6}>
                <Paper style={{padding:10,display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                    <CircularProgress/>
                    <h1 style={{marginLeft:10}}>Please wait while system updates. Do not close this page</h1>
                </Paper>
            </Card>
        )
    }

    renderRestart() {
        return (
            <Card mb={6}>
                <Paper style={{padding:10,display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                    <CircularProgress/>
                    <h1 style={{marginLeft:10,color:'red'}}>Please wait until system restarts. This page will refresh automatically</h1>
                </Paper>
            </Card>
        )
    }

    renderManage() {
        return (
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start',marginTop:20}}>
                <div style={{display:'flex',flexDirection:'fow',alignItems:'center',justifyContent:'flex-start'}}>
                    <Button mr={2} variant="contained" color="primary" onClick={() => this.props.Update()} style={{width:200}}>
                        <CloudDownload style={{marginRight:5}}/>
                        Update Firmware
                    </Button>
                    {this.props.lastTimestamp ?
                        <span style={{marginLeft:10}}>Firmware version: <strong>{moment(this.props.lastTimestamp*1000).format("YYYY-MM-DD HH:mm:ss")}</strong></span>
                    : null}
                </div>
                <Button mr={2} variant="contained" color="primary" onClick={() => this.props.Restart()} style={{marginTop:10,width:200,backgroundColor:'#AA0000'}}>
                    <Refresh style={{marginRight:5}}/>
                    Restart System
                </Button>
            </div>
        )
    }

    renderConsole() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <h1>Terminal Session</h1>
                    </Grid>
                </Grid>
                <div>
                    <div>
                        <pre ref={(item) => { this.terminal_area = item;}} className="terminalSession" dangerouslySetInnerHTML={{__html:this.props.buffer}}></pre>
                    </div>
                    <input placeholder="Enter command here" className="terminalSession" value={this.props.command}
                           onKeyPress={this.props.OnCommandKeyPress.bind(this)}
                           onChange={this.props.OnCommandChange.bind(this)}
                    />
                </div>
            </div>
        )
    }



    async componentDidMount() {
        await this.props.Init();
    }

    async componentWillUnmount() {
        this.props.Dispose();
    }
}
