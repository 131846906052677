import React,{Component} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Helmet from 'react-helmet';

import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {Alert} from "@material-ui/lab";
const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

export default class SignIn extends Component {
  render() {
      return (
          <Wrapper>
              <Helmet title="Sign In"/>

              <Typography component="h1" variant="h4" align="center" gutterBottom>
                  Login
              </Typography>
              <Typography component="h2" variant="body1" align="center">
                  Sign in to your account to continue
              </Typography>
              <form>
                  {this.props.errors && this.props.errors["general"] &&
                    <Alert style={{marginTop:10}} severity="error">{this.props.errors["general"]}</Alert>
                  }
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="email">Login</InputLabel>
                      <Input id="email" name="email" autoComplete="email" autoFocus
                             error={this.props.errors['login']}
                             helperText="Some important text"
                             onChange={(e) => this.props.ChangeField('login', e.target.value)}
                      />
                      {this.props.errors["login"] && <span className="error">{this.props.errors["login"]}</span> }
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                          error={this.props.errors['password']}
                          name="password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          onChange={(e) => this.props.ChangeField('password', e.target.value)}
                      />
                      {this.props.errors["password"] && <span className="error">{this.props.errors["password"]}</span> }
                  </FormControl>
                  <Button
                      to="/"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.props.DoLogin()}
                      mb={2}
                  >
                      Sign in
                  </Button>
              </form>
          </Wrapper>
      );
  }

  componentDidMount() {
      this.props.Init();
  }
}

