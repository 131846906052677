import Base from './Base';
import Component from '../pages/auth/LoginPortal';
import {connect} from 'react-redux';
import HttpClient from "../services/HttpClient";

/**
 * Controller for LoginPortal component
 */
export default class LoginPortal extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!LoginPortal.component) {
            const item = new LoginPortal();
            LoginPortal.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return LoginPortal.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        return Object.assign(super.setupStateProps(state,ownProps),{
            email:state.email,
            password:state.password,
            successMessageText:state.successMessageText,
            errors:state.errors
        })
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        this.email = '';
        this.password = '';
    }

    async DoLoginPortal() {
        try {
            if (this.validateForm()) {
                const response = await HttpClient.request("/login/post_login_portal", "post", {
                    email: this.email,
                    password: this.password,
                });
                if (response.data && !response.data.error) {
                    this.successMessageText = "Portal Login successful";
                    this.applyPropsToState();
                    setTimeout(()=> {
                        window.location.href = '/';
                    },3000)
                    return;
                } else {
                    this.errors['general'] = response.data.error;
                }
            }
        } catch (err) {
            this.errors['general'] = err.toString();
        }
        this.applyPropsToState();
    }

    validateForm() {
        this.errors = {};
        if (!this.email) { this.errors['email'] = "Email not specified"; }
        if (!this.password) { this.errors['password'] = "Password not specified"; }
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }
}
