import React,{Component} from "react";
import Helmet from 'react-helmet';
import { Alert } from '@material-ui/lab';

import {
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    Snackbar
} from "@material-ui/core";

import {
    Save as SaveIcon
} from "@material-ui/icons";

import {CircularProgress} from "@material-ui/core";

export default class NetworkSettings extends Component {
    render() {
        if (this.props.isLoading) {
            return <div style={{textAlign:'center',width:"100%"}}><CircularProgress color="primary" /></div>
        }
        return (
            <React.Fragment>
                <Helmet title="Network Settings"/>
                {this.props.successMessageText ?
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={true}
                        message={this.props.successMessageText}
                    /> : null
                }
                <Card mb={6}>
                    <Paper style={{padding:10}}>
                        {this.props.errors['general'] && <Alert mb={4} severity="error">{this.props.errors["general"]}</Alert> }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h1>Ethernet Settings</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{textAlign:'right'}}>
                                    <h1>
                                        <Button mr={2} variant="contained" color="primary" className="pull-right"
                                            onClick={()=>this.props.Save()}>
                                                <SaveIcon />
                                                Save Changes
                                        </Button>
                                    </h1>
                                </div>
                            </Grid>
                        </Grid>
                        <form noValidate autoComplete="off" style={{width:'100%'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        error={this.props.errors["mode"] && this.props.errors["mode"].length}
                                        fullWidth
                                        select
                                        label="Mode"
                                        size="small"
                                        value={this.props.mode}
                                        style={{margin:8}}
                                        onChange={(e)=>this.props.ChangeField("lan_settings.mode",e.target.value)}
                                        SelectProps={{
                                            native: true
                                        }}
                                        variant="outlined"
                                    >
                                        <option value="dhcp">DHCP</option>
                                        <option value="static">Static</option>
                                    </TextField>
                                    {this.props.errors["mode"] && <div className="error">{this.props.errors["mode"]}</div>}
                                </Grid>
                                {this.props.mode === 'static' || !this.props.mode ? [
                                    <Grid item xs={6} key="ip_address">
                                        <TextField
                                            error={this.props.errors["ip_address"] && this.props.errors["ip_address"].length}
                                            fullWidth
                                            size="small"
                                            label="IP Address"
                                            variant="outlined"
                                            value={this.props.ip_address}
                                            style={{margin:8}}
                                            onChange={(e)=>this.props.ChangeField('lan_settings.ip_address',e.target.value)}
                                        />
                                        {this.props.errors["ip_address"] && <div className="error">{this.props.errors["ip_address"]}</div>}
                                    </Grid>,
                                    <Grid item xs={6} key="ip_netmask">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Netmask"
                                            variant="outlined"
                                            value={this.props.ip_netmask}
                                            style={{margin:8}}
                                            onChange={(e)=>this.props.ChangeField('lan_settings.ip_netmask',e.target.value)}
                                        />
                                        {this.props.errors["ip_netmask"] && <div className="error">{this.props.errors["ip_netmask"]}</div>}
                                    </Grid>,
                                    <Grid item xs={6} key="ip_gateway">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Gateway"
                                            variant="outlined"
                                            value={this.props.ip_gateway}
                                            style={{margin:8}}
                                            onChange={(e)=>this.props.ChangeField('lan_settings.ip_gateway',e.target.value)}
                                        />
                                        {this.props.errors["ip_gateway"] && <div className="error">{this.props.errors["ip_gateway"]}</div>}
                                    </Grid>
                                ] : null }
                            </Grid>
                        </form>
                    </Paper>
                </Card>
            </React.Fragment>
        )
    }

    async componentDidMount() {
        await this.props.Init();
    }
}
