import Base from './Base';
import Component from '../pages/charts/ChartList';
import {connect} from 'react-redux';
import HttpClient from '../services/HttpClient';
import Store from "../store/Store";

/**
 * Controller for Chart List component
 */
export default class ChartList extends Base {

    /**
     * Binds properties and methods of this controller main screen view and returns component
     * with properties and methods
     * @returns Component to display
     */
    static component = null;
    static getComponent() {
        if (!ChartList.component) {
            const item = new ChartList();
            ChartList.component =
                connect(item.mapStateToProps.bind(item), item.mapDispatchToProps.bind(item))(Component);
        }
        return ChartList.component;
    }

    /**
     * Binds application state properties to properties of current component
     * @param state - Link to state
     * @param ownProps - Link to properties, manually set to this component tag
     * @returns {*}
     */
    setupStateProps(state,ownProps) {
        const props = {
            charts: state.charts_settings && state.charts_settings.charts ? state.charts_settings.charts : null,
            categories: state.charts_settings && state.charts_settings.categories ? state.charts_settings.categories : [],
            errors: state.errors || {},
            selectedCategory: state.selectedCategory || "",
            successMessageText: state.successMessageText || "",
            isLoading: state.isLoading || false,
            history: ownProps.history,
            newChartId: state.newChartId || 0
        };
        return Object.assign(super.setupStateProps(state,ownProps),props);
    }

    /**
     * Method runs when this component appears for the first time
     */
    async Init() {
        super.Init();
        if (this.charts === null) {
            await this.loadConfig();
        }
    }

    async Save() {
        this.ChangeField('errors',{});
        if (!this.validate()) {
            this.applyPropsToState(["errors"]);
            return;
        }
        this.ChangeField('isLoading',true);
        const state = Store.getState();
        if (this.selectedDevice === 'new' && this.id && this.title) {
            state.config.inventory.lan.push(state.newDevice);
        }
        let response = await HttpClient.request("/charts/post_savecharts","post",{charts:state.charts_settings});
        if (response && response.data && response.data.status && response.data.status === "ok") {
            Store.changeProperties({
                isLoading:false,
                successMessageText:"Changes saved successfully",
                charts:state.charts
            });
            setTimeout(()=>{this.ChangeField("successMessageText","")},3000);
        } else {
            if (response && response.data && response.data.status && response.data.status === "error") {
                this.errors['general'] = response.data.message;
            } else {
                this.errors['general'] = 'Error saving changes';
            }
            this.applyPropsToState("errors");
        }
    }

    validate() {
        return Object.getOwnPropertyNames(this.errors).length === 0;
    }

    async loadConfig() {
        this.ChangeField("isLoading",true);
        const response = await HttpClient.request("/charts/get_config",'get',{});
        if (response.data.status === 'ok' && response.data.charts_settings && typeof(response.data.charts_settings) === 'object') {
            Store.changeProperties({
                charts_settings: response.data.charts_settings,
                isLoading:false
            })
        }
    }

    AddCategory() {
        const state = Store.getState();
        const title = window.prompt("Enter category name");
        if (!title || !title.trim()) { return; }
        const categoryIndex = state.charts_settings.categories.findIndex(item=>item.title == title.trim());
        if (categoryIndex !== -1) {
            alert("Category with specified name already exists");
            return;
        }
        const id = (() => {
            let max = 0;
            state.charts_settings.categories.forEach(category => {
                if (category.id>max) {
                    max = category.id;
                }
            });
            if (max<1) { max=1; }
            return max+1;
        })();
        state.charts_settings.categories.push({id,title});
        this.ChangeField("charts_settings",state.charts_settings);
    }

    DeleteCategory(id,e) {
        e.stopPropagation();
        e.preventDefault();
        if (!window.confirm("Are you sure ?")) { return; }
        const state = Store.getState();
        const index = state.charts_settings.categories.findIndex(item=>item.id == id);
        if (index !== -1) {
            state.charts_settings.categories.splice(index,1);
        }
        this.ChangeField("charts_settings",state.charts_settings);
    }

    EditCategory(id,title,e) {
        e.stopPropagation();
        e.preventDefault();
        const newTitle = window.prompt("Enter category name",title);
        if (!newTitle || !newTitle.trim() || newTitle.trim() == title.trim()) { return; }
        const state = Store.getState();
        let categoryIndex = state.charts_settings.categories.findIndex(item=>item.id == id);
        let index = state.charts_settings.categories.findIndex(item=>item.title == newTitle && item.id != id)
        if (index !== -1) {
            alert("Category with specified name already exists");
            return;
        }
        state.charts_settings.categories[categoryIndex].title = newTitle;
        this.ChangeField("charts_settings",state.charts_settings);
    }

    AddChart(category_id) {
        if (this.newChartId) {
            this.history.push("/chart/"+this.newChartId);
            return;
        }
        let id = 0;
        this.charts.forEach(chart => {
            if (chart.id > id) { id = parseInt(chart.id) }
        });
        id += 1;
        this.charts.push({
            id:id,
            title:'',
            date_start: Date.now()/1000,
            date_end: Date.now()/1000,
            group:'none',
            aggregate:0,
            category_id: category_id,
            stacked: false,
            syncAxis:false,
            series:[],
            timezone:'UTC'
        });
        const state = Store.getState();
        state.charts_settings.charts = this.charts;
        Store.changeProperties({"chart_settings":state.chart_settings,"newChartId":id});
        this.history.push("/chart/"+id);
    }

    DeleteChart(id) {
        if (!window.confirm("Are you sure ?")) { return }
        const state = Store.getState();
        const index = state.charts_settings.charts.findIndex(item=>item.id == id);
        if (index !== -1) {
            state.charts_settings.charts.splice(index,1);
        }
        this.ChangeField("charts_settings",state.charts_settings);
    }
}
